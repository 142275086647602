var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"online-learning backround-cover"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-wrap-reverse"},[_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.play-video-modal",modifiers:{"play-video-modal":true}}],staticClass:"btn btn-link col-md-6 no-underline",attrs:{"tag":"button","round":"","gtm":{
          event: 'modal',
          name: 'onclick',
          action: 'open',
          type: 'click',
          region: 'main content',
          section: 'what are asu online courses like?',
          text: 'play video',
        }}},[_c('img',{staticClass:"play-button",attrs:{"src":require("../assets/images/play-button.svg"),"alt":"play button"}}),_c('p',{staticClass:"text-white py-2"},[_vm._v("play video")])]),_vm._m(0)],1)]),_c('b-modal',{attrs:{"id":"play-video-modal","size":"lg","hide-footer":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('BaseGtmLink',{staticClass:"close-apply text-dark font-36 ml-auto border-none",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
          event: 'modal',
          name: 'onclick',
          action: 'close',
          type: 'click',
          region: 'main content',
          section: 'what are asu online courses like?',
          text: 'play video',
        }},on:{"click":close}},[_c('img',{attrs:{"src":require("../assets/images/close-green.svg"),"alt":"close"}})])]}}])},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":"https://www.youtube.com/embed/TR-mjoupd1M","allowfullscreen":""}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"col-md-10 mx-auto text-center text-lg-left"},[_c('h2',{staticClass:"text-white font-fam-Georgia font-lg-36 font-32"},[_vm._v(" What are ASU Online courses like? ")]),_c('span',{staticClass:"col-2 bg-white d-table my-3 mx-auto mx-lg-0",staticStyle:{"height":"5px"}}),_c('p',{staticClass:"text-white stb-light font-16"},[_vm._v(" Our custom-crafted online degree programs are flexible, but you have to stay on track. You’ll also have access to the knowledge and expertise of the same award-winning faculty who teach on campus. ")]),_c('p',{staticClass:"text-white stb-light font-16"},[_vm._v(" Pursuing your education is a life-changing experience. ASU Online is with you every step of the way with academic advisors, success coaches and more. ")])])])
}]

export { render, staticRenderFns }