<template>
  <section id="hero" :class="`hero-page-${page}`">
    <b-container class="position-relative container-hero">
      <div class="content col-md-8 pl-0">
        <h1
          class="font-32 stb-sBold font-lg-48 mt-4 mt-lg-0 line-height-adjustment"
          v-html="header"
        ></h1>
        <p
          class="px-0 stb-light col-9 mt-lg-32 font-lg-18 font-14 line-height-adjustment-p"
        >
          {{ subheader }}
        </p>
      </div>
      <div class="hero-img" v-if="image">
        <img
          :src="require(`../assets/images/${image}`)"
          alt="image-hero"
          class="image-hero"
        />
      </div>
    </b-container>
  </section>
</template>
<script>
export default {
  name: "Hero",
  props: {
    color: String,
    header: String,
    subheader: String,
    image: String,
    page: String,
  },
  methods: {
    getImageUrl(image) {
      return require("../assets/images/" + image);
    },
  },
};
</script>

<style lang="scss" scoped>
#hero,
.container-hero {
  background-color: #1f3933 !important;
  height: 425px;
  display: flex;
  overflow: hidden;
  position: relative;
  @media only screen and (min-width: 991px) {
    align-items: center;
    height: 540px;
  }
  @media only screen and (max-width: 991px) {
    display: block !important;
  }
}
.line-height-adjustment {
  @media only screen and (max-width: 991px) {
    line-height: 1.4;
  }
}
.line-height-adjustment-p {
  @media only screen and (max-width: 991px) {
    line-height: 19px;
  }
}
.hero-img {
  .image-hero {
    position: absolute;
    height: 360px;
    right: -3%;
    bottom: -20px;
    @media only screen and (max-width: 991px) {
      height: 200px;
      right: -2em;
    }
  }
}
.scroll-for-more {
  position: absolute;
  bottom: 20px;
  text-align: center;
  left: 0;
  right: 0;
}
</style>
