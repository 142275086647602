export default {
  mounted() {
    if (!window.embedded_svc) {
      var s = document.createElement("script");
      s.setAttribute(
        "src",
        `${this.getEnv() === "dev"
          ? "https://edplus-asu--qa.my.salesforce.com/embeddedservice/5.0/esw.min.js"
          : "https://edplus-asu.my.salesforce.com/embeddedservice/5.0/esw.min.js"
        }`
      );
      let self = this;
      s.onload = function () {
        self.initESW(null);
      };
      document.body.appendChild(s);
    } else {
      embedded_svc.addEventHandler("onHelpButtonClick", function () {
        console.log("onHelpButtonClick event was fired.");
      });
      embedded_svc.addEventHandler("onSettingsCallCompleted", function (data) {
        console.log(
          "onSettingsCallCompleted event was fired. Agent availability status is " +
            data.isAgentAvailable
            ? "online"
            : "offline"
        );
      });
      const self = this;
      embedded_svc.addEventHandler("onAvailability", function () {
        self.chatNowText = "Chat now";
      });
      this.initESW("https://service.force.com");
    }
  },
  data() {
    return {
      chatNowText: "Chat now",
    };
  },
  methods: {
    chatDataLayerParams: function (_section, _text) {
      dataLayer.push({
        event: "link",
        name: "onclick",
        action: "click",
        type: "internal link",
        region: "main content",
        section: _section,
        text: _text,
      });
    },
    // TODO: add loading functionality
    initESW(gslbBaseURL) {
      embedded_svc.settings.displayHelpButton = false; //Or false
      embedded_svc.settings.language = "en-US"; //For example, enter 'en' or 'en-US'
      console.log("embedded_svc.componentLoaded()");
      embedded_svc.settings.extraPrechatFormDetails = [
        {
          label: "Degree Interest",
          transcriptFields: ["Degree_Interest_From_Case__c"],
          displayToAgent: true,
        },
        {
          label: "LeadClassFromPreChatForm",
          value: "CORP",
          displayToAgent: false,
          transcriptFields: ["LeadClassFromPreChatForm__c"],
        },
        {
          label: "LeadSubClassFromPreChatForm",
          value: "scap",
          displayToAgent: false,
          transcriptFields: ["LeadSubClassFromPreChatForm__c"],
        },
      ];
      embedded_svc.settings.extraPrechatInfo = [
        {
          entityFieldMaps: [
            {
              doCreate: true,
              doFind: true,
              fieldName: "ASUO_Lead_Class__c",
              isExactMatch: true,
              label: "LeadClassFromPreChatForm",
            },
            {
              doCreate: true,
              doFind: true,
              fieldName: "ASUO_Lead_SubClass__c",
              isExactMatch: true,
              label: "LeadSubClassFromPreChatForm",
            },
          ],
          entityName: "Contact",
        },
      ];

      embedded_svc.settings.enabledFeatures = ["LiveAgent"];
      embedded_svc.settings.entryFeature = "LiveAgent";

      let environment = this.getEnv();
      // console.log(this.getEnv());
      if (environment == "dev") {
        this.dev(gslbBaseURL, embedded_svc);
      } else {
        this.prod(gslbBaseURL, embedded_svc);
      }
    },
    getEnv() {
      let _window = window.location.href;
      if (
        _window.indexOf("dev") >= 0 ||
        _window.indexOf("localhost") >= 0 ||
        _window.indexOf("staging") >= 0
      ) {
        return "dev";
      } else {
        return "prod";
      }
    },
    dev(gslbBaseURL, embedded_svc) {
      embedded_svc.init(
        "https://edplus-asu--qa.sandbox.my.salesforce.com",
        "https://edplus-asu--qa.sandbox.my.site.com/",
        gslbBaseURL,
        "00D8I0000004crz",
        "SBUX_Chat_Deployment",
        {
          baseLiveAgentContentURL: "https://c.la4-c1cs-ia4.salesforceliveagent.com/content",
          deploymentId: "5721M000000k9bd",
          buttonId: "5731M000000k9bc",
          baseLiveAgentURL: "https://d.la4-c1cs-ia4.salesforceliveagent.com/chat",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I1M000000blJeUAI_17764aa41d5",
          isOfflineSupportEnabled: true
        }
      );
      if (!window.embedded_svc) {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://edplus-asu--qa.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        s.onload = function () {
          initESW(null);
        };
        document.body.appendChild(s);
      } else {
        initESW('https://service.force.com');
      }
    },
    prod(gslbBaseURL, embedded_svc) {
      // console.log("prod init");
      embedded_svc.init(
        "https://edplus-asu.my.salesforce.com",
        "https://edplus-asu.my.site.com",
        gslbBaseURL,
        "00D41000002vSQx",
        "SBUX_Chat_Deployment",
        {
          baseLiveAgentContentURL: "https://c.la4-c1-ia4.salesforceliveagent.com/content",
          deploymentId: "5726S000000AypB",
          buttonId: "5736S000000c04N",
          baseLiveAgentURL: "https://d.la4-c1-ia4.salesforceliveagent.com/chat",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I6S000000L2N9UAK_18a525246a6",
          isOfflineSupportEnabled: true
        }
      );
      if (!window.embedded_svc) {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://edplus-asu.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        s.onload = function () {
          initESW(null);
        };
        document.body.appendChild(s);
      } else {
        initESW('https://service.force.com');
      }
    },
    offChatPage() {
      embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: getData().first_name,
        LastName: getData().last_name,
        Email: getData().email_address,
        Phone: getData().phone,
      };
      // embedded_svc.settings.extraPrechatFormDetails = [
      //   {
      //     label: "Degree Interest",
      //     transcriptFields: ["Degree_Interest_From_Case__c"],
      //     displayToAgent: true,
      //   },
      // ];
    },
    ShowChatWindow() {
      embedded_svc.onHelpButtonClick();
      this.chatNowText = "Loading...";
    },
  },
};
