<template>
  <BaseOffsetImg justify="left" vCenter>
    <template #image>
      <div
        :style="
          `background-image: url(${require('../assets/images/military.png')});background-position: center;`
        "
        class="backround-cover col-lg-6 position-relative"
      ></div>
    </template>
    <template #content>
      <div class="col-lg-6 bg-white">
        <div
          class="content-header col-11 mx-auto ml-lg-0 pb-lg-48 pt-lg-5 pl-lg-36"
        >
          <h2 class="font-fam-Georgia font-lg-36 font-32 pb-lg-24">
            A path for veterans and military families
          </h2>
          <p class="font-16 stb-light">
            As a veteran partner, you can extend an additional Starbucks College
            Achievement Plan benefit to a qualifying family member. Like you,
            your family member can choose from 150+ online bachelor's degrees
            and receive the same tuition coverage. The family member can
            call<BaseGtmLink
              tag="a"
              class="text-underline text-light-green px-1"
              href="tel:844-278-7289"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'a path for veterans and military families',
                text: '844-ASU-SBUX (278-7289)'.toLocaleLowerCase(),
              }"
              >844-ASU-SBUX (278-7289)</BaseGtmLink
            >
            and mention they’re a dependent of a military partner to get
            started.
          </p>
          <img
            src="../assets/images/veterans-img.svg"
            alt="veterans"
            class="img-fluid mx-auto d-block mx-lg-0"
          />
        </div>
      </div>
    </template>
  </BaseOffsetImg>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.backround-cover {
  left: 4%;
  @media only screen and (max-width: 991px) {
    left: 0%;
  }
}
.content-header {
  @media only screen and (max-width: 991px) {
    margin-top: -5%;
    background: white;
    padding-top: 24px;
    border-radius: 5px;
  }
}
</style>
