<template>
  <b-form-group
    :id="`input-group-${id}`"
    :label="label"
    :label-class="labelSize"
    :label-for="`id-input-${id}`"
    class="font-label "
    style="margin-bottom:0px !important"
  >
    <select
      :id="`id-input-${id}`"
      v-model="value"
      class="custom-select"
      @change="valueChanged(value)"
      @blur="handleBlur"
      :class="{
        'is-invalid':
          (value === null && errorFlag) || (clickedSubmit && value === null),
      }"
    >
      <option :value="null" disabled hidden>{{ placeholder }}</option>
      <template v-for="option in options">
        <option
          :key="option.code || option"
          :value="{ id: option.code, text: option.title } || option"
          :data-reveal-area="`${degreeType}-${option.title || option}`"
        >
          {{ option.title || option }}
        </option>
      </template>
    </select>
    <b-form-invalid-feedback
      :id="`input-${id}-live-feedback`"
      :class="{
        'd-block':
          (value === null && errorFlag) || (clickedSubmit && value === null),
      }"
    >
      <span>
        {{
          (value === null && errorFlag) || (clickedSubmit && value === null)
            ? "The degree field is required"
            : ""
        }}
      </span>
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    selectedType: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "required",
    },
    labelSize: {
      type: String,
      default: "",
    },
    updatedValue: {
      type: Object,
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      required: true,
    },
    displayText: {
      default: "",
      type: String,
    },
    valueField: {
      default: "",
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    textField: {
      type: String,
      default: "",
    },
    valueForSearch: {
      default: true,
      type: Boolean,
    },
    triggerSectionData: {
      type: String,
      default: "request information",
    },
    clickedSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      degreeType: this.selectedType,
      errorFlag: false,
    };
  },
  watch: {
    selectedType(val) {
      this.degreeType = val;
    },
  },
  methods: {
    handleBlur() {
      this.errorFlag = true;
    },
    valueChanged(value) {
      if (value) {
        this.$emit("input", value);
        this.$emit("changed", value);
      }
    },
    reset() {
      this.value = null;
    },
  },
};
</script>
