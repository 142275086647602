<template>
  <div class="carousal-testimonial pb-lg-96">
    <carousel
      :per-page="1"
      class="testimonial-wrapper"
      :value="setValue"
      :paginationEnabled="false"
      @page-change="changeNavigation"
    >
      <slide
        v-for="testimonial in testimonials"
        :key="testimonial.user"
        class="testimonial-slide"
      >
        <div class="row align-items-center">
          <div class="col-lg-6 col-12 order-2 order-lg-1 px-lg-5 px-4">
            <img
              src="../assets/images/quotation.svg"
              alt=""
              class="img-fluid"
              width="30"
            />
            <p
              class="font-fam-Georgia font-lg-24 font-18 text-dark-green mt-lg-32"
              style="line-height: 28px"
            >
              {{ testimonial.content }}
            </p>
            <p
              class="fam-roboto-light font-lg-16 font-14 pt-lg-48 text-dark-green"
            >
              {{ testimonial.user }}
            </p>
            <div class="carousal-actions">
              <ul
                class="carousal-action-wrapper justify-content-center p-0 justify-content-lg-start"
              >
                <li
                  v-for="(testimonial, i) in testimonials"
                  @click="showCurrentSlide(i, testimonial.user)"
                  :key="testimonial.user"
                >
                  <a
                    href="javascript:void(0)"
                    :class="{ 'active-dot': setValue === i }"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-12 order-1 order-lg-2 text-center">
            <img
              :src="gerImgPath(testimonial.image)"
              alt=""
              class="img-fluid image-responsive mx-auto"
            />
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },

  data() {
    return {
      setValue: 0,
      isShow: false,
      testimonials: [
        {
          user: "Dyan B. | ASU graduate, Starbucks College Achievement Plan",
          content:
            "I was always regretful about not finishing college or even giving college my full potential. Because my past grades were not great, I wasn’t able to get accepted into ASU. Pathway to Admission helped me get into the flow of school again after 10 years and helped me gain admission into ASU, all while still taking courses for credit.",
          image: "dylan-testimonial.png",
        },
        {
          user: "Aaron J. | ASU graduate, Starbucks College Achievement Plan",
          content:
            "Pathway to Admission was a way for me to prove that I had what it took to be a productive student at ASU. I was initially denied admission to ASU due to my poor grades. Conveniently, Pathway to Admission was simultaneously launched and I was able to ease into the process of becoming a student again! Fast forward to today and I was able to graduate.",
          image: "Aaron.png",
        },
        {
          user: "Sarah A. | ASU graduate, Starbucks College Achievement Plan",
          content:
            "Pathway to Admission encouraged me to redefine myself as a student through my classes and by raising my GPA, one class at a time. To all Starbucks partners considering Pathway to Admission, I would say there is no time like the present! If I can do it, anyone can!",
          image: "Sarah.png",
        },
      ],
    };
  },
  methods: {
    gerImgPath(img) {
      return require(`../assets/images/${img}`);
    },
    changeNavigation(slide) {
      this.setValue = slide;
    },
    showCurrentSlide(iterator, user) {
      this.setValue = iterator;
      if (iterator + 1 > 0) {
        let dl = {
          event: "select",
          name: "onclick",
          action: "click",
          type: "owl dot",
          region: "main content",
          section: "starbucks student reviews of pathway to admission",
          text: user.toLocaleLowerCase(),
        };
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousal-action-wrapper {
  .active-dot {
    background-color: #29413b;
  }
  list-style: none;
  display: flex;
  a {
    margin-top: 20px;
    margin: 5px;
    width: 12px;
    height: 12px;
    background-color: transparent;
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: 1px solid #29413b;
    background-clip: content-box;
    box-sizing: content-box;
    border-radius: 100%;
    outline: none;
  }
}
.backround-cover {
  @media only screen and (max-width: 991px) {
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.image-responsive {
  width: 60% !important;
  text-align: center;
  @media only screen and (min-width: 991px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 554px) {
    width: 100% !important;
  }
}
</style>
