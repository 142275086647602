<template>
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :aria-label="'input'"
      :label-class="labelSize"
      :label-for="`id-input-${id}`"
      class="font-label pb-space-xs"
    >
      <slot></slot>
      <b-form-input
        :id="`id-input-${id}`"
        v-model.trim="$v.defaultInput.$model"
        debounce="300"
        :aria-label="'input'"
        :name="`name-input-${id}`"
        :state="
                    $v.defaultInput.$dirty && ($v.defaultInput.$error || emailError == true)
                      ? false
                      : null
                  "
        :aria-describedby="`input-${id}-live-feedback`"
        :placeholder="placeholderText"
        @blur="handleBlur"
        @input="handleInput"
      ></b-form-input>

      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">
        <div class="mb-space-lg">{{ emailError || $v.defaultInput.$error ? displayingEmailErrorMsg : ""  }} </div>
      </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
export default {
  name: "BaseInput",
  mixins: [validationMixin],
  components: {

  },
  props: {
    value: {
      type: null,
      default: null,
    },
    labelSize: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },

    eventComponent: {
      type: String,
      default: "",
    },
    errMsg: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    placeholderText: {
      type: String,
      default: "",
    },
    emailError:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayingEmailErrorMsg() {
      let errorMessage = null
      if (this.defaultInput.length >= 128) {
        errorMessage = `The ${this.$attrs.name} should not be more than 128 characters.`
      } else if (this.defaultInput.length === 0) {
        errorMessage = 'This is a required field'
      } else if (this.emailError === true) {
        errorMessage =
          'Email is required. Please enter a valid email address.'
      } else {
        errorMessage = 'Email is required. Please enter a valid email address.'
      }
      if (!this.emptyValue){
        return errorMessage 
      }else{
        return ""
      }
    }

  },
  data() {
    return {
      
      defaultInput: "",
      emptyValue:true,
    };
  },
  validations:{
    defaultInput: {
        required,
        email,
        maxLength: maxLength(128)
      },
  },
  watch: {
    // Handles internal model changes.
    defaultInput(newVal) {
      this.$emit("input", newVal);
      this.$emit("inputError", 
                    (this.$v.defaultInput.$dirty && (this.$v.defaultInput.$error || this.emailError == true) ? true : false)
                  )
    },
    // Handles external model changes.
    value(newVal) {
      this.defaultInput = newVal;
    }
  },
  created() {
    if (this.value) {
      this.defaultInput = this.value;
    }
  },
  methods: {

    handleInput() {
      this.emptyValue=false
      this.$emit("input", this.defaultInput);
      this.$emit('changed', this.defaultInput);
    },
    handleBlur() {
      
      this.$emit("blur", this.defaultInput);
    },
  },
};
</script>
