<template>
  <scrollactive
    class="my-nav pt-lg-4 bg-white"
    id="stacked-top"
    :offset="60"
    :scrollOffset="10"
    :alwaysTrack="true"
    :duration="800"
    bezier-easing-value=".5,0,.35,1"
    v-on:itemchanged="onItemChanged"
  >
    <b-navbar
      toggleable="lg"
      type="light"
      class="pb-lg-0 secondarynav d-none d-lg-block"
      id="scroll-reveal"
      aria-label="navbar"
    >
      <b-navbar-brand class="d-lg-none">
        <b-navbar-toggle :aria-label="currentItemToDisplay" target="nav-sticky">
          <p :aria-label="currentItemToDisplay"  class="mb-0 text-dark stb-sBold" v-if="!color">
            {{ currentItemToDisplay }}
            <img
              src="../assets/images/Icon-chevron-down-solid.svg"
              alt="Icon-chevron-down-solid"
            />
          </p>
        </b-navbar-toggle>
        <img
          v-if="color"
          :src="require('../assets/images/logo-white.svg')"
          class="logo"
          alt="logo"
          srcset
        />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-top">
        <div id="hamburger-2" @click="closeHamburger()">
          <span class="hamburger-2__line"></span>
          <span class="hamburger-2__line"></span>
          <span class="hamburger-2__line"></span>
        </div>
      </b-navbar-toggle>
      <b-collapse
        id="nav-sticky"
        is-nav
        class="justify-content-center"
        @show="triggerGTM('open'),stickyNavOpen = true"
        @hide="triggerGTM('close'),stickyNavOpen = false"
      >
        <b-navbar-nav role="presentation" >
          <template v-for="(item, i) in stickyValues">
            <BaseGtmLink
              :key="i"
              tag="a"
              :href="`#${item.id}`"
              class="sticky-link font-16 font-lg-18 text-brand-black scrollactive-item pb-lg-2 px-1 mr-lg-25 btn-link"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'secondary navbar',
                text: item.name.toLocaleLowerCase()
              }"
              >{{ item.name }}</BaseGtmLink
            >
          </template>
        </b-navbar-nav>
      </b-collapse>
      <b-collapse
        v-if="mobile"
        id="nav-top"
        is-nav
        class="justify-content-center text-center d-lg-none"
        @show="addColor(), showMenu()"
        @hide="addColor(), closeMenu()"
      >
        <b-navbar-nav>
          <template v-for="(nav, i) in navigation">
            <BaseGtmLink
              tag="a"
              :key="i"
              class="text-center nav-link pr-lg-15 pl-lg-15"
              :href="nav.url"
              :active="nav.label === $router.history.current.name"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'secondary navbar',
                text: nav.name.toLocaleLowerCase()
              }"
            >
              {{ nav.name }}
            </BaseGtmLink>
          </template>
        </b-navbar-nav>
        <hr class="light-border d-lg-none" style="width: 200px" />
        <b-navbar-nav class="ml-auto d-inline-flex d-lg-block cta-buttons">
          <BaseGtmLink
            tag="button"
            class="btn font-14 get-info-button stb-sBold mb-2 mb-lg-0"
            round
            @click="goToRFISection()"
            :gtm="{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'navbar',
              section: 'main navbar',
              text: 'Get info'.toLocaleLowerCase()
            }"
            >Get info</BaseGtmLink
          >
          <BaseGtmLink
            tag="button"
            class="btn font-14 apply-now-button stb-sBold bg-brand-gold text-black-1"
            round
            @click="$store.state.applyNow = 'modal'"
            v-b-modal.modal-1
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'open',
              type: 'click',
              region: 'navbar',
              section: 'sticky navbar',
              text: 'apply now'
            }"
            >Apply now</BaseGtmLink
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </scrollactive>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      currentItemToDisplay: "",
      selected: "",
      checking: false,
      mobile: false,
      stickyNavOpen: false,
      isNavHeaderOpen: false,
      navigation: [
        {
          name: "Home",
          url: "/",
          label: "home"
        },
        {
          name: "Student support",
          url: "/support",
          label: "support"
        },
        {
          name: "Degree search",
          url: "/starbucks-asu-degree-list",
          label: "search"
        },
        {
          name: "FAQ",
          url: "/faq",
          label: "faq"
        },
        {
          name: "Work for Starbucks",
          url: "https://www.starbucks.com/careers",
          label: "work for starbucks"
        }
      ],
      color: false,
      previousClickElement: "",
      subMenuArr: [],
      isSubMenuClicked: false,
      currentClickElement: ""
    };
  },
  props: {
    stickyValues: Array
  },

  mounted() {
    this.currentItemToDisplay = "Menu";
    window.onscroll = this.makeNavSticky;
    this.$nextTick(function() {
      this.onResize();
    });
    window.addEventListener("resize", this.onResize);
    let self = this;
    window.addEventListener("click", function(e) {
      if (
        !document.getElementById("hamburger-2").contains(e.target) &&
        self.isNavHeaderOpen
      ) {
        self.$root.$emit("bv::toggle::collapse", "nav-top");
      }
    });

    if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
        $('body').on("mousewheel", function () {
            // remove default behavior
            event.preventDefault(); 

            //scroll without smoothing
            var wheelDelta = event.wheelDelta;
            var currentScrollPosition = window.pageYOffset;
            window.scrollTo(0, currentScrollPosition - wheelDelta);
        });
}
  },
  methods: {
    closeHamburger() {
      if (this.isNavHeaderOpen) {
        let dl = {
          event: "collapse",
          name: "onlick",
          action: "close",
          type: "click",
          region: "main navbar",
          section: "secondary navbar",
          text: "menu button mobile"
        };
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      }
    },
    onItemChanged(event, currentItem) {
      this.currentItemToDisplay = currentItem ? currentItem.text : "Menu";
    },

    goToRFISection() {
      if (
        this.$router.currentRoute.name !== "home" &&
        this.$router.currentRoute.name !== "support"
      ) {
        // open the RFI modal
        this.$refs["rfi-modal"].show();
      } else {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#get-started").offset().top
          },
          10
        );
      }
    },
    triggerGTM(event) {
      let dl = {
        event: "collapse",
        name: "onlick",
        action: event,
        type: "click",
        region: "main content",
        section: "secondary navbar",
        text: this.currentItemToDisplay.toLowerCase()
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    showMenu() {
      this.isNavHeaderOpen = true;
      if (this.stickyNavOpen)
        this.$root.$emit("bv::toggle::collapse", "nav-sticky");
      let dl = {
        event: "collapse",
        name: "onlick",
        action: "open",
        type: "click",
        region: "main content",
        section: "secondary navbar",
        text: "menu button mobile"
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    closeMenu() {
      this.isNavHeaderOpen = false;
    },
    onResize() {
      if (window.innerWidth > 991) {
        this.isSubMenuClicked = false;
        this.resize = true;
        this.color = true;
        this.addColor();
        this.mobile = false;
      } else {
        this.mobile = true;
      }
    },
    addColor() {
      if (!this.color) {
        document.getElementsByClassName(
          "secondarynav"
        )[0].style.backgroundColor = "#29413b";
        [...document.querySelectorAll(".secondarynav .nav-link")].map(
          elmnt => (elmnt.style.color = "#fff")
        );
        [...document.getElementsByClassName("hamburger-2__line")].map(
          elmnt => (elmnt.style.backgroundColor = "#fff")
        );
        document
          .getElementById("hamburger-2")
          .classList.add("is-active-hamburger");
        this.color = true;
      } else {
        document.getElementsByClassName(
          "secondarynav"
        )[0].style.backgroundColor = "#ffff";
        [...document.querySelectorAll(".secondarynav .nav-link")].map(
          elmnt => (elmnt.style.color = "#2A2A2A")
        );
        [...document.getElementsByClassName("hamburger-2__line")].map(
          elmnt => (elmnt.style.backgroundColor = "#2A2A2A")
        );
        document
          .getElementById("hamburger-2")
          .classList.remove("is-active-hamburger");
        this.color = false;
      }
    },
    makeNavSticky() {
      let upperLimit = document.getElementById("hero");
      let stackable = document.getElementById("stacked-top");
      let sticky = upperLimit.offsetHeight + 70;
      if (window.pageYOffset >= sticky) {
        stackable.classList.add("sticky");
        if (window.innerWidth < 991) {
          document.getElementById("scroll-reveal").classList.remove("d-none");
        }
      } else {
        stackable.classList.remove("sticky");
        if (window.innerWidth < 991) {
          document.getElementById("scroll-reveal").classList.add("d-none");
        }
      }
    }
  }
};
</script>
<style lang="scss">
.sticky-link {
  opacity: 0.6;
  @media only screen and (max-width: 991px) {
    margin-bottom: 10px !important;
  }
}
.is-active {
  opacity: 1 !important;
  border-bottom: 3px solid;
  font-family: stb-s-bold;
  @media only screen and (max-width: 991px) {
    width: fit-content;
  }
}
.is-active-hamburger {
  opacity: 0.5 !important;
}
.sticky-link:not(.is-active) {
  @media only screen and (max-width: 991px) {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.5) !important;
    opacity: 1 !important;
  }
}
#hamburger-2.is-active-hamburger .hamburger-2__line:nth-child(2) {
  opacity: 0;
}
.navBar-desktop-fix {
  @media only screen and (min-width: 767px) {
    top: 16px;
    position: relative;
    left: 5%;
  }
}
#hamburger-2.is-active-hamburger .hamburger-2__line:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}
.hamburger-2__line {
  width: 30px;
  height: 4px;
  background-color: black;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#hamburger-2.is-active-hamburger .hamburger-2__line:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}
.secondarynav {
  -webkit-animation: slide-down 0.3s ease-out;
  -moz-animation: slide-down 0.3s ease-out;
}
.navbar-brand {
  .navbar-toggler-icon {
    display: none;
  }
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slide-down {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
</style>
