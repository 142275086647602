var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offset-how-to-apply container p-0 pl-lg-auto"},[_c('BaseOffsetImg',{attrs:{"justify":"right"},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('div',{staticClass:"backround-cover col-lg-6 offset-background",style:(`background-image: url(${require('../assets/images/How_to_Apply.svg')}); border-radius:10px;`)})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"col-lg-6 negative-top"},[_c('div',{staticClass:"content-header col-10 col-lg-12 mx-auto text-center text-lg-left px-3 pb-4 pb-lg-5 pl-lg-4 pr-lg-5 pt-0"},[_c('h2',{staticClass:"font-fam-Georgia font-lg-36 font-32 pt-4 pt-lg-0"},[_vm._v(" How to apply ")]),_c('p',{staticClass:"font-16 mb-0 col-lg-8 px-0 z-2"},[_vm._v(" Follow these steps to apply to the Starbucks College Achievement Plan ")])]),_c('numbered-list',{staticClass:"col-10 mx-auto col-lg-12 negative-top-numberd pb-lg-3",attrs:{"lists":_vm.items}})],1)]},proxy:true}])}),_c('b-modal',{attrs:{"id":"pathway-modal-desktop","static":true,"size":"xl","hide-footer":"","body-class":"p-0","content-class":"border-0 pathway-container"},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('div',{staticClass:"d-none d-lg-block"},[_c('BaseGtmLink',{staticClass:"close-apply text-dark font-36 ml-auto border-none",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
            event: 'modal',
            name: 'onclick',
            action: 'close',
            type: 'click',
            region: 'main content',
            section: 'embark on your pathway to admission',
            text: 'close cross',
          }},on:{"click":close}},[_c('img',{attrs:{"src":require("../assets/images/Icon-close.svg"),"alt":"close"},on:{"click":close}})])],1),_c('div',{staticClass:"d-lg-none w-100 fixed-top bg-white"},[_c('div',{staticClass:"close-header border-bottom-2-green"},[_c('div',{staticClass:"container"},[_c('BaseGtmLink',{staticClass:"text-dark font-36 ml-auto border-none",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
                event: 'modal',
                name: 'onclick',
                action: 'close',
                type: 'click',
                region: 'main content',
                section: 'embark on your pathway to admission',
                text: 'back',
              }},on:{"click":close}},[_c('img',{attrs:{"src":require("../assets/images/icon-left.svg"),"alt":"left icon"}})]),_c('span',{staticClass:"header-text stb-sBold pl-22 font-16"},[_vm._v("Pathways to admission")])],1)])])]}}])},[_c('div',{staticClass:"container"},[_c('pathway-to-admission')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }