<template>
    <div class="jumbo container text-center col-md-8">
        <h2 class="font-lg-32 pb-lg-36 text-dark-green font-24 font-fam-Georgia pl-0 mb-0 pb-16">{{heading}}</h2>
        <h3 v-if="subheading" class="font-lg-18 font-16 stb-sBold pb-12 mb-0">{{subheading}}</h3>
        <p class="font-lg-16 font-14 mb-0 stb-light" v-html="text"></p>
    </div>
</template>

<script>
export default {
    name: "Jumbotron",
    props: {
        heading: String,
        subheading: String,
        text: String
    }
}
</script>