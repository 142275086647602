<template>
  <BaseSbuxLayout>
    <div class="bg-fafafa pb-90">
      <div class="bg-image"></div>
      <section
        class="get-your-questions bg-white rounded col-lg-10 col-11 py-4 px-lg-5 pt-lg-5 pb-90 mx-auto"
      >
        <h1
          class="mx-auto font-lg-56 col-lg-9 font-36 fam-roboto text-lg-center font-black mb-24"
        >
          Get your questions answered by an enrollment coach
        </h1>
        <p
          class="col-lg-6 mx-auto mb-lg-5 mb-4 text-lg-center font-18 text-dark-green fam-roboto"
        >
          Are you participating in Pathway to Admission? Do you have questions
          about the program or how to earn your admission to Arizona State
          University? Our enrollment coaches have the answers. Just click to
          chat or call.
        </p>
        <div class="bg-dark-green text-white col-lg-10 mx-auto pt-64 pb-64">
          <div class="col-lg-10 mx-auto">
            <div class="row text-center">
              <div class="col-lg-6 mb-lg-0 mb-64">
                <img
                  src="../assets/images/p2a-user.svg"
                  alt=""
                  srcset=""
                  style="height: 82px; width: 72px"
                />
                <h3
                  class="my-lg-5 my-4 font-24 col-10 text-center mx-auto fam-roboto font-black"
                >
                  Speak to an enrollment coach
                </h3>
                <a
                  href="tel:8446912244"
                  class="btn btn-gold font-weight-bold rounded-pill"
                  @click="
                    gtmTrigger(
                      'link',
                      'onclick',
                      'click',
                      'internal link',
                      'main content',
                      'speak to an enrollment coach',
                      'enrollment'
                    )
                  "
                  >844-691-2244</a
                >
              </div>
              <div class="col-lg-6">
                <img
                  src="../assets/images/p2a-monitor.svg"
                  style="height: 82px; width: 93px"
                  alt=""
                  srcset=""
                />
                <h3
                  class="my-lg-5 col-10 text-center mx-auto my-4 font-24 fam-roboto font-black"
                >
                  Questions? Send us a message.
                </h3>
                <a
                  href="javascript:void(0)"
                  @click="
                    ShowChatWindow(),
                      gtmTrigger(
                        'link',
                        'onclick',
                        'click',
                        'internal link',
                        'main content',
                        'questions? send us a message',
                        'chat now'
                      )
                  "
                  class="btn btn-gold font-weight-bold rounded-pill"
                  >{{chatNowText}}</a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </BaseSbuxLayout>
</template>

<script>
import liveAgentMixin from "../services/liveagent-chat";
export default {
  mixins: [liveAgentMixin],
  metaInfo: {
    title: "Chat",
    titleTemplate: "%s | ASU Starbucks",
    htmlAttrs: {
      page: "support",
    },
    link: [
      {
        rel: "canonical",
        href: `${window.location.origin}/p2a-chat`,
      },
    ],
    meta: [
      {
        name: "robots",
        content: "nocarawl,noindex",
      },
    ],
  },
  methods: {
    gtmTrigger(_event, _action, _name, _type, _region, _section, _text) {
      let dl = {
        event: _event,
        name: _action,
        action: _name,
        type: _type,
        region: _region,
        section: _section,
        text: _text,
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch {
        console.log("no gtm/dl is specified");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #8c1d40 !important;
  font-family: "Roboto", sans-serif;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #8c1d40 !important;
}
.bg-image {
  background-image: url("../assets/images/p2a-hero.jpg");
  height: 600px;
  background-position: top;
  background-size: cover;
  width: 100%;
  @media only screen and (max-width: 991px) {
    height: 400px;
  }
}
.bg-fafafa {
  background-color: #f1f1f1;
  * {
    font-family: "Roboto" !important;
  }
}
.get-your-questions {
  margin-top: -10rem;
  @media only screen and (max-width: 991px) {
    margin-top: -5rem;
  }
}
</style>
