var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interest-area"},[_c('p',{staticClass:"fam-roboto font-black font-16 mt-30 mb-24"},[_vm._v("Interest area")]),_c('ais-refinement-list',{attrs:{"attribute":"interestAreas","limit":100,"sortBy":['name']},scopedSlots:_vm._u([{key:"default",fn:function({ items, refine }){return _c('div',{staticClass:"filter-checks"},_vm._l((items),function(item,i){return _c('div',{key:i,staticClass:"my-lg-2 my-1"},[_c('BaseGtmLink',{staticClass:"interest-area-link p-lg-2 border my-lg-2 mr-4 pr-3",class:{ addActivePill: item.isRefined },staticStyle:{"border-radius":"30px"},attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
            event: 'select',
            name: 'onclick',
            action: item.isRefined ? 'onclick' : 'click',
            type: 'box',
            region: 'main content',
            section: 'interest area',
            text: item.value.toLocaleLowerCase(),
          }},on:{"click":function($event){$event.preventDefault();return refine(item.value)}}},[_c('span',{staticClass:"m-0 col pl-2 pr-2",staticStyle:{"width":"15px"},domProps:{"innerHTML":_vm._s(_vm.getImageUrl(item.value))}}),_c('p',{staticClass:"font-12 font-lg-12 mb-0 mr-4 col- ml-3"},[_vm._v(" "+_vm._s(item.value)+" ")])])],1)}),0)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }