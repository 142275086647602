<template>
	<BasePersonaNewWithModalLayout
		:pageData="pageData"
		:cardInfo="cardInfo"></BasePersonaNewWithModalLayout>
</template>

<script>
	import { financial, cardsInfo } from "@/assets/data/persona.json";

	export default {
		metaInfo: {
			title: "Financial",
			titleTemplate: "Starbucks College Achievement Plan | ASU Starbucks",
			htmlAttrs: {
				page: "Financial",
			},
			link: [
				{
					rel: "canonical",
					href: "https://starbucks.asu.edu/",
				},
			],
			meta: [
				{
					name: "robots",
					content: "noindex, nofollow",
				},
			],
		},
		data() {
			return {
				pageData: financial,
				cardInfo: cardsInfo,
			};
		},
	};
</script>

<style></style>
