var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseSbuxLayout',[_c('div',{attrs:{"id":"home"}},[_c('hero',{staticClass:"text-white",attrs:{"subheader":"Receive 100% coverage at Arizona State University with the Starbucks College Achievement Plan","image":"home_Hero_Section_Graphic.svg","page":"home"}}),_c('sticky-nav-bar',{attrs:{"stickyValues":_vm.items}}),_c('article',{staticClass:"monitor-scroll"},[_c('section',{staticClass:"pt-lg-70 pb-lg-114 pt-43 pb-43",attrs:{"id":"ambition-meets-opportunity"}},[_c('jumbotron',{attrs:{"heading":"Ambition meets opportunity","subheading":"Wherever life takes you, the Starbucks College Achievement Plan is here to help","text":"<div class='col-md-10 mx-auto'>Do you want 100% tuition coverage at ASU? The ASU + Starbucks partnership makes this possible for eligible U.S. partners. Choose from 150+ bachelor's degree programs offered 100% online by the nation’s most innovative university.</div>"}}),_c('div',{staticClass:"container text-center my-lg-4 my-3 col-md-8"},[_c('p',{staticClass:"font-16 stb-light"},[_vm._v(" Discover how the program works at our next webinar on "),_c('span',{staticClass:"stb-sBold font-16"},[_c('br',{staticClass:"d-none d-lg-block"}),_vm._v(" "+_vm._s(_vm.$store.state.openHouse.day)+" from "+_vm._s(_vm.$store.state.openHouse.time)+". "),_c('BaseGtmLink',{staticClass:"text-underline text-dark-green stb-light",attrs:{"tag":"a","href":_vm.$store.state.openHouse.link,"gtm":{
                  event: 'link',
                  name: 'onclick',
                  action: 'click',
                  type: 'external link',
                  region: 'main content',
                  section: 'ambition meets opportunity',
                  text: 'register now!',
                }}},[_vm._v(" Register now! ")])],1)])])],1),_c('div',{attrs:{"id":"placeholder-id"}}),_c('section',{staticClass:"bg-green-2",attrs:{"id":"how-it-works"}},[_c('how-it-works',{attrs:{"id":"how-it-work"}})],1),_c('section',{attrs:{"id":"degrees"}},[_c('degrees')],1),_c('section',{staticClass:"pt-lg-200",attrs:{"id":"how-to-apply"}},[_c('how-to-apply')],1),_c('section',{staticClass:"pt-lg-170 pt-36 container",attrs:{"id":"veteran"}},[_c('vetran')],1),_c('section',{staticClass:"pt-lg-120 pt-36 container",attrs:{"id":"testimonial"}},[_c('testimonial')],1),_c('section',{staticClass:"pt-lg-100 pt-90",attrs:{"id":"online-learning"}},[_c('online-learning')],1),_c('section',{staticClass:"pt-100",attrs:{"id":"get-started"}},[_c('rfi')],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }