<template>
  <section class="position-relative" :class="sectionClass">
    <div class="container-fluid px-0">
      <div
        class="row bg-backdrop d-none d-lg-block no-gutters bg-image mt-lg-128 mt-32"
        :style="backdropStyle"
      >
        <div class="parallax-bg"></div>
        <div :class="desktopColumnClass" style="z-index: 1">
          <CardInfoHorizontal
            class="card-info"
            :display-cta="displayCta"
            :cta-text="ctaText"
            :cta-text-size="ctaTextSize"
            :cta-link="ctaLink"
            bg-image-source="../assets/images/careers/upcomingEvents.png"
            :title="title"
            :title-level="titleLevel"
            :title-size="titleSize"
            :title-variant="titleVariant"
            :border-variant="borderVariant"
            :display-logo="displayLogo"
            logo-source="../assets/images/careers/upcomingEvents.png"
            :logo-alt="logoAlt"
            :display-highlight="displayHighlight"
            :highlight-color="highlightColor"
            :highlight-width="highlightWidth"
            :display-label="displayLabel"
            :label-bg="labelBg"
            :label-text="labelText"
            :label-text-size="labelTextSize"
            :label-text-variant="labelTextVariant"
            :cta-as-button="ctaAsButton"
            :cta-button-variant="ctaButtonVariant"
            :cta-text-variant="ctaTextVariant"
            :cta-text-weight="ctaTextWeight"
            :cta-link-target="ctaLinkTarget"
            @ctaClick="handleCtaClick($event)"
          >
            <slot />
          </CardInfoHorizontal>
        </div>
      </div>
    </div>

    <div class="row d-block d-lg-none px-16 px-md-24 no-gutters">
      <div class="col">
        <CardInfoHorizontal
          :display-cta="displayCta"
          :cta-text="ctaText"
          :cta-text-size="ctaTextSize"
          :cta-link="ctaLink"
          bg-image-source="../assets/images/careers/upcomingEvents.png"
          :title="title"
          :title-level="titleLevel"
          :title-size="titleSize"
          :title-variant="titleVariant"
          :border-variant="borderVariant"
          :display-logo="displayLogo"
          logo-source="../assets/images/careers/upcomingEvents.png"
          :logo-alt="logoAlt"
          :display-highlight="displayHighlight"
          :highlight-color="highlightColor"
          :highlight-width="highlightWidth"
          :display-label="displayLabel"
          :label-bg="labelBg"
          :label-text="labelText"
          :label-text-size="labelTextSize"
          :label-text-variant="labelTextVariant"
          :cta-as-button="ctaAsButton"
          :cta-button-variant="ctaButtonVariant"
          :cta-text-variant="ctaTextVariant"
          :cta-text-weight="ctaTextWeight"
          :cta-link-target="ctaLinkTarget"
          @ctaClick="handleCtaClick($event)"
        >
          <slot />
        </CardInfoHorizontal>
      </div>
    </div>
  </section>
</template>

<script>
import UpcomingEventsCards from "../components/UpcomingEventsCards.vue";
// import lozad from "lozad";

export default {
  name: "SectionParallaxApollo",
  components: {
    CardInfoHorizontal: UpcomingEventsCards,
  },
  props: {
    bgColor: {
      type: String,
      default: "white",
    },
    bgImageSource: {
      type: String,
    },
    bgImageLazy: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    titleLevel: {
      type: String,
      default: "h3",
      validator: (value) => {
        return value.match(/(h1|h2|h3|h4|h5|h6)/);
      },
    },
    titleSize: {
      type: String,
      default: "small",
    },
    titleVariant: {
      type: String,
      default: "dark-3",
    },
    displayLogo: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: "../assets/images/careers/upcomingEvents.png",
    },
    logoAlt: {
      type: String,
      default: "cih logo alt",
    },
    displayHighlight: {
      type: Boolean,
      default: true,
    },
    highlightColor: {
      type: String,
      default: "var( --secondary, #ffc627)",
    },
    highlightWidth: {
      type: Number,
      default: 5,
    },
    displayLabel: {
      type: Boolean,
      default: false,
    },
    labelBg: {
      type: String,
      default: "secondary",
    },
    labelText: {
      type: String,
      default: "lorem ipsum",
    },
    labelTextSize: {
      type: String,
      default: "xs",
    },
    labelTextVariant: {
      type: String,
      default: "dark-3",
    },
    borderVariant: {
      type: String,
      default: "light-4",
    },
    displayCta: {
      type: Boolean,
      default: true,
    },
    ctaText: {
      type: String,
      default: "Read more",
    },
    ctaTextSize: {
      type: String,
      default: "large",
    },
    ctaTextVariant: {
      type: String,
      default: "primary",
    },
    ctaTextWeight: {
      type: String,
      default: "normal",
    },
    ctaAsButton: {
      type: Boolean,
      default: false,
    },
    ctaButtonVariant: {
      type: String,
      default: "primary",
    },
    ctaLink: {
      type: String,
      default: "https://rds.edpl.us",
    },
    ctaLinkTarget: {
      type: String,
      default: "_blank",
      validator: (value) => {
        return value.match(/(_blank|_parent|_self|_top)/);
      },
    },
    lgColumns: {
      type: String,
      default: "8",
    },
    offsetLgColumns: {
      type: String,
      default: "2",
    },
    backdropHeight: {
      type: String,
      default: "457",
    },
  },
  computed: {
    sectionClass() {
      return `bg-${this.bgColor}`;
    },
    desktopColumnClass() {
      return `offset-lg-2 col-lg-8 position-relative`;
    },
    backdropStyle() {
      const style = {};
      style["--bd-height"] = `${this.backdropHeight}px`;

      if (!this.bgImageLazy) {
        style["--section-bg-image"] = `url('${this.bgImageSource}')`;
      }

      return style;
    },
    dataBgImage() {
      return this.bgImageLazy ? this.bgImageSource : null;
    },
  },
  methods: {
    handleCtaClick(event) {
      this.$emit("ctaClick", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-backdrop {
  height: var(--bd-height, 492px);
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  background-image: none !important;
}

.parallax-bg {
  height: var(--bd-height, 492px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.parallax-bg:after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-position: center 5%;
  background-size: cover;
  background-repeat: no-repeat;
  will-change: transform;
  box-shadow: inset 0 0 0 2000px rgba(31, 30, 30, 0.82);
  background-image: var(--section-bg-image);
}

.card-info {
  top: 92px;
  padding: 0;
  /* added to fix clip-mask z-index issue */
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.bg-image {
  background-image: url("../assets/images/careers/upcomingEvents.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
</style>
