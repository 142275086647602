<template>
  <div class="position-sticky bg-white" style="top: 0; z-index: 1000">
    <nav class="navbar navbar-light bg-white px-1 px-sm-3">
      <div class="container px-1 px-sm-3">
        <a class="navbar-brand" href="/" @click="triggerNavGtm()">
          <img src="@/assets/images/logo.svg" alt="logo" class="logo-persona" />
        </a>
        <div v-if="displayCta" class="ml-auto">
          <a
            href="javascript:void(0)"
            @click="smoothScroll()"
            class="btn btn-dark rounded-pill stb-sBold font-14 font-weight-bold"
            >Get started</a
          >
        </div>
      </div>
    </nav>
    <slot></slot>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    displayCta: {
      type: Boolean,
      default: false,
    },
  },
  name: "PersonaNavbar",
  methods: {
    triggerNavGtm() {
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "navbar",
        section: "main navbar",
        text: "co brand scap asu logo",
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch {
        console.error("analytics fault");
      }
    },
    smoothScroll() {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#js-get-started").offset().top - 150,
        },
        900
      );
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "navbar",
        section: "main navbar",
        text: "get started",
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch {
        console.error("analytics fault");
      }
    },
  },
};
</script>

<style lang="scss">
.logo-persona {
  width: 277px;
  @media only screen and (max-width: 400px) {
    width: 180px;
  }
}
</style>
