var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('BaseGtmLink',{staticClass:"btn scroll-to-top-btn btn-dark-green",attrs:{"tag":"button","href":"javascript:void(0)","round":"","gtm":{
    event: 'link',
    name: 'onclick',
    action: 'click',
    type: 'internal link',
    region: 'main content',
    section: 'sticky anchor',
    text: 'up arrow'
  }},on:{"click":function($event){return _vm.scrollTop()}}},[_c('img',{staticStyle:{"transform":"rotateZ(-180deg)"},attrs:{"src":require("../assets/images/scroll-to-top.svg"),"alt":"scroll-down"}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }