<template>
  <footer>
    <slot name="primary-section" />

    <!-- university-services-menu section -->
    <section class="bg-warning text-dark">
      <div class="container">
        <div class="row">
          <div class="col">
            <div
              class="d-flex flex-column flex-lg-row align-items-center justify-content-end"
            >
              <b-img-lazy
                src="https://live-asuocms.ws.asu.edu/sites/default/files/2023-10/ASU%202024%20Innovation%20RGB%20for%20Digital%20Lockup%20wide%20on%20gold.png"
                alt="Number one in the U.S. for innovation. ASU ahead of MIT and Stanford—U.S. News & World Report, 9 years, 2016–2024"
                class="order-1 order-lg-2 innovation-image"
              ></b-img-lazy>
              <nav
                v-if="displaySecondaryMenu"
                class="nav grid-flex-menu order-2 order-lg-1 mb-space-sm mb-lg-0"
                aria-label="University Services"
              >
                <a
                  v-for="(item, index) in secondaryItems"
                  :key="index"
                  :href="item.uri"
                  :target="formatLinkTarget(item.target)"
                  class="nav-link text-dark menu-item font-weight-bold py-space-xxs py-lg-0"
                  rel="noopener noreferrer"
                  @click="handleSecondaryMenuLinkClick(item)"
                >
                  {{ item.text }}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- university-legal-compliance-menu section -->
    <section :class="legalClass" class="bg-light">
      <div class="container">
        <div class="row">
          <div class="col">
            <nav
              class="nav grid-flex-menu order-2 order-lg-1"
              aria-label="University Legal and Compliance"
            >
              <a
                v-for="(item, index) in tertiaryItems"
                :key="index"
                :href="item.uri"
                :target="formatLinkTarget(item.target)"
                @click="handleTertiaryMenuLinkClick(item)"
                class="nav-link text-dark menu-item py-space-xxs py-lg-0"
                rel="noopener noreferrer"
              >
                {{ item.text }}
              </a>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "FooterStandard",
  props: {
    displaySecondaryMenu: {
      type: Boolean,
      default: true,
    },
    displayApplyNow: {
      type: Boolean,
      default: false,
    },
    displayRfiCta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      secondaryItems: [
        {
          text: "Maps and Locations",
          uri: "https://asu.edu/about/locations-maps",
          target: "BLNK",
        },
        {
          text: "Jobs",
          uri: "https://cfo.asu.edu/applicant",
          target: "BLNK",
        },
        {
          text: "Directory",
          uri: " https://isearch.asu.edu/asu-people/",
          target: "BLNK",
        },
        {
          text: "Contact ASU",
          uri: "https://asu.edu/about/contact",
          target: "BLNK",
        },
        {
          text: "My ASU",
          uri: " https://my.asu.edu/",
          target: "BLNK",
        },
      ],
      tertiaryItems: [
        {
          text: "Copyright and Trademark",
          uri: "https://www.asu.edu/about/copyright-trademark",
          target: "BLNK",
        },
        {
          text: "Accessibility",
          uri: "https://accessibility.asu.edu/report",
          target: "BLNK",
        },
        {
          text: "Privacy",
          uri: "https://www.asu.edu/about/privacy",
          target: "BLNK",
        },
        {
          text: "Terms of Use",
          uri: "https://www.asu.edu/about/terms-of-use",
          target: "BLNK",
        },
        {
          text: "Emergency",
          uri: "https://cfo.asu.edu/emergency",
          target: "BLNK",
        },
      ],
    };
  },
  computed: {
    legalClass() {
      if (this.displayApplyNow || this.displayRfiCta) {
        return `pb-space-xl pt-space-xs py-lg-space-xs`;
      } else {
        return `py-lg-3`;
      }
    },
  },
  components: {},
  methods: {
    formatLinkTarget(target) {
      if (target == "SELF") {
        return "_self";
      } else {
        return "_blank";
      }
    },
    handleSecondaryMenuLinkClick(item) {
      const trackingData = {
        event: "link",
        name: "onclick",
        action: "click",
        type: "internal link",
        region: "footer",
        section: "secondary footer",
        text: item.text.toLowerCase(),
      };
      this.$gtm.trackEvent(trackingData);
      this.$emit("secondaryMenuLinkClick", trackingData);
    },
    handleTertiaryMenuLinkClick(item) {
      const trackingData = {
        event: "link",
        name: "onclick",
        action: "click",
        type: "internal link",
        region: "footer",
        section: "tertiary footer",
        text: item.text.toLowerCase(),
      };
      this.$gtm.trackEvent(trackingData);
      this.$emit("tertiaryMenuLinkClick", trackingData);
    },
  },
};
</script>

<style lang="scss" scoped>
nav.grid-flex-menu {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  justify-items: start;
  flex-wrap: wrap;
  width: 100%;
}

img.innovation-image {
  width: 100%;
  height: auto;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  img.innovation-image {
    height: 100px;
    width: auto;
  }
  nav.grid-flex-menu {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  a.menu-item:hover {
    text-decoration: underline;
  }
}
</style>
