<template>
  <section id="hero" :class="`hero-page-${page}`">
    <b-container class="position-relative container-hero">
      <div class="content col-md-8 pl-0">
        <h1 class="font-32 stb-sBold font-lg-48 mt-4 mt-lg-0">
          <span class="underlined-special">Go to college</span>, on us
        </h1>
        <p class="col-lg-8 px-0 stb-light col-11 col-lg-8 font-lg-18 font-16">
          {{ subheader }}
        </p>
      </div>
      <div class="hero-img" v-if="image">
        <img
          :src="require(`../assets/images/${image}`)"
          alt="hero image"
          class="img-fluid"
        />
      </div>
    </b-container>
  </section>
</template>
<script>
export default {
  name: "Hero",
  props: {
    color: String,
    header: String,
    subheader: String,
    image: String,
    page: String,
  },
  methods: {
    getImageUrl(image) {
      return require("../assets/images/" + image);
    },
  },
};
</script>

<style lang="scss" scoped>
#hero {
  background-color: #1f3933;
  overflow: hidden;
}
#hero,
.container-hero {
  height: 425px;
  display: flex;
  position: relative;
  align-items: center;
  @media only screen and (min-width: 991px) {
    align-items: center;
    height: 570px;
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
}
.underlined-special {
  background-image: url("../assets/images/home-hero-scribble.svg");
  background-size: contain;
  background-position: center 1.8rem;
  @media only screen and (min-width: 991px) {
    background-position: center 2.5rem;
    height: 70px;
  }
  background-repeat: no-repeat;
  height: 50px;
  display: inline-flex;
}
.hero-img {
  img {
    position: absolute;
    right: -21%;
    bottom: -37%;
    z-index: 0;
    width: 80%;
    @media only screen and (max-width: 991px) {
      width: 70%;
      bottom: -21%;
      right: 0;
    }
    @media only screen and (max-width: 600px) {
      width: auto;
      bottom: -14%;
      right: 0;
    }
  }
}
</style>
