<template>
  <BaseSbuxLayout>
    <div id="home">
      <hero
        class="text-white"
        subheader="Receive 100% coverage at Arizona State University with the Starbucks College Achievement Plan"
        image="home_Hero_Section_Graphic.svg"
        page="home"
      />
      <sticky-nav-bar :stickyValues="items" />
      <article class="monitor-scroll">
        <section
          class="pt-lg-70 pb-lg-114 pt-43 pb-43"
          id="ambition-meets-opportunity"
        >
          <jumbotron
            heading="Ambition meets opportunity"
            subheading="Wherever life takes you, the Starbucks College Achievement Plan is here to help"
            text="<div class='col-md-10 mx-auto'>Do you want 100% tuition coverage at ASU? The ASU + Starbucks partnership makes this possible for eligible U.S. partners. Choose from 150+ bachelor's degree programs offered 100% online by the nation’s most innovative university.</div>"
          />
          <div class="container text-center my-lg-4 my-3 col-md-8">
            <p class="font-16 stb-light">
              Discover how the program works at our next webinar on
              <span class="stb-sBold font-16">
                <br class="d-none d-lg-block" />
                {{ $store.state.openHouse.day }} from
                {{ $store.state.openHouse.time }}.
                <BaseGtmLink
                  tag="a"
                  :href="$store.state.openHouse.link"
                  class="text-underline text-dark-green stb-light"
                  :gtm="{
                    event: 'link',
                    name: 'onclick',
                    action: 'click',
                    type: 'external link',
                    region: 'main content',
                    section: 'ambition meets opportunity',
                    text: 'register now!',
                  }"
                >
                  Register now!
                </BaseGtmLink></span
              >
            </p>
          </div>
        </section>
        <!-- TODO: work this later -->
        <div id="placeholder-id"></div>
        <section class="bg-green-2" id="how-it-works">
          <how-it-works id="how-it-work" />
        </section>
        <section id="degrees">
          <degrees />
        </section>
        <section class="pt-lg-200" id="how-to-apply">
          <how-to-apply />
        </section>
        <section id="veteran" class="pt-lg-170 pt-36 container">
          <vetran />
        </section>
        <section class="pt-lg-120 pt-36 container" id="testimonial">
          <testimonial />
        </section>
        <section id="online-learning" class="pt-lg-100 pt-90">
          <online-learning />
        </section>
        <section id="get-started" class="pt-100">
          <rfi />
        </section>
        <!-- <div style="background-color: rgb(250, 250, 250)">
        <div class="container">
          <footer-desclimer class="col-md-10 mx-auto pt-50 pb-50" />
        </div>
      </div> -->
      </article>
    </div>
  </BaseSbuxLayout>
</template>

<script>
import hero from "@/components/hero-home.vue";
import StickyNavBar from "@/components/sticky-nav-bar.vue";
import HowItWorks from "@/components/how-it-works-no-anime";
import jumbotron from "@/components/jumbotron.vue";
// import FooterDesclimer from "@/components/footer-desclimer.vue";
import degrees from "@/components/degrees.vue";
import HowToApply from "@/components/how-to-apply.vue";
import vetran from "@/components/vetran.vue";
import testimonial from "@/components/testimonial.vue";
import onlineLearning from "@/components/online-learning.vue";
import rfi from "@/components/rfi.vue";
import $ from "jquery";
export default {
  name: "home",
  data() {
    return {
      items: [
        {
          name: "How It Works",
          id: "how-it-works",
        },
        {
          name: "Degrees",
          id: "degrees",
        },
        {
          name: "How To Apply",
          id: "how-to-apply",
        },
        {
          name: "Online Learning",
          id: "online-learning",
        },
        {
          name: "Get Info",
          id: "get-started",
        },
      ],
    };
  },
  mounted() {
    let self = this;
    let windowWidth = $(window).width();
    window.addEventListener("resize", function() {
      if ($(window).width() != windowWidth) {
        self.$store.state.orientationChanged = true;
        let self2 = self;
        setTimeout(() => {
          self2.$store.state.orientationChanged = false;
          if (self2.animationStarted === true) {
            var elmnt = document.getElementById("placeholder-id");
            elmnt.scrollIntoView();
          }
        }, 200);
      }
      windowWidth = $(window).width();
    });
  },
  components: {
    hero,
    jumbotron,
    degrees,
    StickyNavBar,
    HowToApply,
    vetran,
    testimonial,
    onlineLearning,
    rfi,
    // FooterDesclimer,
    HowItWorks,
  },
  metaInfo: {
    title: "Starbucks College Achievement Plan",
    titleTemplate: "%s | ASU Starbucks",
    htmlAttrs: {
      page: "home",
    },
    link: [{ rel: "canonical", href: `${window.location.origin}/` }],
    meta: [
      {
        name: "description",
        content:
          "Discover how Starbucks will cover your college tuition at ASU Online and browse the 100+ degrees you can earn online.",
      },
    ],
  },
};
</script>
