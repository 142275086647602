var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-12 stb-light lh-1 mb-32 mb-lg-16"},[_vm._v(" By submitting my information, I consent to ASU contacting me about educational services using automated calls, prerecorded voice messages, SMS/text messages or email at the information provided above. Message and data rates may apply. To opt out of messages, text STOP to 46278. Consent is not required to receive services, and I may call ASU directly at "),_c('BaseGtmLink',{staticClass:"text-light-green text-underline",attrs:{"tag":"a","href":"tel:844-278-7289","target":"_blank","gtm":{
      event: 'link',
      name: 'onclick',
      action: 'click',
      type: 'internal link',
      region: 'main content',
      section: 'get started at asu today!',
      text: '844-278-7289',
    }}},[_vm._v("844-278-7289.")]),_vm._v(" I consent to ASU’s "),_c('BaseGtmLink',{staticClass:"text-light-green text-underline",attrs:{"tag":"a","href":"https://asuonline.asu.edu/text-terms/","target":"_blank","gtm":{
      event: 'link',
      name: 'onclick',
      action: 'click',
      type: 'internal link',
      region: 'main content',
      section: 'get started at asu today!',
      text: 'mobile terms and conditions',
    }}},[_vm._v(" mobile terms and conditions,")]),_c('BaseGtmLink',{staticClass:"text-light-green text-underline",attrs:{"tag":"a","href":"https://www.asu.edu/privacy/","target":"_blank","gtm":{
      event: 'link',
      name: 'onclick',
      action: 'click',
      type: 'internal link',
      region: 'main content',
      section: 'get started at asu today!',
      text: 'privacy statement',
    }}},[_vm._v(" Privacy Statement,")]),_vm._v(" including the European Supplement, and ASU Online’s "),_c('BaseGtmLink',{staticClass:"text-light-green text-underline",attrs:{"tag":"a","href":"https://asuonline.asu.edu/web-analytics-privacy-2/","target":"_blank","gtm":{
      event: 'link',
      name: 'onclick',
      action: 'click',
      type: 'internal link',
      region: 'main content',
      section: 'get started at asu today!',
      text: 'web analytics privacy policy.',
    }}},[_vm._v("web analytics privacy policy.")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }