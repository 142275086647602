<template>
  <BasePersonaNewLayout
    :pageData="pageData"
    :cardInfo="cardInfo"
  ></BasePersonaNewLayout>
</template>

<script>
import { wellBeing, cardsInfo } from "@/assets/data/persona.json";
export default {
  metaInfo: {
    title: "WellBeing",
    titleTemplate: "Starbucks College Achievement Plan | ASU Starbucks",
    htmlAttrs: {
      page: "WellBeing",
    },
    link: [
      {
        rel: "canonical",
        href: "https://starbucks.asu.edu/",
      },
    ],
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow",
      },
    ],
  },
  data() {
    return {
      pageData: wellBeing,
      cardInfo: cardsInfo,
    };
  },
};
</script>

<style></style>
