<template>
  <div class="row mb-lg-134 mb-96 mt-24">
    <template v-for="(grid, i) in compData">
      <a
        v-if="grid.path !== currentPage"
        :key="i"
        :href="grid.path"
        class=" col-12 service-links col-lg-4 mb-16 mb-lg-0"
        @click="triggerCardClickDataLayer(grid.title)"
      >
        <div class="card bg-dark-green text-white d-flex w-100 h-100">
          <div class="card-body h-100">
            <h3 class="font-24 pt-24 pb-24 stb-regular mb-0">
              {{ grid.title }}
            </h3>
            <div>
              <p class="stb-light font-16 mb-0">{{ grid.text }}</p>
            </div>
          </div>
          <div class="pb-40 card-footer border-0 bg-transparent pt-0">
            <u
              ><a class="font-16 text-white link-hover" :href="grid.link"
                >Learn more</a
              ></u
            >
          </div>
        </div>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: "personacards",
  computed: {
    currentPage() {
      return this.$route.path;
    },
  },
  data() {
    return {
      page: "career",
      compData: [
        {
          category: "career",
          title: "Online degrees",
          text:
            "ASU offers more than 100 bachelor’s degrees online. Explore program offerings that align with your career goals.",
          path: "/personalize/career",
        },
        {
          category: "time",
          title: "Time commitment",
          text:
            "The flexibility of ASU Online can make it easier to add school into your schedule. Learn how you can fit college around your life.",
          path: "/personalize/time",
        },
        {
          category: "cost",
          title: "College costs",
          text:
            "The Starbucks College Achievement Plan covers your tuition and fees each semester. Learn more about 100% tuition coverage and how it works.",
          path: "/personalize/cost",
        },
        {
          category: "online",
          title: "Learning experience",
          text:
            "ASU Online courses are as engaging and rigorous as on-campus courses. Explore the online learning experience and our personalized support services.",
          path: "/personalize/online",
        },
      ],
    };
  },
  methods: {
    triggerCardClickDataLayer(val) {
      let dl = {
        event: "link",
        name: "onclick",
        action: "click",
        type: "internal link",
        region: "main content",
        section: "what else do i need to know?",
        text: val.toLocaleLowerCase(),
      };
      console.log(val);
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
  },
};
</script>

<style lang="scss" scoped>
.common-height {
  min-height: 350px;
}
.bg-dark-green {
  border: 1px solid transparent;
}

.service-links {
  &:hover {
    color: #29413b;
    text-decoration: none;
    .link-hover {
      color: #00704a !important;
    }
    .bg-dark-green {
      background-color: white !important;
      color: #29413b !important;
      border-color: #29413b !important;
      transition: all 0.3s ease;
    }
  }
}
.card-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 0px !important;
}
.card-footer {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 16px !important;
}
</style>
