<template>
  <section class="algolia-cards card-deck">
    <div
      class="algolia-card__header degree-header"
      :style="`background-image: url(${item.degreeImage});`"
    ></div>
    <div class="container">
      <div class="algolia-card__type" style="margin-top: -10px">
        <span class="type bg-dark-green text-white p-1 font-weight-bold font-12"
          >Degree</span
        >
      </div>
      <div class="algolia-card__title">
        <h3 class="fam-roboto card-title mt-2 font-black font-16">
          {{ item.title }}
        </h3>
        <span
          class="bg-dark-green d-block"
          style="height: 2px; width: 30px"
        ></span>
      </div>
    </div>
    <hr class="mt-0 w-100" />
    <div class="algolia-card__dates container row align-items-center mb-10">
      <div class="col-11 pr-0">
        <p class="font-14 mb-6">
          Starts: {{ splitNextStartDate(item.nextStartDate) }}
        </p>
        <BaseGtmLink
          tag="a"
          href="javascript:void(0)"
          class="btn btn-link text-light-green mb-10 font-16 text-underline p-0"
        >
          Learn more
        </BaseGtmLink>
      </div>
      <div class="col-1 pl-0">
        <BaseGtmLink
          tag="a"
          href="javascript:void(0)"
          class="btn btn-link text-light-green mb-10 font-16 text-underline"
        >
          <img
            src="../assets/images/Icon-chevron-right.svg"
            alt="Icon-chevron-right"
          />
        </BaseGtmLink>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    splitNextStartDate(nextstartdate) {
      let arr = nextstartdate.split("-");
      return `${arr[1]}/${arr[2]}/${arr[0]}`;
    },
  },
};
</script>

<style lang="scss">
.degree-header {
  background-size: cover;
  height: 180px;
  @media only screen and (max-width: 991px) {
    height: 220px;
  }
  width: 100%;
}
.algolia-cards {
  border-radius: 12px;
  border: 1px solid #dee2e6;
  border-bottom: 5px solid #29413b !important;
}
.algolia-card__header {
  border-radius: 12px;
  border-bottom-right-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}
.algolia-card__title {
  height: 85px;
  display: flex;
  justify-content: baseline;
  flex-direction: column;
}
</style>
<style lang="scss" scoped>
.card-deck {
  margin-left: 0px;
}
</style>
