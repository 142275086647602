<template>
  <section id="hero" class="background-cover" :class="`hero-page-${page}`">
    <b-container>
      <div class="content col-md-8 pl-0">
        <h1 class="font-32 stb-sBold font-lg-48 mt-4 mt-lg-0" v-html="header"></h1>
        <p class="px-0 stb-light col-11 font-lg-18 font-16">{{ subheader }}</p>
      </div>
    </b-container>
  </section>
</template>
<script>
export default {
  name: "Hero",
  props: {
    color: String,
    header: String,
    subheader: String,
    image: String,
    page: String
  },
  methods: {
    getImageUrl(image) {
      return require("../assets/images/" + image);
    }
  }
};
</script>

<style lang="scss" scoped>
#hero {
  height: 425px;
  display: flex;
  background-color: #1F3933;
  background-image: url("../assets/images/faq-hero.svg");
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @media only screen and (min-width: 991px) {
    align-items: center;
    height: 540px;
  }
  @media only screen and (max-width: 991px) {
    height: 245px;
    align-items: center;
  }
}
</style>
