<template>
  <BasePersonaLayout :available="pageData.availableIn">
    <hero :hero="pageData.hero" />
    <div class="container pt-lg-96 pt-48 pb-48 pb-64 pb-lg-96">
      <jumbotron :content="pageData.jumbo" />
    </div>
    <div class="container pb-48 pb-64 pb-lg-96">
      <image-text
        :heading="pageData.ListImage.right.heading"
        :image="getImageUrl(pageData.ListImage.right.image)"
        justify="right"
        :lists="pageData.ListImage.right.lists"
        section="how-will-i-fund"
      />
    </div>
    <div class="container text-center pb-lg-32 pb-24">
      <h3 class="highlight-text text-center font-black font-lg-36 font-24">
        What if I still have questions?
      </h3>
    </div>
    <div class="container">
      <contact :content="contacts" />
    </div>
    <div
      style="background-color: #1f3932"
      v-if="pageData.image_container.available"
      class="position-relative"
    >
      <image-container
        :imageMobile="getImageUrl(pageData.image_container.imageMobile)"
        :image="getImageUrl(pageData.image_container.image)"
        :imageClass="pageData.image_container.imageClass"
      />
    </div>
  </BasePersonaLayout>
</template>

<script>
import { cost } from "@/assets/data/persona.json";

import hero from "@/components/personalize/hero";
import jumbotron from "@/components/personalize/jumbotron";
import ImageContainer from "@/components/personalize/imageContainer";
import ImageText from "@/components/personalize/image-text";
import contact from "@/components/personalize/contact";

export default {
  metaInfo: {
    title: "Cost",
    titleTemplate: "Starbucks College Achievement Plan | ASU Starbucks",
    htmlAttrs: {
      page: "Cost",
    },
    link: [{ rel: "canonical", href: "https://starbucks.asu.edu/" }],
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow",
      },
    ],
  },

  data() {
    return {
      pageData: cost,

      contacts: [
        {
          image: "degree-hat.svg",
          header: "Ready to begin <br> the application?",
          action: {
            label: "Apply now",
            type: "a",
            url: "https://webapp4.asu.edu/uga_admissionsapp/?partner=SCAP",
          },
        },
        {
          image: "phone.svg",
          header: "Speak to an <br> enrollment coach.",
          action: {
            label: "844-ASU-SBUX",
            type: "a",
            url: "tel:844-278-7289",
          },
        },
        {
          image: "chat.svg",
          header: "Have a question? <br> Chat with our team.",
          action: {
            label: "Chat now",
            type: "button",
            url: "#",
          },
        },
      ],
    };
  },
  methods: {
    getImageUrl(image) {
      if (image) return require("@/assets/images/personalize/" + image);
      else return false;
    },
  },
  components: {
    hero,
    jumbotron,
    contact,
    ImageText,
    ImageContainer,
  },
};
</script>

<style lang="scss" scoped></style>
