import { render, staticRenderFns } from "./accolades.vue?vue&type=template&id=86cfe08a&scoped=true"
import script from "./accolades.vue?vue&type=script&lang=js"
export * from "./accolades.vue?vue&type=script&lang=js"
import style0 from "./accolades.vue?vue&type=style&index=0&id=86cfe08a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86cfe08a",
  null
  
)

export default component.exports