let util = {
    getEnv() {
        return window.location.host.split('.')[0] === 'staging' ? 'stage' : (window.location.host.split('.')[0].indexOf('localhost') >= 0 ? 'dev' : 'prod')
    },
    getAPIUrl() {
        return this.getEnv() !== 'dev' ? `https://e3toenbjcb.execute-api.us-west-2.amazonaws.com/${this.getEnv()}` : 'http://localhost:3002';
    },
    getLeadAPIUrl() {
        return `https://${this.getEnv() === 'prod' ? '' : 'qa-'}api.edpl.us/v1/asuo/rfi-leads?get_dx_url=true`;
    },
    getParterValidationAPIUrl(subx_id) {
        return `https://${this.getEnv() === 'prod' ? '' : 'qa-'}api.edpl.us/v1/cp/starbucks/partner?sbux_id=${subx_id}`;
    },
    getDXLink() {
        return this.getEnv() === 'prod' ? 'https://next.online.asu.edu?rfi_id=%rfi_id%' : 'https://staging-next.online.asu.edu?rfi_id=%rfi_id%';
    }
}

export default util;