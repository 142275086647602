<template>
  <div
    class="container pt-lg-48 pb-lg-64 mb-lg-96 mb-48 pb-48 pt-114 position-relative"
    :class="imageClass"
  >
    <h2
      class="font-lg-24 font-16 text-white text-center text-lg-left same-bg mb-48"
    >
      <div class="text">
        How does the Starbucks College <br />
        Achievement Plan work
      </div>
      <img
        class="scribble-3 d-none d-lg-block"
        src="@/assets/images/personalize/how-it-works-scrrible-3.svg"
        alt=".how-it-works-scrrible-3.svg"
      />
    </h2>
    <img
      :src="Image"
      alt="image"
      class="img-fluid"
      :class="{
        'd-none d-md-block':
          imageClass === 'cost-conscious' || imageClass === 'home-page',
      }"
    />
    <div class="mobile-view container text-center">
      <img
        class="carousal-end img-fluid show-on-landscape d-md-none mobile-image-final-slide"
        src="@/assets/images/personalize/Howitworks-Mobile-01.png"
        alt="mobile-image-final-slide"
      />
      <div class="d-md-none mt-4">
        <template v-for="(slide, i) in slidesMobile">
          <div :key="i" class="carousal-end text-left">
            <div
              class="colour-dot mb-2"
              :style="`background-color:${slide.colour}`"
            ></div>
            <h2
              class="font-24 font-lg-30 text-white font-fam-Georgia"
              v-html="slide.heading"
            ></h2>
            <h3
              v-if="slide.subHeading"
              class="font-14 font-lg-30 font-fam-Georgia text-brand-gold"
              v-html="slide.subHeading"
            ></h3>
            <p class="font-14 text-white col-11 pl-0" v-html="slide.text"></p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    Image: String,
    imageClass: String,
    imageMobile: String,
  },
  data() {
    return {
      slidesMobile: [
        {
          colour: "#00A4E0",
          heading: "College Achievement Plan Scholarship",
          text:
            "Through the Starbucks College Achievement Plan, you receive a College Achievement Plan (CAP) Scholarship applied by ASU each semester toward undergraduate tuition.",
        },
        {
          colour: "#FFC627",
          heading: "Starbucks Tuition Benefit",
          subHeading: "(100% tuition coverage)",
          text:
            "After the CAP Scholarship and any need-based federal aid grants are applied (if applicable), the remaining tuition and fees are covered by the Starbucks Tuition Benefit and applied directly to your ASU account before the start of the semester or session.",
        },
        {
          colour: "#AF3656",
          heading: "Books and supplies",
          subHeading: "(Student responsibility)",
          text:
            "The Starbucks College Achievement Plan doesn’t cover books or supplies. These are the student’s responsibility to pay. Yet, most partners find their financial aid can help with these costs.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.colour-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.cost-conscious {
  &:before {
    content: "";
    background-image: url("../../assets/images/personalize/LINE.svg");
    height: 100%;
    width: 10px;
    background-repeat: no-repeat;
    top: -15%;
    left: 50%;
    position: absolute;
  }
}
.scribble-3 {
  background-image: url("../../assets/images/personalize/how-it-works-scrrible-3.svg");
  background-position: left 2.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
}
</style>
