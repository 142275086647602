import Api from './Api'
import axios from 'axios'
import util from './utils'

export default {
    register(credentials) {
        return Api().post('createAccount', credentials)
    },
    sendInfo(credentials) {
        return new Promise((resolve, reject) => {
            axios.post(util.getLeadAPIUrl(), JSON.stringify(credentials), {
                headers: { 'Content-Type': 'application/json'},
              })
              .then(function (response) {
                resolve(response);
              })
              .catch(function (error) {
                console.log(error);
                reject({
                    details: "Service failed",
                    errCode: "ASUL003"
                })
              });
        })
       
         
    },
    checkPartner(partnerId){
        return new Promise((resolve, reject) => {
            axios.get(util.getParterValidationAPIUrl(partnerId),{
                headers: { 'Content-Type': 'application/json'},
              })
              .then(function (response) {
                resolve(response);
              })
              .catch(function (error) {
                console.log(error);
                reject({
                    details: "Service failed",
                    errCode: "ASUL003"
                })
              });
        })
    }
}