<template>
  <ul class="offset pr-lg-5">
    <template v-for="(item, i) in lists">
      <li :key="i" class="offset-list pb-2" v-if="item.show">
        <span class="stb-sBold font-lg-16 font-14">{{ item.heading }}</span> -
        <span class="font-lg-16 font-14"
          >Once you’ve gathered enough information, apply to ASU. Even if you
          might not be admissible, you can earn your admission through
          <BaseGtmLink
            tag="a"
            class="text-underline text-dark-green"
            href="javascript:void(0)"
            v-b-modal.pathway-modal-desktop
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'open',
              type: 'click',
              region: 'main content',
              section: 'how to apply',
              text: 'pathway to admission',
            }"
            >Pathway to Admission.<span style="margin-right:5px"></span>
          </BaseGtmLink>

          <BaseGtmLink
            tag="a"
            class="text-underline text-dark-green"
            href="javascript:void(0)"
            @click="$store.state.applyNow = 'modal'"
            v-b-modal.modal-1
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'open',
              type: 'click',
              region: 'main content',
              section: 'how to apply',
              text: 'Apply today!',
            }"
            >Apply today!</BaseGtmLink
          ></span
        >
      </li>
      <li :key="i" class="offset-list mb-2" v-else>
        <span class="stb-sBold font-lg-16 font-14">{{ item.heading }}</span> -
        <span class="font-lg-16 font-14" v-html="item.content"></span>
      </li>
    </template>
  </ul>
</template>
<script>
// import $ from "jquery";
export default {
  props: {
    lists: Array,
  },
  mounted() {
    // $("#use-this-form").click(function() {
    //   alert("Handler for .click() called.");
    // });
  },
  methods: {
    showMobileModal() {
      this.$store.state.showPathwayModal = true;
      document.getElementById("app").style.overflowY = "hidden";
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
.offset {
  left: 5%;
  @media only screen and (max-width: 991px) {
    left: 0%;
  }
  position: relative;
  background-color: white;
  z-index: 10;
  padding-right: 20px;
  padding-left: 0;
  counter-reset: my-sec-counter;
  .offset-list {
    position: relative;
    padding-left: 20px;
    list-style: none;
    counter-increment: my-sec-counter;
    &:after,
    &:not(:last-of-type):before {
      content: "";
      position: absolute;
      top: calc((1em - 8px) / 2);
    }
    &:not(:last-of-type):before {
      height: 115%;
      width: 1px;
      left: -3px;
      background-color: #185728;
    }
    &:after {
      content: counter(my-sec-counter);
      display: inline-block;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      border: 1px solid #185728;
      border-radius: 50%;
      background-color: #fff;
      flex-shrink: 0;
      position: absolute;
      left: -12px;
      font: bold 12px sans-serif;
      text-align: center;
      color: #fff;
      line-height: 18px;
      width: 18px;
      height: 18px;
      background: #185728;
      -moz-border-radius: 999px;
      border-radius: 999px;
    }
  }
}
</style>
