var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"offset pr-lg-5"},[_vm._l((_vm.lists),function(item,i){return [(item.show)?_c('li',{key:i,staticClass:"offset-list pb-2"},[_c('span',{staticClass:"stb-sBold font-lg-16 font-14"},[_vm._v(_vm._s(item.heading))]),_vm._v(" - "),_c('span',{staticClass:"font-lg-16 font-14"},[_vm._v("Once you’ve gathered enough information, apply to ASU. Even if you might not be admissible, you can earn your admission through "),_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.pathway-modal-desktop",modifiers:{"pathway-modal-desktop":true}}],staticClass:"text-underline text-dark-green",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
            event: 'modal',
            name: 'onclick',
            action: 'open',
            type: 'click',
            region: 'main content',
            section: 'how to apply',
            text: 'pathway to admission',
          }}},[_vm._v("Pathway to Admission."),_c('span',{staticStyle:{"margin-right":"5px"}})]),_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"text-underline text-dark-green",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
            event: 'modal',
            name: 'onclick',
            action: 'open',
            type: 'click',
            region: 'main content',
            section: 'how to apply',
            text: 'Apply today!',
          }},on:{"click":function($event){_vm.$store.state.applyNow = 'modal'}}},[_vm._v("Apply today!")])],1)]):_c('li',{key:i,staticClass:"offset-list mb-2"},[_c('span',{staticClass:"stb-sBold font-lg-16 font-14"},[_vm._v(_vm._s(item.heading))]),_vm._v(" - "),_c('span',{staticClass:"font-lg-16 font-14",domProps:{"innerHTML":_vm._s(item.content)}})])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }