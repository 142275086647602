<template>
  <p class="font-12 stb-light lh-1 mb-32 mb-lg-16">
    By submitting my information, I consent to ASU contacting me about
    educational services using automated calls, prerecorded voice messages,
    SMS/text messages or email at the information provided above. Message and
    data rates may apply. To opt out of messages, text STOP to 46278. Consent is
    not required to receive services, and I may call ASU directly at
    <BaseGtmLink
      tag="a"
      href="tel:844-278-7289"
      target="_blank"
      class="text-light-green text-underline"
      :gtm="{
        event: 'link',
        name: 'onclick',
        action: 'click',
        type: 'internal link',
        region: 'main content',
        section: 'get started at asu today!',
        text: '844-278-7289',
      }"
      >844-278-7289.</BaseGtmLink
    >
    I consent to ASU’s
    <BaseGtmLink
      tag="a"
      href="https://asuonline.asu.edu/text-terms/"
      target="_blank"
      class="text-light-green text-underline"
      :gtm="{
        event: 'link',
        name: 'onclick',
        action: 'click',
        type: 'internal link',
        region: 'main content',
        section: 'get started at asu today!',
        text: 'mobile terms and conditions',
      }"
    >
      mobile terms and conditions,</BaseGtmLink
    >
    <BaseGtmLink
      tag="a"
      href="https://www.asu.edu/privacy/"
      target="_blank"
      class="text-light-green text-underline"
      :gtm="{
        event: 'link',
        name: 'onclick',
        action: 'click',
        type: 'internal link',
        region: 'main content',
        section: 'get started at asu today!',
        text: 'privacy statement',
      }"
    >
      Privacy Statement,</BaseGtmLink
    >
    including the European Supplement, and ASU Online’s
    <BaseGtmLink
      tag="a"
      href="https://asuonline.asu.edu/web-analytics-privacy-2/"
      target="_blank"
      class="text-light-green text-underline"
      :gtm="{
        event: 'link',
        name: 'onclick',
        action: 'click',
        type: 'internal link',
        region: 'main content',
        section: 'get started at asu today!',
        text: 'web analytics privacy policy.',
      }"
      >web analytics privacy policy.</BaseGtmLink
    >
  </p>
</template>
<script>
export default {
  name: "footer",
};
</script>
<style lang="scss" scoped>
.lh-1 {
  line-height: 1.3;
}
</style>
