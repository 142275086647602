<template>
  <BaseGtmLink
   v-if="isVisible"
    tag="button"
    @click="scrollTop()"
    href="javascript:void(0)"
    class="btn scroll-to-top-btn btn-dark-green"
    round
    :gtm="{
      event: 'link',
      name: 'onclick',
      action: 'click',
      type: 'internal link',
      region: 'main content',
      section: 'sticky anchor',
      text: 'up arrow'
    }"
  >
    <img
      src="../assets/images/scroll-to-top.svg"
      alt="scroll-down"
      style="transform: rotateZ(-180deg)"
    />
  </BaseGtmLink>
</template>

<script>
export default {
  data(){
      return{
        isVisible: false
      }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      if(window.pageYOffset > 50){
        this.isVisible = true
      }else{
        this.isVisible = false
      }
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss">
.scroll-to-top-btn {
  position: fixed;
  bottom: 5%;
  height: 60px;
  width: 60px;
  right: 10%;
  z-index: 20;
  background: #1f3933;
  border-radius: 50% !important;
  box-shadow: inset -1px 3px 8px 5px #1f3933, 2px 5px 16px 0px #1f3933, 2px 5px 25px 5px rgba(132,132,132,0);
}
</style>
