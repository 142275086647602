import { render, staticRenderFns } from "./pathway-to-admission.vue?vue&type=template&id=08335757&scoped=true"
import script from "./pathway-to-admission.vue?vue&type=script&lang=js"
export * from "./pathway-to-admission.vue?vue&type=script&lang=js"
import style0 from "./pathway-to-admission.vue?vue&type=style&index=0&id=08335757&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08335757",
  null
  
)

export default component.exports