var render = function render(){var _vm=this,_c=_vm._self._c;return _c('scrollactive',{staticClass:"my-nav pt-lg-4 bg-white",attrs:{"id":"stacked-top","offset":60,"scrollOffset":10,"alwaysTrack":true,"duration":800,"bezier-easing-value":".5,0,.35,1"},on:{"itemchanged":_vm.onItemChanged}},[_c('b-navbar',{staticClass:"pb-lg-0 secondarynav d-none d-lg-block",attrs:{"toggleable":"lg","type":"light","id":"scroll-reveal","aria-label":"navbar"}},[_c('b-navbar-brand',{staticClass:"d-lg-none"},[_c('b-navbar-toggle',{attrs:{"aria-label":_vm.currentItemToDisplay,"target":"nav-sticky"}},[(!_vm.color)?_c('p',{staticClass:"mb-0 text-dark stb-sBold",attrs:{"aria-label":_vm.currentItemToDisplay}},[_vm._v(" "+_vm._s(_vm.currentItemToDisplay)+" "),_c('img',{attrs:{"src":require("../assets/images/Icon-chevron-down-solid.svg"),"alt":"Icon-chevron-down-solid"}})]):_vm._e()]),(_vm.color)?_c('img',{staticClass:"logo",attrs:{"src":require('../assets/images/logo-white.svg'),"alt":"logo","srcset":""}}):_vm._e()],1),_c('b-navbar-toggle',{attrs:{"target":"nav-top"}},[_c('div',{attrs:{"id":"hamburger-2"},on:{"click":function($event){return _vm.closeHamburger()}}},[_c('span',{staticClass:"hamburger-2__line"}),_c('span',{staticClass:"hamburger-2__line"}),_c('span',{staticClass:"hamburger-2__line"})])]),_c('b-collapse',{staticClass:"justify-content-center",attrs:{"id":"nav-sticky","is-nav":""},on:{"show":function($event){_vm.triggerGTM('open'),_vm.stickyNavOpen = true},"hide":function($event){_vm.triggerGTM('close'),_vm.stickyNavOpen = false}}},[_c('b-navbar-nav',{attrs:{"role":"presentation"}},[_vm._l((_vm.stickyValues),function(item,i){return [_c('BaseGtmLink',{key:i,staticClass:"sticky-link font-16 font-lg-18 text-brand-black scrollactive-item pb-lg-2 px-1 mr-lg-25 btn-link",attrs:{"tag":"a","href":`#${item.id}`,"gtm":{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'secondary navbar',
              text: item.name.toLocaleLowerCase()
            }}},[_vm._v(_vm._s(item.name))])]})],2)],1),(_vm.mobile)?_c('b-collapse',{staticClass:"justify-content-center text-center d-lg-none",attrs:{"id":"nav-top","is-nav":""},on:{"show":function($event){_vm.addColor(), _vm.showMenu()},"hide":function($event){_vm.addColor(), _vm.closeMenu()}}},[_c('b-navbar-nav',[_vm._l((_vm.navigation),function(nav,i){return [_c('BaseGtmLink',{key:i,staticClass:"text-center nav-link pr-lg-15 pl-lg-15",attrs:{"tag":"a","href":nav.url,"active":nav.label === _vm.$router.history.current.name,"gtm":{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'secondary navbar',
              text: nav.name.toLocaleLowerCase()
            }}},[_vm._v(" "+_vm._s(nav.name)+" ")])]})],2),_c('hr',{staticClass:"light-border d-lg-none",staticStyle:{"width":"200px"}}),_c('b-navbar-nav',{staticClass:"ml-auto d-inline-flex d-lg-block cta-buttons"},[_c('BaseGtmLink',{staticClass:"btn font-14 get-info-button stb-sBold mb-2 mb-lg-0",attrs:{"tag":"button","round":"","gtm":{
            event: 'link',
            name: 'onclick',
            action: 'click',
            type: 'internal link',
            region: 'navbar',
            section: 'main navbar',
            text: 'Get info'.toLocaleLowerCase()
          }},on:{"click":function($event){return _vm.goToRFISection()}}},[_vm._v("Get info")]),_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"btn font-14 apply-now-button stb-sBold bg-brand-gold text-black-1",attrs:{"tag":"button","round":"","gtm":{
            event: 'modal',
            name: 'onclick',
            action: 'open',
            type: 'click',
            region: 'navbar',
            section: 'sticky navbar',
            text: 'apply now'
          }},on:{"click":function($event){_vm.$store.state.applyNow = 'modal'}}},[_vm._v("Apply now")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }