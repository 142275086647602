<template>
  <div id="404" class="roboto-section">
    <navBar class="border-bottom" />
    <div
      class="container justify-content-center flex-column d-flex align-items-center"
      style="height: 80vh"
    >
      <h1>Page not found</h1>
      <a href="/" class="btn btn-dark-green text-white">Visit Home page</a>
    </div>
    <mainFooter />
  </div>
</template>

<script>
import navBar from "../components/just-logo.vue";
import mainFooter from "../components/mainFooter";
export default {
  name: "App",
  components: {
    navBar,
    mainFooter,
  },
};
</script>

