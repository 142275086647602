<template>
  <div class="card pl-32 pr-32 pt-32 pb-32 mb-0">
    <img
      :src="require(`@/assets/images/personalize/${info.image}`)"
      alt="certificate"
      height="36px"
      width="32px"
      class="mb-16 mr-auto"
    />
    <div class="bg-white p-0 border-0 card-header">
      <h3 class="font-24 pb-8 font-weight-bold">{{ info.heading }}</h3>
    </div>
    <div class="card-body p-0 pb-4 bg-white p-0 border-0">
      <p class="m-0 p-0" v-html="info.text"></p>
    </div>
    <div class="card-footer bg-white p-0 border-0 mt-32">
      <a
        :href="info.cta.href"
        target="_blank"
        @click="
          dataLayerTrigger(
            info.cta.label.toLowerCase().replace(/<[^>]*>/g, ''),
            info.heading.toLowerCase().replace(/<[^>]*>/g, ''),
            info.cta.type
          )
        "
        class="btn btn-gold font-weight-bold border-radius"
        v-html="info.cta.label"
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDegreeInfo",
  props: {
    info: Object,
    componentText: {
      type: String,
      default: "does arizona state university have the major i want?",
    },
  },
  methods: {
    dataLayerTrigger(_text, _section, _type) {
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: _type,
        region: "main content",
        section: _section,
        text: _text,
        component: this.componentText,
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch {
        console.error("analytics fault");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.border-radius {
  border-radius: 25px;
}
</style>
