<template>
  <section
    :class="sectionClass"
    class="sticky-nav"
    ref="nav"
    :style="sectionStyle"
  >
    <div class="container-lg px-0 px-lg-gutter">
      <div class="row no-gutters">
        <div class="col-12">
          <nav
            aria-label="secondary navigation"
            class="navbar navbar-expand-lg px-0 pb-0"
          >
            <div class="nav-title-wrapper px-4 px-lg-0">
              <p
                class="nav-title px-0 py-3 my-0 mr-16 d-none d-lg-block"
                :class="titleClass"
              >
                {{ getNavTitle }}
              </p>
              <button
                class="collapse-icon w-100 d-flex align-items-center justify-content-between d-lg-none px-4 px-lg-0 py-4"
                :class="togglerClass"
                @click="toggleMenu"
                :aria-label="
                  navIsExpanded
                    ? `${getNavTitle} - Collapse Menu`
                    : `${getNavTitle} - Expand Menu`
                "
              >
                <p class="nav-title px-0 py-3 my-0 mr-16" :class="titleClass">
                  {{ getNavTitle }}
                </p>
                <svg
                  :style="iconStyle"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                  />
                </svg>
              </button>
            </div>

            <ul class="navbar-nav navbar-nav-collapsed" ref="menu">
              <li
                v-for="(item, key) in navItems"
                :key="key"
                class="nav-item text-medium px-4 px-lg-0"
              >
                <a
                  :id="getNavItemId(key)"
                  :class="navItemClass"
                  :ref="key"
                  :aria-label="getLinkAriaLabel(item)"
                  :href="getNavItemHref(key)"
                  @click="scrollSmooth"
                  class="nav-link mx-0 py-3"
                  >{{ item }}</a
                >
              </li>
            </ul>
            <div
              v-show="navIsStuck || alwaysShowStickySlot"
              class="side-menu"
              v-if="this.$slots.stuckMenu"
            >
              <slot name="stuckMenu"></slot>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import AnalyticsMixin from "@rds/analytics-gs-mixin";

export default {
  name: "NavbarStickyAtlas",
  //   mixins: [AnalyticsMixin],
  data() {
    return {
      stickyObserver: null,
      itemObserver: null,
      intersectedSections: {},
      lastVisitedSection: "",
      navIsStuck: false,
      navIsExpanded: false,
    };
  },
  props: {
    navItems: {
      type: Object,
      required: true,
    },
    navItemVariant: {
      type: String,
      default: "brand-black",
    },
    navItemWeight: {
      type: String,
      default: "normal",
      validator: (value) => {
        return value.match(/(bold|normal|light|lighter)/);
      },
    },
    textSize: {
      type: String,
      default: "medium",
      validator: (value) => {
        return value.match(/(xs|small|medium|large|xl)/);
      },
    },
    title: {
      type: String,
      default: null,
    },
    titleVariant: {
      type: String,
      default: "brand-black",
    },
    titleWeight: {
      type: String,
      default: "bold",
      validator: (value) => {
        return value.match(/(bold|normal|light|lighter)/);
      },
    },
    backgroundVariant: {
      type: String,
      default: "white",
    },
    borderVariant: {
      type: String,
      default: "light-4",
    },
    iconVariant: {
      type: String,
      default: "dark-3",
    },
    addBoxShadow: {
      type: Boolean,
      default: false,
    },
    alwaysShowStickySlot: {
      type: Boolean,
      default: false,
    },
    underlineVariant: {
      type: String,
      default: "secondary",
    },
    underlineMultiple: {
      type: Boolean,
      default: false,
    },
    activeLinkVariant: {
      type: String,
      default: null,
    },
  },
  computed: {
    sectionClass() {
      let classList = `bg-${this.backgroundVariant} `;
      this.navIsStuck && this.addBoxShadow ? (classList += "shadow ") : null;
      this.navIsStuck ? (classList += "stuck-nav ") : null;
      return classList;
    },
    sectionStyle() {
      return `
          --nav-border-variant: ${
            this.borderVariant ? `var(--${this.borderVariant})` : "transparent"
          };
          --active-highlight-variant: ${
            this.underlineVariant ? `var(--light-green)` : "transparent"
          };
          --black-1: ${
            this.activeLinkVariant
              ? `var(--${this.activeLinkVariant})`
              : `var(--${this.navItemVariant})`
          };
          border-bottom:1px solid var(--nav-border-variant);
          `;
    },
    navItemClass() {
      let classList = `text-${this.navItemVariant} text-${this.textSize} font-weight-${this.navItemWeight} `;

      Object.keys(this.navItems).length <= 5 ? (classList += "mx-lg-1 ") : null;

      return classList;
    },
    titleClass() {
      let classList = `text-${this.titleVariant} text-${this.textSize} font-weight-${this.titleWeight} `;

      this.title ? null : (classList += ` d-lg-none `);

      return classList;
    },
    togglerClass() {
      return this.navIsExpanded ? "expanded" : "collapsed";
    },
    getNavTitle() {
      // if no title prop, we use the last visitied section on mobile, or first nav item if no section is intersecting
      if (this.title) {
        return this.title;
      } else {
        return this.lastVisitedSection
          ? this.$refs[this.lastVisitedSection][0].innerHTML
          : Object.values(this.navItems)[0];
      }
    },
    getLastNavItem() {
      return Object.keys(this.navItems)[Object.keys(this.navItems).length - 1];
    },
    iconStyle() {
      return `fill: var(--${this.iconVariant}, var(--dark-3))`;
    },
  },
  methods: {
    getLinkAriaLabel(item) {
      return `link to ${item}`;
    },
    getNavItemId(key) {
      return `nav-item-${key}`;
    },
    getNavItemHref(key) {
      return `#${key}`;
    },
    scrollSmooth(evt) {
      evt.preventDefault();

      const href = evt.target.getAttribute("href");
      if (href && href != "" && href != "#") {
        if (document.getElementById(href.slice(1)) !== null) {
          const targetElement = document.getElementById(href.slice(1));
          const targetElemTop = targetElement.getBoundingClientRect().top;
          const navBarHeight = this.$el.getBoundingClientRect().height;

          // safe area of 25px
          const displacement = targetElemTop - navBarHeight + 25;
          window.scrollBy({
            top: displacement,
            behavior: "smooth",
          });
          // collapse mobile menu on link click
          this.navIsExpanded ? this.toggleMenu() : null;
        }
      }
    },
    underlineToggler(object) {
      if (Object.keys(object).length > 0) {
        let activeElementCount = 0;
        for (let element of Object.keys(object)) {
          let navElement = this.$refs[element][0];
          // stop if only first item should be highlighted
          if (this.navIsStuck && navElement !== undefined) {
            if (object[element] == true && activeElementCount < 1) {
              navElement.classList.add("nav-link-active");
              this.lastVisitedSection = element;
              // if underlineMultiple is true, we don't increment i and all sections that the window is intersecting are underlined.
              // Otherwise only the first intersecting section is marked as active
              this.underlineMultiple ? null : activeElementCount++;
            } else if (object[element] == false || activeElementCount >= 1) {
              navElement.classList.remove("nav-link-active");
            }
          } else if (navElement !== undefined) {
            navElement.classList.remove("nav-link-active");
          } else {
            break;
          }
        }
        // we style the last visted section if nothing is currently intersecting & the nav is sticky
        if (activeElementCount == 0 && this.navIsStuck) {
          // set lastVisitedSection as active if inside nav area, otherwise we set the last navItem as active
          if (
            this.lastVisitedSection &&
            this.$refs[this.lastVisitedSection][0].classList
          ) {
            this.$refs[this.lastVisitedSection][0].classList.add(
              "nav-link-active"
            );
          } else if (this.$refs[this.getLastNavItem][0].classList) {
            this.$refs[this.getLastNavItem][0].classList.add("nav-link-active");
          }
        }
      }
    },
    stickyIntersectionCallback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.navIsStuck = true;
        } else {
          this.navIsStuck = false;
          this.lastVisitedSection = "";
        }
        this.underlineToggler(this.intersectedSections);
      });
    },
    createStickyObserver(root) {
      let rootElement;

      window.location !== window.parent.location
        ? (rootElement = root)
        : (rootElement = null);

      this.stickyObserver = new IntersectionObserver(
        this.stickyIntersectionCallback,
        {
          root: rootElement,
          rootMargin: "0px 0px -98.5% 0px",
          threshold: 0,
        }
      );
    },
    itemIntersectionCallback(entries) {
      entries.forEach((entry) => {
        let entryId = entry.target.id;

        if (entry.isIntersecting) {
          this.intersectedSections[entryId] = true;
        } else {
          this.intersectedSections[entryId] = false;
        }
        this.underlineToggler(this.intersectedSections);
      });
    },
    createItemObserver(root, header) {
      let headerSpacing = `${(header.scrollHeight + 2) * -1}px`;
      let rootElement;
      window.location !== window.parent.location
        ? (rootElement = root)
        : (rootElement = null);

      this.itemObserver = new IntersectionObserver(
        this.itemIntersectionCallback,
        {
          root: rootElement,
          rootMargin: headerSpacing,
          threshold: 0,
        }
      );
    },
    // toggleMenu(event) {
    //   // all collapse animation logic taken from https://css-tricks.com/using-css-transitions-auto-dimensions/ tech 3
    //   // get the height of the element's inner content, regardless of its actual size
    //   let element = this.$refs.menu;
    //   var sectionHeight = element.scrollHeight;

    //   if (this.navIsExpanded) {
    //     // temporarily disable all css transitions
    //     var elementTransition = element.style.transition;
    //     element.style.transition = "";

    //     // on the next frame (as soon as the previous style change has taken effect),
    //     // explicitly set the element's height to its current pixel height, so we
    //     // aren't transitioning out of 'auto'
    //     requestAnimationFrame(function () {
    //       element.style.height = sectionHeight + "px";
    //       element.style.transition = elementTransition;

    //       // on the next frame (as soon as the previous style change has taken effect),
    //       // have the element transition to height: 0
    //       requestAnimationFrame(function () {
    //         element.style.height = 0 + "px";
    //         element.classList.add("navbar-nav-collapsed");
    //       });
    //     });
    //   } else {
    //     // have the element transition to the height of its inner content
    //     element.style.height = sectionHeight + "px";

    //     // when the next css transition finishes (which should be the one we just triggered)
    //     element.addEventListener("transitionend", function handler() {
    //       // remove this event listener so it only gets triggered once
    //       element.removeEventListener("transitionend", handler);
    //       // remove "height" from the element's inline styles, so it can return to its initial value
    //       element.style.height = null;
    //       element.classList.remove("navbar-nav-collapsed");
    //     });
    //   }

    //   // gtag related code, only pushes to google if toggle is triggered by collapse toggle click
    //   // if (event) {
    //   //   this.trackCollapseEvent(
    //   //     "onclick",
    //   //     `${this.navIsExpanded ? "close" : "open"}`,
    //   //     "click",
    //   //     "main content",
    //   //     "sticky navbar",
    //   //     "menu button mobile",
    //   //     "sticky navbar"
    //   //   );
    //   // }

    //   this.navIsExpanded = !this.navIsExpanded;
    // },
  },
  mounted() {
    if (typeof window !== "undefined" && window !== null) {
      // backup iframe query if the sticky nav is in an iframe
      let observerRoot = document.querySelector("iframe");
      let header = this.$refs.nav;

      // sticky observer code
      this.createStickyObserver(observerRoot);
      this.stickyObserver.observe(header);

      // header/ nav item observer code
      this.createItemObserver(observerRoot, header);
      for (const property in this.navItems) {
        if (document.getElementById(`${property}`)) {
          this.itemObserver.observe(document.getElementById(`${property}`));
        }
      }
    }
  },
  destroyed() {
    this.itemObserver.disconnect();
    this.stickyObserver.disconnect();
  },
};
</script>
<style scoped>
.sticky-nav {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1020 !important;
}
.stuck-nav {
  z-index: 1020;
}
.navbar {
  flex-direction: column;
  margin-bottom: -1px;
}

.navbar-nav {
  flex-direction: column;
  overflow: hidden;
  transition: height 0.2s ease-out;
}

.nav-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-link {
  display: inline-block;
  position: relative;
  outline-offset: -2px;
  padding-top: 10px !important;
}
.nav-link:after {
  width: 0;
}

.nav-link-active {
  color: var(--black-1) !important;
}
.nav-link:hover:after,
.nav-link-active:after {
  width: 100%;
}
.nav-link:after,
.nav-link-active:after {
  transition: width 0.5s cubic-bezier(0.19, 1, 0.19, 1);
  content: "";
  left: 0;
  height: 8px;
  background-color: var(--active-highlight-variant);
  position: absolute;
  bottom: 0 !important;
  z-index: 1;
}
.collapse-icon {
  border: none;
  padding: 0px !important;
  margin: auto 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.collapse-icon svg {
  transition: transform 0.2s;
}
.collapse-icon.collapsed svg {
  transform: rotate(-180deg);
}
.collapse-icon svg {
  width: 1.25rem;
  height: auto;
}
.side-menu {
  flex-grow: 1;
}

@media (max-width: 991px) {
  .side-menu {
    display: none;
  }
  .navbar-nav-collapsed {
    height: 0px;
  }
  .nav-title-wrapper,
  .navbar-nav,
  .nav-item a {
    width: 100%;
  }
  .nav-item a {
    border-top: 1px solid var(--nav-border-variant, var(--light-4, #d0d0d0));
  }

  .nav-link-active:after {
    bottom: -1px;
  }
}
@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
    height: auto !important;
  }
  .navbar {
    flex-direction: row;
  }
}
</style>

<template>
  <section
    :class="sectionClass"
    class="sticky-nav"
    ref="nav"
    :style="sectionStyle"
  >
    <div class="container-lg px-0 px-lg-gutter">
      <div class="row no-gutters">
        <div class="col-12">
          <nav
            aria-label="secondary navigation"
            class="navbar navbar-expand-lg px-0 pb-0"
          >
            <div class="nav-title-wrapper px-4 px-lg-0">
              <p
                class="nav-title px-0 py-2 my-0 mt-0 mb-8 d-none d-lg-block"
                :class="titleClass"
              >
                {{ getNavTitle }}
              </p>

              <button
                class="collapse-icon w-100 d-flex align-items-center justify-content-between d-lg-none px-4 px-lg-0 py-4"
                :class="togglerClass"
                @click="toggleMenu"
                :aria-label="
                  navIsExpanded
                    ? `${getNavTitle} - Collapse Menu`
                    : `${getNavTitle} - Expand Menu`
                "
              >
                <p class="nav-title px-0 py-3 my-0 mr-16" :class="titleClass">
                  {{ getNavTitle }}
                </p>
                <svg
                  :style="iconStyle"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                  />
                </svg>
              </button>
            </div>

            <ul class="navbar-nav navbar-nav-collapsed" ref="menu">
              <li
                v-for="(item, key) in navItems"
                :key="key"
                class="nav-item text-medium px-4 px-lg-0"
              >
                <a
                  :id="getNavItemId(key)"
                  :class="navItemClass"
                  :ref="key"
                  :aria-label="getLinkAriaLabel(item)"
                  :href="getNavItemHref(key)"
                  @click="scrollSmooth($event, item)"
                  class="nav-link mx-0 py-3"
                  >{{ item }}</a
                >
              </li>
            </ul>
            <div
              v-show="navIsStuck || alwaysShowStickySlot"
              class="side-menu"
              v-if="this.$slots.stuckMenu"
            >
              <slot name="stuckMenu"></slot>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import AnalyticsMixin from "@rds/analytics-gs-mixin";

export default {
  name: "NavbarStickyAtlas",
  //   mixins: [AnalyticsMixin],
  data() {
    return {
      stickyObserver: null,
      itemObserver: null,
      intersectedSections: {},
      lastVisitedSection: "",
      navIsStuck: false,
      navIsExpanded: false,
    };
  },
  props: {
    navItems: {
      type: Object,
      required: true,
    },
    navItemVariant: {
      type: String,
      default: "brand-black",
    },
    navItemWeight: {
      type: String,
      default: "normal",
      validator: (value) => {
        return value.match(/(bold|normal|light|lighter)/);
      },
    },
    textSize: {
      type: String,
      default: "medium",
      validator: (value) => {
        return value.match(/(xs|small|medium|large|xl)/);
      },
    },
    title: {
      type: String,
      default: null,
    },
    titleVariant: {
      type: String,
      default: "brand-black",
    },
    titleWeight: {
      type: String,
      default: "bold",
      validator: (value) => {
        return value.match(/(bold|normal|light|lighter)/);
      },
    },
    backgroundVariant: {
      type: String,
      default: "white",
    },
    borderVariant: {
      type: String,
      default: "light-4",
    },
    iconVariant: {
      type: String,
      default: "dark-3",
    },
    addBoxShadow: {
      type: Boolean,
      default: false,
    },
    alwaysShowStickySlot: {
      type: Boolean,
      default: false,
    },
    underlineVariant: {
      type: String,
      default: "secondary",
    },
    underlineMultiple: {
      type: Boolean,
      default: false,
    },
    activeLinkVariant: {
      type: String,
      default: null,
    },
  },
  computed: {
    sectionClass() {
      let classList = `bg-${this.backgroundVariant} `;
      this.navIsStuck && this.addBoxShadow ? (classList += "shadow ") : null;
      this.navIsStuck ? (classList += "stuck-nav ") : null;
      return classList;
    },

    sectionStyle() {
      return `
          --nav-border-variant: ${
            this.borderVariant ? `var(--${this.borderVariant})` : "transparent"
          };
          --active-highlight-variant: ${
            this.underlineVariant ? `var(--light-green)` : "transparent"
          };
          --black-1: ${
            this.activeLinkVariant
              ? `var(--${this.activeLinkVariant})`
              : `var(--${this.navItemVariant})`
          };
          border-bottom:1px solid var(--nav-border-variant);
          `;
    },
    navItemClass() {
      let classList = `text-${this.navItemVariant} text-${this.textSize} font-weight-${this.navItemWeight} `;

      Object.keys(this.navItems).length <= 5 ? (classList += "mx-lg-1 ") : null;

      return classList;
    },
    titleClass() {
      let classList = `text-${this.titleVariant} text-${this.textSize} font-weight-${this.titleWeight} `;

      this.title ? null : (classList += ` d-lg-none `);

      return classList;
    },
    togglerClass() {
      return this.navIsExpanded ? "expanded" : "collapsed";
    },
    getNavTitle() {
      // if no title prop, we use the last visitied section on mobile, or first nav item if no section is intersecting
      if (this.title) {
        return this.title;
      } else {
        return this.lastVisitedSection
          ? this.$refs[this.lastVisitedSection][0].innerHTML
          : Object.values(this.navItems)[0];
      }
    },
    getLastNavItem() {
      return Object.keys(this.navItems)[Object.keys(this.navItems).length - 1];
    },
    iconStyle() {
      return `fill: var(--${this.iconVariant}, var(--dark-3))`;
    },
  },
  methods: {
    getLinkAriaLabel(item) {
      return `link to ${item}`;
    },
    getNavItemId(key) {
      return `nav-item-${key}`;
    },
    getNavItemHref(key) {
      return `#${key}`;
    },
    scrollSmooth(evt, item) {
      evt.preventDefault();

      const href = evt.target.getAttribute("href");
      if (href && href != "" && href != "#") {
        if (document.getElementById(href.slice(1)) !== null) {
          const targetElement = document.getElementById(href.slice(1));
          const targetElemTop = targetElement.getBoundingClientRect().top;
          const navBarHeight = this.$el.getBoundingClientRect().height;

          // safe area of 25px
          const displacement = targetElemTop - navBarHeight + 25;
          window.scrollBy({
            top: displacement,
            behavior: "smooth",
          });
          // collapse mobile menu on link click
          this.navIsExpanded ? this.toggleMenu() : null;
        }
      }
      this.triggerGtm(item);
    },
    triggerGtm(evt) {
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "main content",
        section: "sticky navbar",
        text: evt.toLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    underlineToggler(object) {
      if (Object.keys(object).length > 0) {
        let activeElementCount = 0;
        for (let element of Object.keys(object)) {
          let navElement = this.$refs[element][0];
          // stop if only first item should be highlighted
          if (this.navIsStuck && navElement !== undefined) {
            if (object[element] == true && activeElementCount < 1) {
              navElement.classList.add("nav-link-active");
              this.lastVisitedSection = element;
              // if underlineMultiple is true, we don't increment i and all sections that the window is intersecting are underlined.
              // Otherwise only the first intersecting section is marked as active
              this.underlineMultiple ? null : activeElementCount++;
            } else if (object[element] == false || activeElementCount >= 1) {
              navElement.classList.remove("nav-link-active");
            }
          } else if (navElement !== undefined) {
            navElement.classList.remove("nav-link-active");
          } else {
            break;
          }
        }
        // we style the last visted section if nothing is currently intersecting & the nav is sticky
        if (activeElementCount == 0 && this.navIsStuck) {
          // set lastVisitedSection as active if inside nav area, otherwise we set the last navItem as active
          if (
            this.lastVisitedSection &&
            this.$refs[this.lastVisitedSection][0].classList
          ) {
            this.$refs[this.lastVisitedSection][0].classList.add(
              "nav-link-active"
            );
          } else if (this.$refs[this.getLastNavItem][0].classList) {
            this.$refs[this.getLastNavItem][0].classList.add("nav-link-active");
          }
        }
      }
    },
    stickyIntersectionCallback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.navIsStuck = true;
        } else {
          this.navIsStuck = false;
          this.lastVisitedSection = "";
        }
        this.underlineToggler(this.intersectedSections);
      });
    },
    createStickyObserver(root) {
      let rootElement;

      window.location !== window.parent.location
        ? (rootElement = root)
        : (rootElement = null);

      this.stickyObserver = new IntersectionObserver(
        this.stickyIntersectionCallback,
        {
          root: rootElement,
          rootMargin: "0px 0px -98.5% 0px",
          threshold: 0,
        }
      );
    },
    itemIntersectionCallback(entries) {
      entries.forEach((entry) => {
        let entryId = entry.target.id;

        if (entry.isIntersecting) {
          this.intersectedSections[entryId] = true;
        } else {
          this.intersectedSections[entryId] = false;
        }
        this.underlineToggler(this.intersectedSections);
      });
    },
    createItemObserver(root, header) {
      let headerSpacing = `${(header.scrollHeight + 2) * -1}px`;
      let rootElement;
      window.location !== window.parent.location
        ? (rootElement = root)
        : (rootElement = null);

      this.itemObserver = new IntersectionObserver(
        this.itemIntersectionCallback,
        {
          root: rootElement,
          rootMargin: headerSpacing,
          threshold: 0,
        }
      );
    },
    toggleMenu(event) {
      // all collapse animation logic taken from https://css-tricks.com/using-css-transitions-auto-dimensions/ tech 3
      // get the height of the element's inner content, regardless of its actual size
      let element = this.$refs.menu;
      var sectionHeight = element.scrollHeight;
      let dl = {
        event: "collapse",
        name: "onlick",
        action: this.navIsExpanded ? "close" : "open",
        type: "click",
        region: "main content",
        section: "sticky navbar",
        text: "menu button mobile",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);

      if (this.navIsExpanded) {
        // temporarily disable all css transitions
        var elementTransition = element.style.transition;
        element.style.transition = "";

        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(function () {
          element.style.height = sectionHeight + "px";
          element.style.transition = elementTransition;

          // on the next frame (as soon as the previous style change has taken effect),
          // have the element transition to height: 0
          requestAnimationFrame(function () {
            element.style.height = 0 + "px";
            element.classList.add("navbar-nav-collapsed");
          });
        });
      } else {
        // have the element transition to the height of its inner content
        element.style.height = sectionHeight + "px";

        // when the next css transition finishes (which should be the one we just triggered)
        element.addEventListener("transitionend", function handler() {
          // remove this event listener so it only gets triggered once
          element.removeEventListener("transitionend", handler);
          // remove "height" from the element's inline styles, so it can return to its initial value
          element.style.height = null;
          element.classList.remove("navbar-nav-collapsed");
        });
      }

      // gtag related code, only pushes to google if toggle is triggered by collapse toggle click
      // if (event) {
      //   this.trackCollapseEvent(
      //     "onclick",
      //     `${this.navIsExpanded ? "close" : "open"}`,
      //     "click",
      //     "main content",
      //     "sticky navbar",
      //     "menu button mobile",
      //     "sticky navbar"
      //   );
      // }

      this.navIsExpanded = !this.navIsExpanded;
    },
  },
  mounted() {
    if (typeof window !== "undefined" && window !== null) {
      // backup iframe query if the sticky nav is in an iframe
      let observerRoot = document.querySelector("iframe");
      let header = this.$refs.nav;

      // sticky observer code
      this.createStickyObserver(observerRoot);
      this.stickyObserver.observe(header);

      // header/ nav item observer code
      this.createItemObserver(observerRoot, header);
      for (const property in this.navItems) {
        if (document.getElementById(`${property}`)) {
          this.itemObserver.observe(document.getElementById(`${property}`));
        }
      }
    }
  },
  destroyed() {
    this.itemObserver.disconnect();
    this.stickyObserver.disconnect();
  },
};
</script>
<style scoped>
.sticky-nav {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1020 !important;
  box-shadow: 2px 3px #dadada;
}
.stuck-nav {
  z-index: 1020;
}
.navbar {
  flex-direction: column;
  margin-bottom: -1px;
}

.navbar-nav {
  flex-direction: column;
  overflow: hidden;
  transition: height 0.2s ease-out;
}

.nav-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-link {
  display: inline-block;
  position: relative;
  outline-offset: -2px;
  padding-top: 10px !important;
}
.nav-link:after {
  width: 0;
}

.nav-link-active {
  color: var(--black-1) !important;
}
.nav-link:hover:after,
.nav-link-active:after {
  width: 100%;
}
.nav-link:after,
.nav-link-active:after {
  transition: width 0.5s cubic-bezier(0.19, 1, 0.19, 1);
  content: "";
  left: 0;
  height: 8px;
  background-color: var(--active-highlight-variant);
  position: absolute;
  bottom: 0 !important;
  z-index: 1;
}
.collapse-icon {
  border: none;
  padding: 0px !important;
  margin: auto 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.collapse-icon svg {
  transition: transform 0.2s;
}
.collapse-icon.collapsed svg {
  transform: rotate(-180deg);
}
.collapse-icon svg {
  width: 1.25rem;
  height: auto;
}
.side-menu {
  flex-grow: 1;
}

@media (max-width: 991px) {
  .side-menu {
    display: none;
  }
  .navbar-nav-collapsed {
    height: 0px;
  }
  .nav-title-wrapper,
  .navbar-nav,
  .nav-item a {
    width: 100%;
  }
  .nav-item a {
    border-top: 1px solid var(--nav-border-variant, var(--light-4, #d0d0d0));
  }

  .nav-link-active:after {
    bottom: -1px;
  }
}
@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
    height: auto !important;
  }
  .navbar {
    flex-direction: row;
  }
}
</style>
