<template>
  <div no-body :class="cardClass">
    <div class="row no-gutters cih-lazy cih-logo p-4 p-lg-0 move-card">
      <div class="col-12 col-md-5 position-relative cih-bg-image">
        <div class="logo-container"></div>
      </div>
      <div class="col-12 col-md-7 bg-white">
        <div class="cih-body mb-32 mb-lg-0 p-4 p-lg-5">
          <!-- label -->
          <p
            v-if="displayLabel"
            class="mb-16 p-2 d-inline-block"
            :class="labelClass"
            v-html="labelText"
          ></p>

          <!-- title -->
          <component
            :is="titleLevel"
            class="mb-space-sm mb-lg-space-sm font-weight-bold"
            :class="titleClass"
          >
            {{ pageData.upcomingEvents.title }}
          </component>

          <!-- slot -->
          <div>
            <p>
              {{ pageData.upcomingEvents.text }}
            </p>
          </div>

          <!-- cta -->
          <div class="row">
            <div class="col-12" aria-label="Next page">
              <a
                :target="ctaLinkTarget"
                class="mb-0 text-underline text-brand-maroon"
                :class="ctaClass"
                href="https://career.eoss.asu.edu/channels/spring-2024-career-internship-fairs/"
                aria-label="browse events"
                @click="handleClick"
                >Browse events</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import lozad from "lozad";
import { carrerServices } from "../assets/data/careers.json";

import { BCard, BCardImgLazy, BCardBody } from "bootstrap-vue";

export default {
  name: "CardInfoHorizontal",
  data() {
    return {
      pageData: carrerServices,
    };
  },
  components: {
    "b-card": BCard,
    "b-card-body": BCardBody,
    "b-card-img-lazy": BCardImgLazy,
  },
  props: {
    borderVariant: {
      type: String,
      default: "white",
    },
    bgImageSource: {
      type: String,
    },
    displayLogo: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: "../assets/images/careers/upcomingEvents.png",
    },
    logoAlt: {
      type: String,
      default: "cih logo alt",
    },
    displayHighlight: {
      type: Boolean,
      default: true,
    },
    highlightColor: {
      type: String,
      default: "#ffc627",
    },
    highlightWidth: {
      type: Number,
      default: 5,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
    labelBg: {
      type: String,
      default: "secondary",
    },
    labelText: {
      type: String,
      default: "lorem ipsum",
    },
    labelTextSize: {
      type: String,
      default: "xs",
    },
    labelTextVariant: {
      type: String,
      default: "dark",
    },
    title: {
      type: String,
    },
    titleLevel: {
      type: String,
      default: "h3",
      validator: (value) => {
        return value.match(/(h1|h2|h3|h4|h5|h6)/);
      },
    },
    titleSize: {
      type: String,
      default: "medium",
    },
    titleVariant: {
      type: String,
      default: "dark",
    },
    displayCta: {
      type: Boolean,
      default: true,
    },
    ctaAsButton: {
      type: Boolean,
      default: false,
    },
    ctaButtonVariant: {
      type: String,
      default: "primary",
    },
    ctaText: {
      type: String,
      default: "cta text",
    },
    ctaTextSize: {
      type: String,
      default: "large",
    },
    ctaTextVariant: {
      type: String,
      default: "primary",
    },
    ctaTextWeight: {
      type: String,
      default: "normal",
    },
    ctaLink: {
      type: String,
      default: "https://rds.edpl.us",
    },
    ctaLinkTarget: {
      type: String,
      default: "_blank",
      validator: (value) => {
        return value.match(/(_blank|_parent|_self|_top)/);
      },
    },
  },
  computed: {
    cardClass() {
      return `border-${this.borderVariant}`;
    },
    cardBodyStyle() {
      if (this.displayHighlight) {
        return {
          "border-left": `${this.highlightWidth}px solid #006340 !important`,
        };
      }

      return null;
    },
    labelClass() {
      return `bg-${this.labelBg} text-${this.labelTextSize} text-${this.labelTextVariant}`;
    },
    titleClass() {
      return `${this.titleLevel}-${this.titleSize} text-${this.titleVariant}`;
    },
    ctaClass() {
      let classNames = "";

      if (this.ctaAsButton) {
        classNames += `btn btn-${this.ctaButtonVariant} `;
      } else {
        classNames += "rds-link ";
      }

      classNames += `text-${this.ctaTextSize} text-${this.ctaTextVariant} font-weight-${this.ctaTextWeight} `;

      return classNames;
    },
  },
  methods: {
    handleClick(event) {
      this.$emit("ctaClick", event);
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "main content",
        section: "upcoming events",
        text: "browse events",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
  },
  // mounted() {
  //   const observer = lozad(".cih-lazy");
  //   observer.observe();
  // }
};
</script>

<style lang="scss" scoped>
.cih-bg-image {
  background-image: url("../assets/images/careers/upcomingEvents.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 360px;
}

div.logo-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60%;

  opacity: 1;
}
img.cih-logo {
  max-width: 100%;
  bottom: 24px;
  padding-left: 12px;
}
@media (max-width: 991px) {
  .move-card {
    z-index: 99;
    background: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 768px) {
  .cih-body {
    padding: 0;
    border-left: 5px solid #006340;
  }
}
@media (max-width: 768px) {
  .cih-body {
    border-top: 5px solid #006340;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .cih-bg-image {
    height: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .move-card {
    top: -100px;
    position: absolute;
  }
}
</style>
