<template>
  <main id="career_services">
    <header id="section_header" class="container">
      <nav class="d-flex justify-content-start py-4">
        <a href="https://starbucks.asu.edu/" @click="triggerGtm">
          <img
            src="../assets/images/logo.svg"
            alt="header SCAP and ASU logo"
            width="320px"
          />
        </a>
      </nav>
    </header>

    <!--Section Hero start-->
    <section
      id="persona-new-hero"
      :style="
        'background-image:url(' +
        require('../assets/images/careers/' + pageData.sectionHero.image) +
        ');'
      "
    >
      <div
        class="w-100"
        style="background: transparent linear-gradient(180deg, #19191900 0%, #19191900 3%, #19191940 39%, #19191980 67%, #000000CC 100%) 0% 0% no-repeat padding-box; !important"
      >
        <div class="container">
          <div class="col-lg-9 pl-lg-0 position-relative z-top">
            <h1
              class="font-36 font-lg-56 font-weight-bold padding-fix mb-0 text-white pb-lg-48 pb-24"
            >
              {{ pageData.sectionHero.title }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <!--End-->

    <!--Section Navbar sticky atlas start-->
    <NavbarStickyAtlasVue
      :title="pageData.sectionStickyNav.title"
      :nav-items="pageData.sectionStickyNav.navItems"
      :always-show-sticky-slot="true"
    >
    </NavbarStickyAtlasVue>
    <!--End-->

    <!-- section career -->
    <section id="section_career_support" class="container">
      <div class="pt-lg-96 pb-lg-48 d-flex">
        <img
          class="img-fluid d-lg-block d-none section-career-image"
          :src="
            require(`../assets/images/careers/${pageData.sectionCareerSupport.image}`)
          "
          alt="career image"
        />
        <div class="ml-lg-32">
          <div
            class="bg-dark-green mt-48 mt-lg-0 mb-24 mb-lg-0 pl-12 pr-12 pt-32 pb-32 pt-lg-64 pr-lg-64 pl-lg-64 pb-lg-64 careersectionstyle"
          >
            <div v-html="pageData.sectionCareerSupport.title"></div>
            <p class="text-white py-lg-12">
              {{ pageData.sectionCareerSupport.text }}
            </p>
            <a
              :href="pageData.sectionCareerSupport.cta.ctaLink"
              target="_blank"
              class="text-brand-gold text-underline non-rds-link"
              >{{ pageData.sectionCareerSupport.cta.ctaLabel }}</a
            >
          </div>
        </div>
      </div>
      <div>
        <div class="bg-dark-green advanceyourcareesection mt-24 mt-lg-48"></div>

        <h1 class="mt-24 font-weight-bold">
          {{ pageData.advanceYourcareerSection.title }}
        </h1>
        <p class="mt-24 mb-24">
          {{ pageData.advanceYourcareerSection.description }}
        </p>
        <advanceYourcareerSection></advanceYourcareerSection>
      </div>
    </section>
    <!--End-->

    <!--section starbucksOpportunites start-->
    <section>
      <starbucksOpportunites
        class="mt-48 mb-48 mt-lg-72 mb-lg-96"
      ></starbucksOpportunites>
    </section>
    <!--End-->

    <!--section job market insight-->
    <section class="container">
      <JobmarketInsights></JobmarketInsights>
    </section>
    <!--end -->

    <!--Upcoming Event-->
    <section id="section_upcoming_events">
      <ParallaxUpcomingEvents></ParallaxUpcomingEvents>
    </section>
    <!--End-->

    <mainFooter></mainFooter>
  </main>
</template>
<script>
import advanceYourcareerSection from "@/components/advanceYourCareer.vue";
import starbucksOpportunites from "@/components/starbucksOpportunites.vue";
import JobmarketInsights from "@/components/JobmarketInsights.vue";
import ParallaxUpcomingEvents from "@/components/ParallaxUpcomingEvents.vue";
import mainFooter from "@/components/mainFooter";
import NavbarStickyAtlasVue from "@/components/NavbarStickyAtlas.vue";
import { carrerServices } from "@/assets/data/careers.json";

export default {
  metaInfo: {
    title: "Career Services for Starbucks Partners",
    titleTemplate: "Starbucks Career services",
    htmlAttrs: {
      page: "Career services",
    },
    link: [
      {
        rel: "canonical",
        href: `${window.location.origin}/career-services/`,
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "ASU Career Services offers many opportunities for Starbucks partners. Browse available tools and resources to help you begin or advance your career.",
      },
    ],
  },
  components: {
    mainFooter,
    NavbarStickyAtlasVue,
    advanceYourcareerSection,
    starbucksOpportunites,
    JobmarketInsights,
    ParallaxUpcomingEvents,
  },

  data() {
    return {
      pageData: carrerServices,
    };
  },
  methods: {
    triggerGtm() {
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "navbar",
        section: "main navbar",
        text: "co brand scap asu logo",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
  },
};
</script>
<style lang="scss">
.advanceyourcareesection {
  height: 7px;
  width: 66px;
}
#career_services {
  font-family: "stb-regular" !important;
}
.section-career-image {
  object-fit: cover;
}
</style>
