<template>
  <div class="online-learning backround-cover">
    <div class="container">
      <div class="row flex-wrap-reverse">
        <BaseGtmLink
          tag="button"
          class="btn btn-link col-md-6 no-underline"
          round
          v-b-modal.play-video-modal
          :gtm="{
            event: 'modal',
            name: 'onclick',
            action: 'open',
            type: 'click',
            region: 'main content',
            section: 'what are asu online courses like?',
            text: 'play video',
          }"
        >
          <img
            src="../assets/images/play-button.svg"
            alt="play button"
            class="play-button"
          />
          <p class="text-white py-2">play video</p>
        </BaseGtmLink>
        <div class="col-md-6">
          <div class="col-md-10 mx-auto text-center text-lg-left">
            <h2 class="text-white font-fam-Georgia font-lg-36 font-32">
              What are ASU Online courses like?
            </h2>
            <span
              class="col-2 bg-white d-table my-3 mx-auto mx-lg-0"
              style="height: 5px"
            ></span>
            <p class="text-white stb-light font-16">
              Our custom-crafted online degree programs are flexible, but you
              have to stay on track. You’ll also have access to the knowledge
              and expertise of the same award-winning faculty who teach on
              campus.
            </p>
            <p class="text-white stb-light font-16">
              Pursuing your education is a life-changing experience. ASU Online
              is with you every step of the way with academic advisors, success
              coaches and more.
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="play-video-modal" size="lg" hide-footer centered>
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <BaseGtmLink
          tag="a"
          href="javascript:void(0)"
          class="close-apply text-dark font-36 ml-auto border-none"
          @click="close"
          :gtm="{
            event: 'modal',
            name: 'onclick',
            action: 'close',
            type: 'click',
            region: 'main content',
            section: 'what are asu online courses like?',
            text: 'play video',
          }"
        >
          <img src="../assets/images/close-green.svg" alt="close" />
        </BaseGtmLink>
      </template>
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/TR-mjoupd1M"
        allowfullscreen
      ></b-embed>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    showVideoSection() {
      // TODO: use this to trigger GTM
    },
  },
};
</script>

<style lang="scss">
.online-learning {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 476px !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../assets/images/online-degree-bg.jpg");
  @media only screen and (max-width: 991px) {
    height: 640px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block !important;
    padding-top: 80px;
  }
}
.play-button {
  height: 185px;
  width: 185px;
  @media only screen and (max-width: 991px) {
    height: 104px;
    width: 104px;
  }
}
</style>
