var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"algolia-filters",attrs:{"id":"filters-mobile-fixed"}},[_c('p',{staticClass:"fam-roboto font-14 font-black mt-md-10 mb-18 mt-32"},[_vm._v(" Search programs and courses ")]),_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function({ currentRefinement, isSearchStalled, refine }){return _c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchWord),expression:"searchWord"}],staticClass:"form-control mb-3 pr-4",staticStyle:{"height":"33px"},attrs:{"title":"Enter keywords","placeholder":"Enter Keywords"},domProps:{"value":(_vm.searchWord)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchWord=$event.target.value},function($event){return refine($event.currentTarget.value)}],"blur":function($event){return _vm.gtmClickText()}}}),_c('span',{staticClass:"input-group-append"},[_c('img',{attrs:{"src":require("../assets/images/search-icon.svg"),"alt":"search icon"}})])])}}])}),_c('div',{staticClass:"mobile-section-filter--button d-lg-none"},[_c('ais-current-refinements',{scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.mobile-interestArea",modifiers:{"mobile-interestArea":true}}],staticClass:"btn btn-block border-dark-green font-14",attrs:{"tag":"button","gtm":{
            event: 'link',
            name: 'onclick',
            action: 'click',
            type: 'internal link',
            region: 'main content',
            section: 'search programs and courses',
            text: 'filters',
          }},on:{"click":function($event){_vm.showinterestAreaMobile = true}}},[_vm._v(" Filters "),_c('span',{staticClass:"filter-highlight"},[_vm._v(_vm._s(items[0] ? items[0].refinements.length : 0))]),_c('img',{staticStyle:{"transform":"rotateZ(-90deg)","margin-top":"-3px"},attrs:{"src":require("../assets/images/chevron-right.svg"),"alt":"down arrow"}})])]}}])}),_c('div',{staticClass:"text-center my-2"},[_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.start-date",modifiers:{"start-date":true}}],staticClass:"btn btn-link text-light-green text-underline font-14",attrs:{"tag":"button","gtm":{
          event: 'modal',
          name: 'onclick',
          action: 'open',
          type: 'click',
          region: 'main content',
          section: 'explore programs',
          text: 'view all start dates',
        }}},[_vm._v(" View all start dates "),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"table","scale":"1","variant":"dark"}})],1)],1)],1),_c('hr',{staticClass:"mt-lg-32 mb-lg-32 my-2 d-lg-block"}),_c('div',{staticClass:"row d-none d-lg-flex"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"col-md-7"},[_c('ais-current-refinements',{scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [_c('BaseGtmLink',{staticClass:"btn btn-block btn-dark-green font-14",attrs:{"tag":"button","gtm":{
              event: 'select',
              name: 'onclick',
              action: 'open',
              type: 'dropdown',
              region: 'main content',
              section: 'search programs and courses',
              text: 'filters applied',
            },"disabled":!items[0]},on:{"click":function($event){_vm.isExpanded = true}}},[_vm._v(_vm._s(items[0] ? items[0].refinements.length : 0)+" filters applied "),_c('img',{attrs:{"src":require("../assets/images/chevron-down.svg"),"alt":"down arrow"}})])]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],staticClass:"refine-list"},[_c('ais-current-refinements',{scopedSlots:_vm._u([{key:"default",fn:function({ items }){return _c('div',{},[_vm._l((items),function(item){return [_c('ul',{key:item.value,staticClass:"refine-list_container"},_vm._l((item.refinements),function(refinement){return _c('li',{key:refinement.value,staticClass:"refine-list_value d-flex align-items-center"},[_c('span',{staticClass:"refine-list_lable"},[_vm._v(_vm._s(refinement.value))]),_c('span',{staticClass:"refine-list_close"},[_c('b-icon',{attrs:{"icon":"x-circle-fill","scale":"1.5","variant":"light"},on:{"click":function($event){$event.preventDefault();item.refine(refinement), _vm.gtmTrigger(refinement.value)}}})],1)])}),0)]})],2)}}])})],1)],1),_c('div',{staticClass:"col-md-5"},[_c('ais-clear-refinements',{scopedSlots:_vm._u([{key:"default",fn:function({ canRefine, refine }){return [_c('BaseGtmLink',{staticClass:"btn btn-block btn-default border font-14",attrs:{"tag":"button","disabled":!canRefine,"gtm":{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'search programs and courses',
              text: 'clear all'.toLocaleLowerCase(),
            }},on:{"click":function($event){$event.preventDefault();return refine.apply(null, arguments)}}},[_vm._v("Clear all")])]}}])})],1)]),_c('InterestArea',{staticClass:"d-lg-block d-none"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showinterestAreaMobile),expression:"showinterestAreaMobile"}],attrs:{"id":"mobile-interestArea"}},[_c('b-container',[_c('BaseGtmLink',{staticClass:"close-apply text-dark font-36 ml-auto border-none float-right",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
          event: 'modal',
          name: 'onclick',
          action: 'click',
          type: 'internal link',
          region: 'main content',
          section: 'interest area',
          text: 'close',
        }},on:{"click":function($event){_vm.showinterestAreaMobile = false}}},[_c('img',{attrs:{"src":require("../assets/images/Icon-close.svg"),"alt":"close"}})]),_c('InterestArea'),_c('div',{staticClass:"footevars position-fixed w-100 bg-white px-2 py-3",staticStyle:{"bottom":"0","left":"0","right":"0"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('BaseGtmLink',{staticClass:"btn btn-block btn-dark-green font-12",attrs:{"tag":"button","gtm":{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'interest area',
                text: 'apply',
              }},on:{"click":function($event){_vm.showinterestAreaMobile = false}}},[_vm._v(" Apply ")])],1),_c('div',{staticClass:"col-6"},[_c('ais-clear-refinements',{scopedSlots:_vm._u([{key:"default",fn:function({ canRefine, refine }){return [_c('BaseGtmLink',{staticClass:"btn btn-block btn-default border font-12",attrs:{"tag":"button","disabled":!canRefine,"gtm":{
                    event: 'link',
                    name: 'onclick',
                    action: 'click',
                    type: 'internal link',
                    region: 'main content',
                    section: 'search programs and courses',
                    text: 'clear all'.toLocaleLowerCase(),
                  }},on:{"click":function($event){$event.preventDefault();return refine.apply(null, arguments)}}},[_vm._v("Clear all")])]}}])})],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }