<template>
  <section class="cards-scroll">
    <div class="card-row">
      <div
        class="card card-mobile"
        :class="`bg-${section.bgColor}`"
        v-for="(section, index) in pageData.advanceYourcareerSection.sections"
        :key="index"
      >
        <div class="card__header">
          <h2
            class="col-12 col-lg-11 pt-24 pt-lg-0 px-lg-0 font-lg-36 font-24 font-weight-bold"
            :class="`text-${section.textColor}`"
          >
            {{ section.title }}
          </h2>
          <img
            class="d-none d-lg-block col-lg-1"
            :src="require(`../assets/images/careers/${section.icon}`)"
            alt="career image"
          />
        </div>
        <div class="card__body">
          <div
            class="card__content"
            :class="`bg-${section.bgColor}`"
            v-for="point in section.pointers"
            :key="point.title"
          >
            <div class="pb-0 pr-16 pl-16 pr-lg-64 pl-lg-64 told-article-style">
              <div :class="`text-${point.textColor}`">
                <h3 class="mb-12 font-weight-bold" style="font-size: 18px">
                  {{ point.title }}
                </h3>
              </div>
              <div
                class="pb-24 pb-lg-30"
                :class="`text-${point.textColor}`"
                v-html="point.text"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { carrerServices } from "../assets/data/careers.json";

export default {
  data() {
    return {
      pageData: carrerServices,
      cards: [],
      containerHeight: 0,
      headerHeight: 105,
      mobileHeaderHeight: 0,
      baseWidth: 60,
    };
  },
  computed: {
    cardsCount() {
      return this.cards.length;
    },
  },
  methods: {
    setupCardStyles() {
      this.cards.forEach((card, i) => {
        const incValue = i * this.headerHeight;
        const decValue = (this.cardsCount - i - 1) * this.headerHeight;
        const heightValue =
          this.cardsCount * this.headerHeight - this.headerHeight;

        const totalHeaders = this.cardsCount * this.headerHeight;
        const reduceValue = (this.cardsCount - i) * this.headerHeight;

        let bottomValue =
          window.innerHeight - totalHeaders - reduceValue + this.headerHeight;
        bottomValue =
          bottomValue > 0 ? `-${bottomValue}` : Math.abs(bottomValue);

        card.style.marginTop = `${incValue}px`;
        card.style.marginBottom = `${decValue}px`;
        card.style.top = `${incValue}px`;
        card.style.bottom = `${bottomValue}px`;
        card.style.maxHeight = `calc(100vh - ${heightValue - 3}px)`;

        card.addEventListener("click", () => this.cardsClickHandler(i));
      });
    },
    setupMobileCardStyle() {
      this.cards.forEach((card, i) => {
        const incValue = i * this.mobileHeaderHeight;
        const decValue = (this.cardsCount - i - 1) * this.mobileHeaderHeight;
        const heightValue =
          this.cardsCount * this.mobileHeaderHeight - this.mobileHeaderHeight;

        const totalHeaders = this.cardsCount * this.mobileHeaderHeight;
        const reduceValue = (this.cardsCount - i) * this.mobileHeaderHeight;

        let bottomValue =
          window.innerHeight -
          totalHeaders -
          reduceValue +
          this.mobileHeaderHeight;
        bottomValue =
          bottomValue > 0 ? `-${bottomValue}` : Math.abs(bottomValue);

        card.style.marginTop = `${incValue}px`;
        card.style.marginBottom = `${decValue}px`;
        card.style.top = `${incValue}px`;
        card.style.bottom = `${bottomValue}px`;
        card.style.maxHeight = `calc(100vh - ${heightValue - 3}px)`;

        card.addEventListener("click", () => this.cardsMobileClickHandler(i));
      });
    },
    handleResize() {
      this.cards = document.querySelectorAll(".cards-scroll .card");
      this.containerHeight =
        document.querySelector(".card-row").getBoundingClientRect().top +
        window.pageYOffset;
      if (this.cardsCount) {
        this.setupCardStyles();
      }
      if (window.innerWidth < 992) {
        this.setupMobileCardStyle();
      }
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Oswald", sans-serif;
}

.common {
  background: green;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1,
  h2 {
    color: #fff;
    font-size: 80px;
  }
}

.cards-scroll {
  display: flex;
  flex-flow: column;
  @media only screen and (min-width: 992px) {
    .card.bg-dark-green {
      top: 50px !important;
    }
    .card.bg-light-3 {
      top: 160px !important;
    }
    .card.bg-light-1 {
      top: 230px !important;
    }
    .card {
      overflow: hidden !important;
      height: 100vh;
      position: sticky;
      width: 100%;
      background: #fff;
      top: 0px;
      margin-left: auto;
      margin-right: auto;
      &__header {
        width: 100%;
        min-height: 75px;
        padding: 48px 64px 24px 64px;
        display: flex;
        align-items: center;

        h2 {
          font-size: 1em;
        }
      }
      &__body {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        grid-template-rows: 0fr;
        overflow-y: auto !important;
        height: 100%;
        margin-bottom: 32px;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      &__content {
        text-align: left;
        padding: 0 3e;
        h3 {
          font-size: 1.4em;
          margin-bottom: 0.5em;
          font-weight: 700;
        }
        p {
          font-size: 0.87em;
          line-height: 1.3;
          font-weight: 500;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .card.bg-dark-green {
      max-height: calc(100vh - -144px) !important;
    }
    .card.bg-light-3 {
      max-height: calc(100vh - -70px) !important;
    }
    .card.bg-light-1 {
      max-height: calc(100vh - -270px) !important;
    }

    .card-mobile {
      height: 100%;
      width: auto;
      background: #fff;
      border-radius: 0% !important;

      &__header {
        padding: 24px 16px 24px 0px;
        display: flex;
        width: 100%;
        align-items: center;
        cursor: pointer;
      }
      &__body {
        display: inline;
      }
      &__content {
        text-align: left;

        h3 {
          font-size: 1.4em;
          margin-bottom: 0.5em;
          font-weight: 700;
        }
        p {
          font-size: 0.87em;
          line-height: 1.3;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
