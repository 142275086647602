<template>
  <section id="hero" :class="`hero-page-${page}`">
    <b-container class="position-relative class-student">
      <div class="content col-md-6 pl-lg-0 pl-0">
        <h1
          class="font-32 stb-sBold font-lg-48 mt-4 mt-lg-0"
          v-html="header"
        ></h1>
        <p class="px-0 stb-light font-lg-18 font-16">{{ subheader }}</p>
      </div>
      <div class="hero-img" v-if="image">
        <img :src="require(`../assets/images/${image}`)" alt="" class="image-hero"/>
        <div class="scroll-for-more d-lg-none">
        <BaseGtmLink
            tag="a"
            @click="showMoreStudent()"
            href="javascript:void(0)"
            class="text-center d-none"
            round
            :gtm="{
                  event: 'link',
                  name: 'onclick',
                  action: 'click',
                  type: 'internal link',
                  region: 'main content',
                  section: 'Student success',
                  text: ' scroll for more'.toLocaleLowerCase()
                }"
          >
            <img
              src="../assets/images/Scroll_Down_Icon.svg"
              alt="scroll-down"
            />
          </BaseGtmLink>
          <br>
          <span class="text-white pt-24 font-10 text-center d-none">Scroll for more</span>
      </div>
      </div>
    </b-container>
  </section>
</template>
<script>
export default {
  name: "Hero",
  props: {
    color: String,
    header: String,
    subheader: String,
    image: String,
    page: String
  },
  methods: {
    getImageUrl(image) {
      return require("../assets/images/" + image);
    },
    showMoreStudent(){
      window.scrollTo({
        top: document.getElementById('services').offsetTop,
        left: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#hero, .class-student {
  background-color: #1f3933 !important;
  height: 425px;
  display: flex;
  overflow: hidden;
  position: relative;
  @media only screen and (min-width: 991px) {
    align-items: center;
    height: 540px;
  }
  @media only screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
}
.hero-img {
  .image-hero {
    position: absolute;
    height: 500px;
    bottom: 0;
    @media only screen and (max-width: 991px) {
      height: 280px;
      right: 0;
      left: -20px;
      margin: 0 auto;
    }
  }
}
.scroll-for-more{
  position: absolute;
  bottom: 20px;
  text-align: center;
  left: 0;
  right: 0;
}
</style>
