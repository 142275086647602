var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"contact pt-48 pb-lg-64 mb-96 mb-lg-134 bg-contact more-contact position-relative",attrs:{"id":"js-get-started"}},[_c('div',{staticClass:"row position-relative z-top grid-card"},_vm._l((_vm.content),function(contact,i){return _c('div',{key:i,staticClass:"col-lg-4 mb-48"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center text-center"},[_c('div',{staticClass:"image-icons"},[_c('img',{attrs:{"src":_vm.getimageURL(contact.image),"alt":"degree-hat.svg"}})]),_c('h3',{staticClass:"font-black font-24 px-5 mt-24 mb-32 text-white grid-card__heading",domProps:{"innerHTML":_vm._s(contact.header)}}),(contact.action.label === 'Chat now')?_c('BaseGtmLink',{staticClass:"btn btn-gold rounded-pill font-18 font-weight-bold btn-lg grid-card__button",attrs:{"tag":contact.action.type,"href":contact.action.type === 'a'
              ? contact.action.url
              : 'javascript:void(0)',"gtm":{
            event: 'link',
            name: 'onclick',
            action: 'click',
            type: 'internal link',
            region: 'main content',
            section: 'what if i still have questions?',
            text: contact.action.label.toLocaleLowerCase(),
          }},on:{"click":function($event){return _vm.actionClick(contact.action)}}},[_vm._v(_vm._s(_vm.chatNowText))]):_c('BaseGtmLink',{staticClass:"btn btn-gold rounded-pill font-18 font-weight-bold btn-lg grid-card__button",attrs:{"tag":contact.action.type,"href":contact.action.type === 'a'
              ? contact.action.url
              : 'javascript:void(0)',"gtm":{
            event: 'link',
            name: 'onclick',
            action: 'click',
            type: 'internal link',
            region: 'main content',
            section: 'what if i still have questions?',
            text: contact.action.label.toLocaleLowerCase(),
          }},on:{"click":function($event){return _vm.actionClick(contact.action)}}},[_vm._v(_vm._s(contact.action.label))])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }