var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseSbuxLayout',[_c('div',{staticClass:"bg-fafafa pb-90"},[_c('div',{staticClass:"bg-image"}),_c('section',{staticClass:"get-your-questions bg-white rounded col-lg-10 col-11 py-4 px-lg-5 pt-lg-5 pb-90 mx-auto"},[_c('h1',{staticClass:"mx-auto font-lg-56 col-lg-9 font-36 fam-roboto text-lg-center font-black mb-24"},[_vm._v(" Get your questions answered by an enrollment coach ")]),_c('p',{staticClass:"col-lg-6 mx-auto mb-lg-5 mb-4 text-lg-center font-18 text-dark-green fam-roboto"},[_vm._v(" Are you participating in Pathway to Admission? Do you have questions about the program or how to earn your admission to Arizona State University? Our enrollment coaches have the answers. Just click to chat or call. ")]),_c('div',{staticClass:"bg-dark-green text-white col-lg-10 mx-auto pt-64 pb-64"},[_c('div',{staticClass:"col-lg-10 mx-auto"},[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-lg-6 mb-lg-0 mb-64"},[_c('img',{staticStyle:{"height":"82px","width":"72px"},attrs:{"src":require("../assets/images/p2a-user.svg"),"alt":"","srcset":""}}),_c('h3',{staticClass:"my-lg-5 my-4 font-24 col-10 text-center mx-auto fam-roboto font-black"},[_vm._v(" Speak to an enrollment coach ")]),_c('a',{staticClass:"btn btn-gold font-weight-bold rounded-pill",attrs:{"href":"tel:8446912244"},on:{"click":function($event){return _vm.gtmTrigger(
                    'link',
                    'onclick',
                    'click',
                    'internal link',
                    'main content',
                    'speak to an enrollment coach',
                    'enrollment'
                  )}}},[_vm._v("844-691-2244")])]),_c('div',{staticClass:"col-lg-6"},[_c('img',{staticStyle:{"height":"82px","width":"93px"},attrs:{"src":require("../assets/images/p2a-monitor.svg"),"alt":"","srcset":""}}),_c('h3',{staticClass:"my-lg-5 col-10 text-center mx-auto my-4 font-24 fam-roboto font-black"},[_vm._v(" Questions? Send us a message. ")]),_c('a',{staticClass:"btn btn-gold font-weight-bold rounded-pill",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.ShowChatWindow(),
                    _vm.gtmTrigger(
                      'link',
                      'onclick',
                      'click',
                      'internal link',
                      'main content',
                      'questions? send us a message',
                      'chat now'
                    )}}},[_vm._v(_vm._s(_vm.chatNowText))])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }