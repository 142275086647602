<template>
  <div id="starbucks-lp">
    <navBar>
      <CallOutSection
        class="mt-0"
        :openHouseLink="$store.state.openHouse.link"
      />
    </navBar>
    <div id="main">
      <slot></slot>
    </div>
    <mainFooter></mainFooter>
    <scrollTop />
  </div>
</template>

<script>
import navBar from "@/components/navbar";
import scrollTop from "@/components/scroll-top";
import mainFooter from "@/components/mainFooter";
import CallOutSection from "@/components/CallOutSection";
export default {
  components: {
    navBar,
    mainFooter,
    CallOutSection,
    scrollTop,
  },
  mounted() {
    if (window.location.href.includes("staging")) {
      const { set } = this.$meta().addApp("robots");
      set({
        meta: [{ content: "noindex" }],
      });
    }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
