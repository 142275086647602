var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseOffsetImg',{attrs:{"justify":_vm.justify},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('div',{staticClass:"backround-cover col-lg-5 position-relative z-medium",class:[
        [
          _vm.justify === 'right'
            ? 'content-offset--left'
            : 'content-offset--right',
        ],
        [_vm.section],
      ],style:(`background-image: url(${_vm.image}); background-position: center;`)},[(_vm.section === 'how-will-i-fund')?_c('img',{staticClass:"position-absolute d-lg-none image-fluid",staticStyle:{"top":"-2rem","right":"0","max-width":"10rem"},attrs:{"src":require("@/assets/images/personalize/Repeat Grid 10(1).svg"),"alt":"Repeat grid"}}):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"col-lg-7 negative-top pt-lg-48"},[_c('div',{staticClass:"content-offset pb-lg-48 bg-white z-top bg-white pt-24 pt-lg-48",class:[
          _vm.justify === 'right'
            ? 'pr-lg-3 content-offset--right'
            : 'pl-lg-48 content-offset--left',
        ]},[_c('div',{staticClass:"col-11 mx-auto col-lg-12 pl-lg-0"},[_c('h3',{staticClass:"font-lg-36 px-0 font-24 mb-32 mb-lg-0 pr-1 py-1 mx-lg-0 pt-lg-0 d-lg-table d-inline font-black mb-lg-32 pl-0",domProps:{"innerHTML":_vm._s(_vm.heading)}})]),_c('numbered-list',{staticClass:"col-lg-10 col-11 px-0 col-lg-12 ml-auto mt-32 mt-lg-0 mr-auto mr-lg-0 pr-lg-40 pl-40 px-lg-0 negative-top-numberd pt-0",attrs:{"lists":_vm.lists}})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }