export default {
  jsonldSchema(faq) {
    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: prepareMainEntity(faq),
    };
  },
};
function prepareMainEntity(faqs) {
  return faqs.map((faq) => prepareJsonLd(faq.question, faq.answer));
}
function prepareJsonLd(question, answer) {
  return {
    "@type": "Question",
    name: question,
    acceptedAnswer: {
      "@type": "Answer",
      text: answer.join(),
    },
  };
}
