<template>
  <div class="container">
    <div class="bg-dark-green advanceyourcareesection mt-24 mt-lg-48"></div>
    <h1 class="mt-24">
      {{ pageData.advanceYourcareerSection.title }}
    </h1>
    <p class="mt-24 mb-24">
      {{ pageData.advanceYourcareerSection.description }}
    </p>
    <div class="pin-panel">
      <div class="body">
        <!-- New Body -->
        <!-- <ul class="custom-cards">
          <li class="custom-card">
            <h2>ARCADE HEXAGON</h2>
            <p>
              Inhabiting discretion the her dispatched decisively boisterous
              joy. So form were wish open is able of mile of. Waiting express if
              prevent it we an musical. Especially reasonable travelling she
              son. Resources resembled forfeited no to zealously. Has procured
              daughter how friendly followed repeated who surprise. Great asked
              oh under on voice downs. Law together prospect kindness
            </p>
          </li>
          <li class="custom-card">
            <h2>EXILE NOTHING</h2>
            <p>
              securing six. Learning why get hastened smallest cheerful. So if
              on advanced addition absolute received replying throwing he.
              Delighted consisted newspaper of unfeeling as neglected so. Tell
              size come hard mrs and four fond are. Of in commanded earnestly
              resources it. At quitting in strictly up wandered of relation
              answered felicity. Side need at in what dear ever upon if. Same
              down want joy neat ask pain help she. Alone three stuff use law
              walls fat asked. Near do that he help.
            </p>
          </li>
          <li class="custom-card">
            <h2>LUNAR ARMOR</h2>
            <p>
              Started earnest brother believe an exposed so. Me he believing
              daughters if forfeited at furniture. Age again and stuff downs
              spoke. Late hour new nay able fat each sell. Nor themselves age
              introduced frequently use unsatiable devonshire get. They why quit
              gay cold rose deal park. One same they four did ask busy. Reserved
              opinions fat him nay position. Breakfast as zealously incommode do
              agreeable furniture. One too nay led fanny allow plate
            </p>
          </li>
        </ul> -->
        <div class="custom-cards mb-64">
          <div
            class="custom-card pt-24 pb-24 pt-lg-48 pb-lg-48 pr-16 pl-16 pr-lg-64 pl-lg-64"
            v-for="section in pageData.advanceYourcareerSection.sections"
            :key="section.title"
            :class="`bg-${section.bgColor}`"
          >
            <div class="row pb-lg-28">
              <h2
                class="col-12 col-lg-11 font-lg-36"
                :class="`text-${section.textColor}`"
              >
                {{ section.title }}
              </h2>
              <img
                class="d-none d-lg-block col-lg-1 iconstyles"
                :src="require(`../assets/images/careers/${section.icon}`)"
                alt="career image"
              />
            </div>
            <div class="row">
              <div
                class="col-12 col-lg-6"
                v-for="point in section.pointers"
                :key="point.title"
              >
                <div class="pb-12 fw-bold" :class="`text-${point.textColor}`">
                  {{ point.title }}
                </div>
                <div
                  class="pb-32 advanceyourcareerstyles"
                  :class="`text-${point.textColor}`"
                  v-html="point.text"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { carrerServices } from "../assets/data/careers.json";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      cards: [],
      pageData: carrerServices,
    };
  },
  mounted() {
    // gsap.registerPlugin(ScrollTrigger);

    // const spacer = 120;
    // this.cards = document.querySelectorAll(".individual-card");

    // cards.forEach(function (card, index) {
    //   card.style.zIndex = index;
    // });

    // gsap.set(cards, { position: "absolute" });

    // gsap.to(".individual-card", {
    //   yPercent: -100,
    //   stagger: 0.5,
    //   scrollTrigger: {
    //     trigger: ".card",
    //     pin: true,
    //     markers: true,
    //     scrub: 1,
    //     start: "top 35%",
    //     end: "bottom 10%",
    //     snap: {
    //       snapTo: 0.33,
    //       duration: { min: 0.1, max: 0.4 },
    //       ease: "power1.inOut",
    //     },
    //   },
    // });

    // ScrollTrigger.create({
    //   trigger: ".card",
    //   pin: true,
    //   markers: true,
    //   scrub: 1,
    //   start: "top 35%",
    //   end: "bottom 10%",
    //   snap: {
    //     snapTo: 0.33,
    //     duration: { min: 0.1, max: 0.4 },
    //     ease: "power1.inOut",
    //   },
    // });

    // this.cards.forEach((card, index) => {
    //   ScrollTrigger.create({
    //     trigger: card,
    //     start: `top-=${index * spacer} top+=120px`,
    //     endTrigger: ".pin-panel",
    //     // end: `bottom top+=${200 + this.cards.length * spacer}`,
    //     end: "bottom top+=100%",
    //     pin: true,
    //     pinSpacing: false,
    //     markers: true,
    //     id: "card-pin",
    //     invalidateOnRefresh: true,
    //     ease: "power2.inOut",
    //   });
    // });

    // New Coed

    gsap.registerPlugin(ScrollTrigger);

    const allIndividualCards = document.querySelectorAll(".custom-card");
    allIndividualCards.forEach(function (card, index) {
      card.style.zIndex = index;
    });

    gsap.set(allIndividualCards, { position: "absolute" });

    gsap.to(".custom-card", {
      yPercent: -100,
      stagger: 0.5,
      marker: true,
      scrollTrigger: {
        trigger: ".custom-cards",
        pin: true,
        markers: true,
        scrub: 1,
        start: "top 65%",
        end: "bottom 10%",
        snap: {
          snapTo: 0.33,
          // duration: { min: 0.1, max: 0.4 },
          // ease: "power1.inOut",
        },
      },
    });
  },
};
</script>
<style scoped>
@media (min-width: 992px) {
  .pin-panel {
    padding: 0rem 0rem;
  }
}

.iconstyles {
  height: 50px;
}

/* .cards .card:not(:last-child) {
  margin-bottom: 0rem;
} */

/* My Styles */

section {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.custom-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.custom-card {
  --spacing: 5rem;
  position: relative;
  height: calc(100vh - var(--spacing) * 4);
  /* display: flex; */
  /* flex-direction: column; */
  /* margin-bottom: 50px; */
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  /*   not needed right now - but needed when JS kicks in */
  top: var(--spacing);
  z-index: 3;
  padding: 2rem;
  box-sizing: border-box;
}

.custom-card:nth-child(2) {
  top: calc(var(--spacing) * 2);
  z-index: 2;
}

.custom-card:nth-child(3) {
  top: calc(var(--spacing) * 3);
  z-index: 1;
}

/* My styles end */

figure {
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 992px) {
  .headerheight {
    font-size: 36px;
  }
}
@media only screen and (max-width: 992px) {
  .headerheight {
    max-height: 39px;

    font-size: 24px;
  }
}
</style>
