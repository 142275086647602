<template>
  <section class="algolia-filters" id="filters-mobile-fixed">
    <!-- search -->
    <p class="fam-roboto font-14 font-black mt-md-10 mb-18 mt-32">
      Search programs and courses
    </p>
    <ais-search-box>
      <div
        slot-scope="{ currentRefinement, isSearchStalled, refine }"
        class="position-relative"
      >
        <input
          @input="refine($event.currentTarget.value)"
          @blur="gtmClickText()"
          title="Enter keywords"
          v-model="searchWord"
          placeholder="Enter Keywords"
          class="form-control mb-3 pr-4"
          style="height: 33px"
        />
        <span class="input-group-append">
          <img src="../assets/images/search-icon.svg" alt="search icon" />
        </span>
      </div>
    </ais-search-box>
    <div class="mobile-section-filter--button d-lg-none">
      <ais-current-refinements>
        <template slot-scope="{ items }">
          <BaseGtmLink
            tag="button"
            class="btn btn-block border-dark-green font-14"
            :gtm="{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'search programs and courses',
              text: 'filters',
            }"
            v-b-modal.mobile-interestArea
            @click="showinterestAreaMobile = true"
          >
            Filters
            <span class="filter-highlight">{{
              items[0] ? items[0].refinements.length : 0
            }}</span>
            <img
              src="../assets/images/chevron-right.svg"
              alt="down arrow"
              style="transform: rotateZ(-90deg); margin-top: -3px"
          /></BaseGtmLink>
        </template>
      </ais-current-refinements>
      <div class="text-center my-2">
        <BaseGtmLink
          tag="button"
          class="btn btn-link text-light-green text-underline font-14"
          v-b-modal.start-date
          :gtm="{
            event: 'modal',
            name: 'onclick',
            action: 'open',
            type: 'click',
            region: 'main content',
            section: 'explore programs',
            text: 'view all start dates',
          }"
        >
          View all start dates
          <b-icon icon="table" scale="1" variant="dark" class="ml-2"></b-icon>
        </BaseGtmLink>
      </div>
    </div>
    <hr class="mt-lg-32 mb-lg-32 my-2 d-lg-block" />
    <!-- row -->
    <div class="row d-none d-lg-flex">
      <div class="col-md-7" v-click-outside="hide">
        <ais-current-refinements>
          <template slot-scope="{ items }">
            <BaseGtmLink
              tag="button"
              class="btn btn-block btn-dark-green font-14"
              :gtm="{
                event: 'select',
                name: 'onclick',
                action: 'open',
                type: 'dropdown',
                region: 'main content',
                section: 'search programs and courses',
                text: 'filters applied',
              }"
              :disabled="!items[0]"
              @click="isExpanded = true"
              >{{ items[0] ? items[0].refinements.length : 0 }}
              filters applied
              <img src="../assets/images/chevron-down.svg" alt="down arrow"
            /></BaseGtmLink>
          </template>
        </ais-current-refinements>

        <div class="refine-list" v-show="isExpanded">
          <ais-current-refinements>
            <div slot-scope="{ items }">
              <template v-for="item in items">
                <ul class="refine-list_container" :key="item.value">
                  <li
                    v-for="refinement in item.refinements"
                    :key="refinement.value"
                    class="refine-list_value d-flex align-items-center"
                  >
                    <span class="refine-list_lable">{{
                      refinement.value
                    }}</span>
                    <span class="refine-list_close"
                      ><b-icon
                        icon="x-circle-fill"
                        scale="1.5"
                        variant="light"
                        @click.prevent="
                          item.refine(refinement), gtmTrigger(refinement.value)
                        "
                      ></b-icon
                    ></span>
                  </li>
                </ul>
              </template>
            </div>
          </ais-current-refinements>
        </div>
      </div>
      <div class="col-md-5">
        <ais-clear-refinements>
          <template slot-scope="{ canRefine, refine }">
            <BaseGtmLink
              tag="button"
              class="btn btn-block btn-default border font-14"
              :disabled="!canRefine"
              @click.prevent="refine"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'search programs and courses',
                text: 'clear all'.toLocaleLowerCase(),
              }"
              >Clear all</BaseGtmLink
            >
          </template>
        </ais-clear-refinements>
      </div>
    </div>
    <!-- /row -->
    <!-- interest area -->
    <InterestArea class="d-lg-block d-none" />
    <div id="mobile-interestArea" v-show="showinterestAreaMobile">
      <!-- Emulate built in modal header close button action -->
      <b-container>
        <BaseGtmLink
          tag="a"
          href="javascript:void(0)"
          class="close-apply text-dark font-36 ml-auto border-none float-right"
          @click="showinterestAreaMobile = false"
          :gtm="{
            event: 'modal',
            name: 'onclick',
            action: 'click',
            type: 'internal link',
            region: 'main content',
            section: 'interest area',
            text: 'close',
          }"
        >
          <img src="../assets/images/Icon-close.svg" alt="close" />
        </BaseGtmLink>
        <InterestArea />
        <div
          class="footevars position-fixed w-100 bg-white px-2 py-3"
          style="bottom: 0; left: 0; right: 0"
        >
          <div class="row">
            <div class="col-6">
              <BaseGtmLink
                tag="button"
                class="btn btn-block btn-dark-green font-12"
                @click="showinterestAreaMobile = false"
                :gtm="{
                  event: 'link',
                  name: 'onclick',
                  action: 'click',
                  type: 'internal link',
                  region: 'main content',
                  section: 'interest area',
                  text: 'apply',
                }"
              >
                Apply
              </BaseGtmLink>
            </div>
            <div class="col-6">
              <ais-clear-refinements>
                <template slot-scope="{ canRefine, refine }">
                  <BaseGtmLink
                    tag="button"
                    class="btn btn-block btn-default border font-12"
                    :disabled="!canRefine"
                    @click.prevent="refine"
                    :gtm="{
                      event: 'link',
                      name: 'onclick',
                      action: 'click',
                      type: 'internal link',
                      region: 'main content',
                      section: 'search programs and courses',
                      text: 'clear all'.toLocaleLowerCase(),
                    }"
                    >Clear all</BaseGtmLink
                  >
                </template>
              </ais-clear-refinements>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </section>
</template>

<script>
import InterestArea from "./interest-area";
export default {
  data() {
    return {
      filterNumber: 0,
      isExpanded: false,
      searchWord: "",
      showinterestAreaMobile: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.makeNavMobileFilterSticky);
    this.popupItem = this.$el;
  },
  components: {
    InterestArea,
  },
  methods: {
    gtmTrigger(closeWord) {
      let dl = {
        event: "select",
        name: "onclick",
        action: "onclick",
        type: "click",
        region: "main content",
        section: "filters applied",
        text: closeWord.toLocaleLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    gtmClickText() {
      let dl = {
        event: "search",
        name: "onenter",
        action: "type",
        type: "course search",
        region: "main content",
        section: "search programs and courses",
        text: this.searchWord.toLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    expandedFalse(item) {
      if (item) {
        this.isExpanded = false;
      }
    },
    filterApplied(item) {
      console.log(item);
    },
    hide() {
      this.isExpanded = false;
    },
    onResize() {
      document
        .getElementById("filters-mobile-fixed")
        .classList.remove("sticky");
      document
        .getElementById("algolia-cards-container")
        .classList.remove("padding-adjustment");
    },
    makeNavMobileFilterSticky() {
      let upperLimit = document.getElementById("hero");
      let stackable = document.getElementById("filters-mobile-fixed");
      let stickyTop = document.getElementById("algolia-cards-container");
      let sticky = upperLimit.offsetHeight + 70;
      if (window.outerWidth < 991) {
        if (window.pageYOffset >= sticky) {
          stackable.classList.add("sticky");
          stickyTop.classList.add("padding-adjustment");
        } else {
          stackable.classList.remove("sticky");
          stickyTop.classList.remove("padding-adjustment");
        }
      } else {
        stackable.classList.remove("sticky");
        stickyTop.classList.remove("padding-adjustment");
      }
    },
  },
};
</script>

<style lang="scss">
#mobile-interestArea {
  width: 100%;
  background: white;
  height: 100vh;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media only screen and (max-width: 991px) {
  .interest-area {
    margin-bottom: 60px !important;
  }
}

.input-group-append {
  position: absolute;
  top: 12px;
  right: 5px;
}
.interest-area {
  width: fit-content;
}
.refine-list_container {
  margin-top: 5px;
  position: absolute;
  z-index: 20;
  background: white;
  box-shadow: 2px 5px 30px -10px rgba(0, 0, 0, 0.75);
  width: 150%;
  padding: 5px 10px;
}
.refine-list_value {
  background-color: #29413b;
  color: white;
  margin: 5px auto;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
}

.algolia-filters.sticky {
  padding-right: 25px;
  padding-left: 25px;
}
.filter-highlight {
  border-radius: 50%;
  background-color: #29413b;
  color: white;
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 20px;
}
</style>
