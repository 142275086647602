<template>
  <div class="degree-bg">
    <div class="container">
      <div class="col-lg-5 col-11 px-0 v-center-align">
        <div
          class="degree-content text-white text-center pt-lg-50 pb-lg-50 pr-lg-30 pl-lg-30 pt-22 pb-22 pr-22 pl-22"
        >
          <h2 class="font-fam-Georgia font-lg-36 font-24 pb-lg-30 pb-18 mb-0">
            <span class="underline-special-degree">ASU bachelor’s degrees</span>
          </h2>
          <p
            class="col-11 font-sBold font-lg-16 font-14 mx-auto pb-lg-30 pb-18 mb-0"
          >
            Eligible Starbucks partners can choose from more than 150 bachelor’s
            degrees online
          </p>
          <div class="col-lg-5 col-6 mx-auto">
            <BaseGtmLink
              tag="a"
              href="/starbucks-asu-degree-list"
              class="btn font-lg-14 font-12 bg-brand-gold stb-sBold"
              round
              block
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'asu bachelor\'s degrees',
                text: 'View degrees'.toLocaleLowerCase(),
              }"
              >View degrees</BaseGtmLink
            >
          </div>
        </div>
        <div class="scroll-more pt-lg-52 pt-34 d-flex flex-column">
          <BaseGtmLink
            tag="a"
            @click="showMore()"
            href="javascript:void(0)"
            class="text-center"
            round
            :gtm="{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'asu bachelor\'s degrees',
              text: 'scroll for more'.toLocaleLowerCase(),
            }"
          >
            <img
              src="../assets/images/Scroll_Down_Icon.svg"
              alt="scroll-down"
            />
          </BaseGtmLink>
          <span class="text-white pt-24 text-center">Scroll for more</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.degree-bg {
  overflow: hidden;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/images/degrees-fixed-bg.jpg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  z-index: 9;
}
.degree-content {
  background-color: #1f3933 !important;
}
.v-center-align {
  margin: 0;
  position: absolute;
  top: 50vh;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  border-radius: 2px;
}
.underline-special-degree {
  background-image: url("../assets/images/degree-page-scribble.svg");
  background-position: center 1.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
  @media only screen and (max-width: 991px) {
    line-height: 44px;
  }
}
</style>
<script>
import $ from "jquery";
export default {
  mounted() {
    // if (navigator.userAgent.match(/Trident\/7\./)) {
    //   // if IE
    //   $("body").on("mousewheel", function() {
    //     // remove default behavior
    //     event.preventDefault();
    //     //scroll without smoothing
    //     var wheelDelta = event.wheelDelta;
    //     var currentScrollPosition = window.pageYOffset;
    //     window.scrollTo(0, currentScrollPosition - wheelDelta);
    //   });
    // }
  },
  methods: {
    showMore() {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#how-to-apply").offset().top,
        },
        2000
      );
    },
  },
};
</script>
