<template>
  <div class="container pb-lg-114 pt-lg-114 py-5">
    <div class="container text-center text-lg-left postition-relative">
      <h2 class="font-24 text-white same-bg mb-5 mb-lg-3">
        How does the Starbucks College <br />
        Achievement Plan work?
        <img
          class="scribble-3 d-none d-lg-block"
          src="../assets/images/how-it-works-scrrible-3.svg"
          alt=".how-it-works-scrrible-3.svg"
        />
      </h2>
      <img
        class="carousal-end img-fluid show-on-landscape d-lg-none mobile-image-final-slide"
        src="../assets/images/Howitworks-Mobile-01.png"
        alt="mobile-image-final-slide"
      />
      <SectionCupImage class="img-fluid d-none d-lg-block" />

      <div class="d-lg-none mt-5">
        <template v-for="(slide, i) in slidesMobile">
          <div :key="i" class="carousal-end text-left">
            <div
              class="colour-dot mb-2"
              :style="`background-color:${slide.colour}`"
            ></div>
            <h3
              class="font-24 font-lg-30 text-white font-fam-Georgia"
              v-html="slide.heading"
            ></h3>
            <h3
              v-if="slide.subHeading"
              class="font-14 font-lg-30 font-fam-Georgia text-brand-gold"
              v-html="slide.subHeading"
            ></h3>
            <p class="font-14 text-white col-11 pl-0" v-html="slide.text"></p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import SectionCupImage from "./desktopCupImage";
export default {
  props: {
    Image: String,
    imageClass: String,
    imageMobile: String,
  },
  components: {
    SectionCupImage,
  },
  data() {
    return {
      slidesMobile: [
        {
          colour: "#00A4E0",
          heading: "College Achievement Plan Scholarship",
          text:
            "Through the Starbucks College Achievement Plan, you receive a College Achievement Plan (CAP) Scholarship applied by ASU each semester toward undergraduate tuition.",
        },
        {
          colour: "#FFC627",
          heading: "Starbucks Tuition Benefit",
          subHeading: "(100% tuition coverage)",
          text:
            "After the CAP Scholarship and any need-based federal aid grants are applied (if applicable), the remaining tuition and fees are covered by the Starbucks Tuition Benefit and applied directly to your ASU account before the start of the semester or session.",
        },
        {
          colour: "#AF3656",
          heading: "Books and supplies",
          subHeading: "(Student responsibility)",
          text:
            "The Starbucks College Achievement Plan doesn’t cover books or supplies. These are the student’s responsibility to pay. Yet, most partners find their financial aid can help with these costs.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.colour-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.scribble-3 {
  background-image: url("../assets/images/how-it-works-scrrible-3.svg");
  background-position: left 2.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
}
.image-bg-hiw {
  @media only screen and (max-width: 991px) {
    &:before {
      background-image: url("../assets/images/mobile-line.svg") !important;
      top: -10% !important;
    }
  }
  @media only screen and (max-width: 767px) {
    &:before {
      background-image: url("../assets/images/mobile-line.svg") !important;
      top: -3% !important;
    }
  }
  &:before {
    content: "";
    background-image: url("../assets/images/LINE.svg");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    top: -15%;
    left: 50%;
    position: absolute;
  }
}
</style>
