import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Support from "../views/student_support.vue";
import Search from "../views/degree_search.vue";
import FAQ from "../views/faq.vue";
import PersonaCost from "../views/personalize/cost.vue";
import PersonaTime from "../views/personalize/time.vue";
import PersonaCareer from "../views/personalize/career.vue";
import PersonaOnline from "../views/personalize/online.vue";
import PersonaNetworking from "../views/personalize/Networking.vue";
import PersonaFinancial from "../views/personalize/Financial.vue";
import PersonaWellBeing from "../views/personalize/WellBeing.vue";
import PersonaAcademics from "../views/personalize/Academics.vue";
import careersService from "../views/careers_service.vue";
import advcaned from "../views/advanceCareer2.vue";

import p2aChat from "../views/p2aChat.vue";
import PageNotFound from "../views/404.vue";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/support",
      name: "support",
      component: Support,
    },
    {
      path: "/starbucks-asu-degree-list",
      name: "search",
      component: Search,
    },
    {
      path: "/programs",
      name: "Programs",
      beforeEnter: (to, from, next) => next("/starbucks-asu-degree-list"),
    },
    {
      path: "/p2a-chat",
      name: "p2a",
      component: p2aChat,
    },
    {
      path: "/faq",
      name: "faq",
      component: FAQ,
    },
    {
      path: "/personalize/cost",
      name: "personaCost",
      component: PersonaCost,
    },
    {
      path: "/personalize/online",
      name: "personaOnline",
      component: PersonaOnline,
    },
    {
      path: "/personalize/time",
      name: "personaTime",
      component: PersonaTime,
    },
    {
      path: "/personalize/career",
      name: "personaCareer",
      component: PersonaCareer,
    },
    {
      path: "/personalize/networking",
      name: "personaNetworking",
      component: PersonaNetworking,
    },
    {
      path: "/personalize/wellbeing",
      name: "personaWellBeing",
      component: PersonaWellBeing,
    },
    {
      path: "/personalize/financial",
      name: "personaFinancial",
      component: PersonaFinancial,
    },
    {
      path: "/personalize/academics",
      name: "personaAcademics",
      component: PersonaAcademics,
    },
    {
      path: "/career-services/",
      name: "careersService",
      component: careersService,
    },
    {
      path: "/advcaned",
      name: "advcaned",
      component: advcaned
    },
    { path: "*", component: PageNotFound },
  ],
});
export default router;
