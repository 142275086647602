<template>
  <ul class="offset-persona pl-16">
    <template v-for="(item, i) in lists">
      <li :key="i" class="offset-persona--list mb-2 list-bullets" v-html="item">
      </li>
    </template>
  </ul>
</template>
<script>
export default {
  props: {
    lists: Array
  },
};
</script>

<style lang="scss">
.offset-persona--list{
  list-style: none;
  position: relative;
}
</style>