var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gold padding-fix"},[_c('div',{staticClass:"container px-lg-0 px-2 margin-fix"},[_c('p',{staticClass:"font-14 text-black text-center text-lg-left my-2 fam-roboto"},[_c('span',{staticClass:"font-black"},[_vm._v("Work for Starbucks? ")]),_vm._v("Discover how the program works at our next webinar on "),_c('span',{staticClass:"font-black"},[_c('b-icon',{staticClass:"mx-2 font-black",staticStyle:{"width":"20px"},attrs:{"icon":"table","scale":"1.2","variant":"black"}}),_c('span',{staticClass:"font-black"},[_vm._v(" "+_vm._s(_vm.$store.state.openHouse.day)+" from "+_vm._s(_vm.$store.state.openHouse.time)+". "),_c('BaseGtmLink',{staticClass:"text-underline mx-1 font-black text-black",attrs:{"tag":"a","href":_vm.openHouseLink,"gtm":{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'external link',
              region: 'main content',
              section: 'navbar callout',
              text: 'register now!',
            }}},[_vm._v(" Register now! ")])],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }