var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:`hero-page-${_vm.page}`,attrs:{"id":"hero"}},[_c('b-container',{staticClass:"position-relative class-student"},[_c('div',{staticClass:"content col-md-6 pl-lg-0 pl-0"},[_c('h1',{staticClass:"font-32 stb-sBold font-lg-48 mt-4 mt-lg-0",domProps:{"innerHTML":_vm._s(_vm.header)}}),_c('p',{staticClass:"px-0 stb-light font-lg-18 font-16"},[_vm._v(_vm._s(_vm.subheader))])]),(_vm.image)?_c('div',{staticClass:"hero-img"},[_c('img',{staticClass:"image-hero",attrs:{"src":require(`../assets/images/${_vm.image}`),"alt":""}}),_c('div',{staticClass:"scroll-for-more d-lg-none"},[_c('BaseGtmLink',{staticClass:"text-center d-none",attrs:{"tag":"a","href":"javascript:void(0)","round":"","gtm":{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'Student success',
                text: ' scroll for more'.toLocaleLowerCase()
              }},on:{"click":function($event){return _vm.showMoreStudent()}}},[_c('img',{attrs:{"src":require("../assets/images/Scroll_Down_Icon.svg"),"alt":"scroll-down"}})]),_c('br'),_c('span',{staticClass:"text-white pt-24 font-10 text-center d-none"},[_vm._v("Scroll for more")])],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }