<template>
  <div class="interest-area">
    <p class="fam-roboto font-black font-16 mt-30 mb-24">Interest area</p>
    <ais-refinement-list
      attribute="interestAreas"
      :limit="100"
      :sortBy="['name']"
    >
      <div slot-scope="{ items, refine }" class="filter-checks">
        <div v-for="(item, i) in items" :key="i" class="my-lg-2 my-1">
          <BaseGtmLink
            tag="a"
            href="javascript:void(0)"
            class="interest-area-link p-lg-2 border my-lg-2 mr-4 pr-3"
            :class="{ addActivePill: item.isRefined }"
            :gtm="{
              event: 'select',
              name: 'onclick',
              action: item.isRefined ? 'onclick' : 'click',
              type: 'box',
              region: 'main content',
              section: 'interest area',
              text: item.value.toLocaleLowerCase(),
            }"
            @click.prevent="refine(item.value)"
            style="border-radius: 30px"
          >
            <span
              class="m-0 col pl-2 pr-2"
              v-html="getImageUrl(item.value)"
              style="width: 15px"
            ></span>
            <p class="font-12 font-lg-12 mb-0 mr-4 col- ml-3">
              {{ item.value }}
            </p>
          </BaseGtmLink>
        </div>
      </div>
    </ais-refinement-list>
  </div>
</template>
<script>
const _ = require("lodash");
export default {
  data() {
    return {
      degreeSVG: {
        artDesign: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.17 30.04"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><line class="cls-1" x1="3.13" y1="10.36" x2="7.14" y2="6.37"/><line class="cls-1" x1="5.33" y1="12.56" x2="6.93" y2="10.96"/><line class="cls-1" x1="17.52" y1="24.69" x2="19.12" y2="23.09"/><line class="cls-1" x1="19.81" y1="26.97" x2="23.82" y2="22.98"/><path class="cls-1" d="M4.17,19.59C4.09,19.67.9,27.73.9,27.73a.93.93,0,0,0,1.18,1.16s8.14-3.12,8.22-3.2"/><line class="cls-1" x1="2.14" y1="24.6" x2="5.23" y2="27.68"/><path class="cls-1" d="M28.32,5,24.8,1.45a1.75,1.75,0,0,0-2.47,0L4.14,19.56l6.18,6.15,18-17.92A2,2,0,0,0,28.32,5Z"/><polyline class="cls-1" points="13.96 6.59 8.1 0.75 0.75 8.06 6.62 13.9"/><polyline class="cls-1" points="16.02 23.26 22.07 29.29 29.42 21.98 23.36 15.95"/><line class="cls-1" x1="7.67" y1="23.08" x2="22.24" y2="8.57"/></g></g></svg>`,
        business: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.5 29.5"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><rect class="cls-1" x="0.75" y="7.75" width="29" height="21" rx="2.5"/><line class="cls-1" x1="0.75" y1="14.75" x2="12.75" y2="14.75"/><line class="cls-1" x1="17.75" y1="14.75" x2="29.75" y2="14.75"/><rect class="cls-1" x="12.75" y="12.75" width="5" height="4"/><path class="cls-1" d="M20.75,4.75h0a4,4,0,0,0-4-4h-3a4,4,0,0,0-4,4h0"/></g></g></svg>`,
        communication: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.56 26.24"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M19.86,6.72H4.75a4,4,0,0,0-4,3.85V16.9a4,4,0,0,0,4,3.87H5.86v3.76a.85.85,0,0,0,1.44.71L12,20.77h7.86a3.88,3.88,0,0,0,3.88-3.87V10.57A3.86,3.86,0,0,0,19.86,6.72Z"/><path class="cls-1" d="M26.3,13.61a3.66,3.66,0,0,0,3.51-3.83V4.6a4,4,0,0,0-4-3.85H13.39A3.68,3.68,0,0,0,9.74,4.24"/><line class="cls-1" x1="5.86" y1="15.72" x2="18.29" y2="15.72"/><line class="cls-1" x1="5.86" y1="11.72" x2="18.29" y2="11.72"/></g></g></svg>`,
        criminalJusticeLaw: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.42 29.38"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M22.61,5.6a6.65,6.65,0,0,1-1.71.2c-2.18,0-5.34-.82-8-4.71a.85.85,0,0,0-1.36,0C8.32,5.78,3.78,6.28,1.81,5.6a.83.83,0,0,0-1.06.81v7.91c0,11,11.13,14.25,11.24,14.28a.82.82,0,0,0,.44,0c.11,0,11.24-3.24,11.24-14.28V6.41A.83.83,0,0,0,22.61,5.6Z"/></g></g></svg>`,
        education: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.5 24.91"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><line class="cls-1" x1="4.25" y1="9.75" x2="11.25" y2="9.75"/><line class="cls-1" x1="4.25" y1="13.75" x2="11.25" y2="13.75"/><line class="cls-1" x1="4.25" y1="17.75" x2="11.25" y2="17.75"/><path class="cls-1" d="M14.75,24.16V5.24c0-1.18-3-2.13-7-2.13s-7,.95-7,2.13V24.16a39.78,39.78,0,0,1,7-.58A39.78,39.78,0,0,1,14.75,24.16Z"/><path class="cls-1" d="M18.75,3.37c-2.33.35-4,1-4,1.87V24.16a56.81,56.81,0,0,1,7.5-.58,46,46,0,0,1,7.5.58V5.24c0-.81-1.72-1.51-4-1.87"/><path class="cls-1" d="M24.85,15.38l-2.27-2a.5.5,0,0,0-.66,0l-2.34,2a.5.5,0,0,1-.83-.38V.75h6.93V15A.5.5,0,0,1,24.85,15.38Z"/></g></g></svg>`,
        engineering: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.95 30.05"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M13.34,20.11a3.41,3.41,0,1,1-3.4-3.4A3.41,3.41,0,0,1,13.34,20.11Z"/><path class="cls-1" d="M16.55,21.71,19,21.38a9,9,0,0,0,0-2.54l-2.49-.32a6.7,6.7,0,0,0-.81-1.95l1.53-2a9.25,9.25,0,0,0-1.79-1.8l-2,1.53a7,7,0,0,0-2-.81L11.21,11a9,9,0,0,0-2.54,0L8.35,13.5a6.76,6.76,0,0,0-2,.81l-2-1.53a9.2,9.2,0,0,0-1.79,1.79l1.53,2a6.5,6.5,0,0,0-.81,2l-2.49.33a9.32,9.32,0,0,0,0,2.53l2.49.33a6.34,6.34,0,0,0,.81,1.95l-1.53,2a9.24,9.24,0,0,0,1.79,1.8l2-1.53a7.05,7.05,0,0,0,2,.81l.32,2.49a9.82,9.82,0,0,0,2.54,0l.33-2.49a7,7,0,0,0,2-.81l2,1.53a9.25,9.25,0,0,0,1.79-1.8l-1.53-2A6.7,6.7,0,0,0,16.55,21.71Z"/><path class="cls-1" d="M24.82,8a2.21,2.21,0,1,1-1.21-2.88A2.21,2.21,0,0,1,24.82,8Z"/><path class="cls-1" d="M26.76,10.05l1.75.46a6.46,6.46,0,0,0,.69-1.69l-1.57-.9a4.84,4.84,0,0,0,0-1.52l1.57-.92a7,7,0,0,0-.71-1.69l-1.75.48A4.77,4.77,0,0,0,25.65,3.2l.46-1.76A6.91,6.91,0,0,0,24.42.75l-.9,1.57a4.91,4.91,0,0,0-1.53,0L21.08.76a7,7,0,0,0-1.69.71l.48,1.75A5,5,0,0,0,18.79,4.3L17,3.84a7.05,7.05,0,0,0-.69,1.69l1.57.9a4.9,4.9,0,0,0,0,1.53l-1.57.91a6.68,6.68,0,0,0,.71,1.69l1.75-.48a5,5,0,0,0,1.08,1.08l-.46,1.75a6.46,6.46,0,0,0,1.7.69L22,12a4.9,4.9,0,0,0,1.53,0l.91,1.57a7,7,0,0,0,1.69-.71l-.48-1.75A5,5,0,0,0,26.76,10.05Z"/></g></g></svg>`,
        geographicalSciencesUrbanPlanning: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.22 28.81"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><polyline class="cls-1" points="26.63 10.51 26.63 28.06 3.62 28.06 3.62 10.51"/><rect class="cls-1" x="8.67" y="17.09" width="3.96" height="3.99"/><rect class="cls-1" x="15.67" y="17.09" width="5.87" height="10.96"/><polyline class="cls-1" points="0.75 12.91 15.11 0.75 29.47 12.91"/></g></g></svg>`,
        healthNutrition: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.5 30.52"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><line class="cls-1" x1="8.75" y1="17.15" x2="8.75" y2="22.35"/><line class="cls-1" x1="6.15" y1="19.75" x2="11.35" y2="19.75"/><path class="cls-1" d="M11.75,7.53V3.75h-6V7.53s-4.71,2-5,4.73V26.91A3.47,3.47,0,0,0,4,29.77h9.61a3.43,3.43,0,0,0,3.19-2.86V12.26C16.48,9.7,11.75,7.53,11.75,7.53Z"/><rect class="cls-1" x="4.75" y="0.75" width="8" height="3"/><rect class="cls-1" x="0.75" y="14.75" width="16" height="10"/><path class="cls-1" d="M25.25,26.75h0a3.5,3.5,0,0,1-3.5-3.5v-7a3.5,3.5,0,0,1,3.5-3.5h0a3.5,3.5,0,0,1,3.5,3.5v7A3.5,3.5,0,0,1,25.25,26.75Z"/><line class="cls-1" x1="21.75" y1="19.75" x2="28.75" y2="19.75"/></g></g></svg>`,
        history: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.93 30.5"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><polygon class="cls-1" points="0.75 9.75 14.98 0.75 29.18 9.75 0.75 9.75"/><path class="cls-1" d="M16.21,5.88A1.25,1.25,0,1,1,15,4.6,1.26,1.26,0,0,1,16.21,5.88Z"/><line class="cls-1" x1="0.96" y1="29.75" x2="28.96" y2="29.75"/><rect class="cls-1" x="4.46" y="12.75" width="2" height="14"/><rect class="cls-1" x="10.46" y="12.75" width="2" height="14"/><rect class="cls-1" x="17.46" y="12.75" width="2" height="14"/><rect class="cls-1" x="23.46" y="12.75" width="2" height="14"/></g></g></svg>`,
        humanitiesSocialSciences: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.43 30.21"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M22.74,29.36V22h3.94l-3.06-7,.6-2.91C24.4,7.22,24,7.5,23.9,7.43a2.54,2.54,0,0,0-.68-.09L21.35,9.18,19.48,7.34a2.54,2.54,0,0,0-.68.09c-.08.07-.5-.21-.32,4.66l.6,2.91L16,22H20v7.36"/><line class="cls-1" x1="4.83" y1="29.46" x2="4.83" y2="19.53"/><path class="cls-1" d="M6.68,2.81c0,1-.83,2.38-1.85,2.38S3,3.8,3,2.81a1.85,1.85,0,0,1,3.69,0Z"/><path class="cls-1" d="M23.22,3.08c0,1-.84,2.38-1.87,2.38s-1.87-1.39-1.87-2.38a1.87,1.87,0,0,1,3.74,0Z"/><path class="cls-1" d="M2.12,29.46,1.71,17.13c0-.26-.86.08-.87-.16-.35-9,.42-9.95.51-10s.95,0,1.2-.06a.84.84,0,0,1,.79.47,1.8,1.8,0,0,0,3,0,.87.87,0,0,1,.8-.47c.25,0,1.09,0,1.2.06s.86,1,.51,10c0,.24-.86-.1-.87.16L7.48,29.46"/><line class="cls-1" x1="12.9" y1="0.75" x2="12.9" y2="29.46"/></g></g></svg>`,
        language: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.29 30.08"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M19.54,29.33V28.1a.83.83,0,0,0-.52-.77c-3.68-1.44-5.73-3.22-6.08-4.33V21.83a7.29,7.29,0,0,0,1.72-3.17A1.91,1.91,0,0,0,15,15.92V13.31c0-2.69-1.48-4.54-4.87-4.54s-4.88,1.85-4.88,4.54v2.61a1.91,1.91,0,0,0,.36,2.74,7.39,7.39,0,0,0,1.72,3.17V23C7,24.11,5,25.89,1.27,27.33a.83.83,0,0,0-.52.77v1.23"/><path class="cls-1" d="M17.63,12.73h2v4.7l5.25-4.49a6,6,0,0,0,4.63-6A5.9,5.9,0,0,0,23.92.75H18.23a5.78,5.78,0,0,0-5.52,5"/><line class="cls-1" x1="17.16" y1="5.67" x2="24.47" y2="5.67"/><line class="cls-1" x1="17.16" y1="8.74" x2="20.82" y2="8.74"/></g></g></svg>`,
        liberalStudies: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.5 30.41"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><polyline class="cls-1" points="17.78 6.59 17.78 13.69 7.78 13.69 7.78 5.66 14.83 5.66"/><path class="cls-1" d="M9.12,5.66s2.31-3.92,5.61-4S19.38,2,21.51.75"/><path class="cls-1" d="M24.78,3a28.66,28.66,0,0,1-6,3c-.62.19-4.9,2.85-4.46,2.58A1.05,1.05,0,1,1,13.21,6.8l3.17-2.23"/><line class="cls-1" x1="19.75" y1="13.69" x2="6.78" y2="13.69"/><rect class="cls-1" x="0.75" y="16.66" width="25" height="13"/><polyline class="cls-1" points="17.78 11.71 21.75 11.71 25.75 16.66 0.75 16.66 4.75 11.71 7.63 11.71"/></g></g></svg>`,
        naturalSciences: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.46 29.66"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M21.94,4.67a16.06,16.06,0,0,1-4,2.38c-2.09.77-3.2,3.42-3.2,3.42a17.82,17.82,0,0,0,.74,4.24"/><path class="cls-1" d="M14.57,8.71c-1.28-3.83-9-2.46-9-2.46s2.59,10.1,9.11,4.22A5.66,5.66,0,0,1,14.57,8.71Z"/><path class="cls-1" d="M24.79.75c-8.52,1.56-10.07,5.61-10.22,8a5.66,5.66,0,0,0,.15,1.76C26.41,16.41,24.79.75,24.79.75Z"/><path class="cls-1" d="M9.81,8.82s3.72.23,4.91,1.65"/><path class="cls-1" d="M21.94,4.67a16.06,16.06,0,0,1-4,2.38c-2.09.77-3.2,3.42-3.2,3.42a17.82,17.82,0,0,0,.74,4.24,7.76,7.76,0,0,1,.18,2.91"/><path class="cls-1" d="M14.57,8.71c-1.28-3.83-9-2.46-9-2.46s2.59,10.1,9.11,4.22A5.66,5.66,0,0,1,14.57,8.71Z"/><path class="cls-1" d="M24.79.75c-8.52,1.56-10.07,5.61-10.22,8a5.66,5.66,0,0,0,.15,1.76C26.41,16.41,24.79.75,24.79.75Z"/><path class="cls-1" d="M9.81,8.82s3.72.23,4.91,1.65"/><path class="cls-1" d="M10,19.81c-1.58-1.22-.2-.28-3.7-3.77-.83-.83-2.17.24-1.34,1.07l1.35,1.68S7,20,6.68,20.36s-.94,0-1.78-.62c-.28-.28-.29-.2-2-2.79a2.22,2.22,0,0,1-.36-1.2V13.5a.89.89,0,0,0-1.78,0c0,2.31-.15,3.21.65,4.42C4,21.77,2.68,21,7.09,23.92c1.55,1,1.36,2.2,1.36,4.1a.89.89,0,0,0,.89.89H12a.89.89,0,0,0,.89-.89V25.11C12.84,23,12.59,21.14,10,19.81Z"/><path class="cls-1" d="M20.54,19.84c1.57-1.22.2-.29,3.66-3.77.82-.83,2.14.25,1.32,1.07l-1.33,1.67s-.65,1.22-.35,1.57.93,0,1.76-.62c.28-.27.29-.2,2-2.78a2.2,2.2,0,0,0,.35-1.19V13.54a.88.88,0,0,1,1.76,0c0,2.3.15,3.2-.64,4.41-2.5,3.84-1.25,3.06-5.61,6-1.53,1-1.35,2.2-1.35,4.1a.87.87,0,0,1-.88.88H18.63a.87.87,0,0,1-.88-.88V25.12C17.75,23,18,21.16,20.54,19.84Z"/></g></g></svg>`,
        publicServicePublicPolicy: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.9 29.48"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><polyline class="cls-1" points="2.82 22.25 2.82 24.21 3.9 24.21 3.9 26.22 5.42 26.22"/><path class="cls-1" d="M25.36,26.29h2.53v-5a.66.66,0,0,0-.36-.58l-4.1-1.77-2.87-4.39a.71.71,0,0,0-.62-.35l-17.12,0v2.07"/><line class="cls-1" x1="10.36" y1="26.29" x2="20.41" y2="26.29"/><path class="cls-1" d="M7.89,28.73a2.47,2.47,0,1,0-2.48-2.47A2.48,2.48,0,0,0,7.89,28.73Z"/><line class="cls-1" x1="4.46" y1="20.31" x2="1.99" y2="20.31"/><line class="cls-1" x1="0.75" y1="18.28" x2="5.69" y2="18.28"/><polygon class="cls-1" points="21.36 20.3 15.79 20.3 15.79 16.25 18.68 16.25 21.36 20.3"/><line class="cls-1" x1="7.89" y1="26.26" x2="7.89" y2="26.26"/><path class="cls-1" d="M22.89,28.73a2.47,2.47,0,1,0-2.48-2.47A2.48,2.48,0,0,0,22.89,28.73Z"/><line class="cls-1" x1="22.89" y1="26.26" x2="22.89" y2="26.26"/><path class="cls-1" d="M18.45,4.61,15.2,5.89c-1.18.47-2-.14-4.86-1.22l-.66.26L12,7.14l-.58.23c-1.25.49,4.37.25,6-.13l9.35-3.68S30,2.28,28.91,1.68s-4.17.45-4.17.45l-3.6,1.42L15.84.75l-.36.14Z"/><rect class="cls-1" x="7.75" y="16.25" width="8.03" height="4.05"/></g></g></svg>`,
        socialWork: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.49 22.4"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M20.27,17.56c-3.22-1.3-6.79-2.92-7.09-3.93V12.58A6.87,6.87,0,0,0,14.69,9.7,1.77,1.77,0,0,0,15,7.22V4.86C15,2.42,13.7.75,10.74.75S6.47,2.42,6.47,4.86V7.22A1.77,1.77,0,0,0,6.78,9.7a6.87,6.87,0,0,0,1.51,2.88v1.05c-.3,1-3.87,2.62-7.08,3.93a.74.74,0,0,0-.46.7V20.9a.73.73,0,0,0,.71.75H20a.73.73,0,0,0,.71-.75V18.26A.75.75,0,0,0,20.27,17.56Z"/><path class="cls-1" d="M23.74,21.65h5.44a.56.56,0,0,0,.56-.57v-2a.59.59,0,0,0-.36-.53c-2.54-1-5.36-2.19-5.6-2.95v-.8A5,5,0,0,0,25,12.64a1.29,1.29,0,0,0,.25-1.87V9c0-1.84-1-3.1-3.37-3.1S18.48,7.15,18.48,9v1.78a1.29,1.29,0,0,0,.25,1.87,5.06,5.06,0,0,0,1.19,2.17"/></g></g></svg>`,
        socialWorkBehavioralHealth: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.56 26.24"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M19.86,6.72H4.75a4,4,0,0,0-4,3.85V16.9a4,4,0,0,0,4,3.87H5.86v3.76a.85.85,0,0,0,1.44.71L12,20.77h7.86a3.88,3.88,0,0,0,3.88-3.87V10.57A3.86,3.86,0,0,0,19.86,6.72Z"/><path class="cls-1" d="M26.3,13.61a3.66,3.66,0,0,0,3.51-3.83V4.6a4,4,0,0,0-4-3.85H13.39A3.68,3.68,0,0,0,9.74,4.24"/><line class="cls-1" x1="5.86" y1="15.72" x2="18.29" y2="15.72"/><line class="cls-1" x1="5.86" y1="11.72" x2="18.29" y2="11.72"/></g></g></svg>`,
        sustainability: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.16 24.51"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M14.28,13.38a3.6,3.6,0,0,0-4.09-2.46,4.72,4.72,0,0,0-7.72,3A3.22,3.22,0,0,0,4.16,20H14.69a3.5,3.5,0,0,0,3.59-3.3A3.53,3.53,0,0,0,14.28,13.38Z"/><path class="cls-1" d="M27.19,4.8a2,2,0,0,0-2.26-1.36,2.62,2.62,0,0,0-4.28,1.64,1.79,1.79,0,0,0,.94,3.36h5.83a1.93,1.93,0,0,0,2-1.83A2,2,0,0,0,27.19,4.8Z"/><path class="cls-1" d="M26.7,8.44a12,12,0,0,1,.61,3.81A11.55,11.55,0,0,1,7.19,20"/><path class="cls-1" d="M4.33,10.76A11.54,11.54,0,0,1,22.52,2.93"/></g></g></svg>`,
        technology: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.24 21.56"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><g  data-name="Layer 2"><g data-name="Layer 1"><path class="cls-1" d="M27.65,15.77V1.7A.94.94,0,0,0,26.7.75H3.53a.94.94,0,0,0-.94,1V15.77"/><path class="cls-1" d="M16.67,17.81v.38a.55.55,0,0,1-.55.54h-2a.54.54,0,0,1-.54-.54v-.38H.75v2a1,1,0,0,0,1,1H28.49a1,1,0,0,0,1-1v-2Z"/><path class="cls-1" d="M7.79,9.32a.82.82,0,1,1,.82-.82A.83.83,0,0,1,7.79,9.32Z"/><path class="cls-1" d="M12.65,6.26a.82.82,0,1,1,.83-.82A.82.82,0,0,1,12.65,6.26Z"/><path class="cls-1" d="M17.52,13.68a.82.82,0,0,1-.83-.81.83.83,0,0,1,1.65,0A.82.82,0,0,1,17.52,13.68Z"/><path class="cls-1" d="M22.38,8.44a.82.82,0,0,1-.83-.81.83.83,0,0,1,1.66,0A.82.82,0,0,1,22.38,8.44Z"/><line class="cls-1" x1="11.83" y1="5.96" x2="8.48" y2="8.06"/><line class="cls-1" x1="13.1" y1="6.13" x2="17.07" y2="12.18"/><line class="cls-1" x1="18.07" y1="12.27" x2="21.81" y2="8.24"/></g></g></svg>`,
      },
    };
  },
  methods: {
    gtmTrigger(isRefined, text) {
      let dl = {
        event: "select",
        name: "onclick",
        action: !isRefined ? "click" : "onclick",
        type: "course search",
        region: "main content",
        section: "search programs and courses",
        text: text.toLocaleLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    getImageUrl(image) {
      return this.degreeSVG[_.camelCase(image)]
        ? this.degreeSVG[_.camelCase(image)]
        : this.degreeSVG["technology"];
    },
  },
};
</script>

<style lang="scss">
.interest-area-link {
  display: flex;
  height: 46px;
  color: black !important;
  float: left;
  clear: right;
  align-items: center;
  svg {
    width: 15px;
  }
}
.addActivePill {
  .cls-1 {
    stroke: white !important;
  }
  background-color: #29413b !important;
  color: white !important;
  &:hover,
  &:focus {
    color: white !important;
  }
}
.filter-checks {
  @media only screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
}
</style>
