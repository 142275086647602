<template>
  <section
    id="RFI-section"
    class="utility-bg-cover roboto-section"
    :class="[page !== 'offpage'?'pt-lg-108 pb-lg-96': 'offpage-RFI']"
    :style="page !== 'offpage' ? 'background-color: #FAFAFA' : ''"
  >
    <div class="container p-0">
      <div class="row bg-white mx-auto">
        <div class="col-lg-5 utility-bg-cover rfi-min-height pr-md-0"></div>
        <div class="col-lg-6 col-11 mx-auto px-0 utility-bg-cover-border">
          <stepOne :page="page" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import stepOne from "./step-1";
export default {
  components: {
    stepOne,
  },
  props: {
    page: {
      type: String,
      default: "home"
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 991px) {
  .utility-bg-cover-border {
    &:before {
      border-top: 5px solid #006340;
      content: "";
      width: 117%;
      left: -9%;
      position: absolute;
    }
  }
}
.rfi-min-height {
  min-height: 650px;
  background-image: url("../assets/images/rfi-bg-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.visible-div {
  visibility: hidden;
}

@media screen and (max-width: 991px) {
  .rfi-min-height {
    min-height: 350px;
    background-image: url("../assets/images/rfi-card-image.png");
    background-size: cover;
    background-position: top;
  }
}
@media screen and (max-width: 600px) {
  .rfi-min-height {
    min-height: 212px !important;
    background-image: url("../assets/images/rfi-card-image.png");
    background-size: cover;
    background-position: top;
  }
  .col-lg-7 {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
