import axios from "axios";

export const briteVerify = async (param) => {
  // params => Object , Eg: {'email':dd@dd.us} or {'phone':+12015550123}
  const body = param;
  try {
    const { data } = await axios({
      url: process.env.VUE_APP_BRITEVERIFY_URL,
      headers: {
        Authorization: `ApiKey: ${process.env.VUE_APP_BRITEVERIFY_API_KEY}`,
        "Content-Type": "application/json"
      },
      method: "post",
      data: JSON.stringify(body)
    });

    return data;
  } catch (e) {
    console.log(e);
  }
};
