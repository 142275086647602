<template>
  <div id="section_starbucks_opportunities">
    <div class="container">
      <b-card class="bg-light-2">
        <div class="pt-34 pb-34 pl-16 pt-lg-64 pb-lg-64 pl-lg-64 pr-lg-64">
          <h2
            v-html="pageData.starbucksOpportunitesSection.title"
            class="d-flex flex-lg-row flex-column starbucksOpportunitesHeader"
            style="width: fit-content"
          ></h2>
          <p class="pt-16 d-none d-lg-block">
            {{ pageData.starbucksOpportunitesSection.text }}
          </p>
          <b-row>
            <b-col lg="5" class="d-none d-lg-block mt-lg-14">
              <b-img
                v-if="`${tabs[activeTab].image}` != undefined"
                :src="
                  require(`../assets/images/careers/${tabs[activeTab].image}`)
                "
                fluid
                alt=""
              ></b-img>
            </b-col>
            <b-col cols="12" lg="7" id="tabsstyles" class="mt-lg-0 mt-64">
              <b-tabs v-model="activeTab" pills card class="px-0 pr-16 pr-lg-0">
                <b-tab
                  v-for="(tab, index) in tabs"
                  :key="index"
                  :title="tab.title"
                  @click="test(tab.title)"
                >
                  <div class="py-0">{{ tab.text }}</div>
                  <div class="ml-27" v-html="tab.pointers"></div>
                  <div class="pt-12" v-html="tab.subText"></div>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { carrerServices } from "../assets/data/careers.json";

export default {
  data() {
    return {
      pageData: carrerServices,
      activeTab: 0,
      tabs: [],
    };
  },
  created() {
    this.tabs = this.pageData.tabSection.map((item) => ({
      title: item.title,
      text: item.text,
      pointers: item.pointers,
      image: item.image,
      subText: item.subText,
    }));
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
    test(title) {
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "main content",
        section: "starbucks opportunities for students",
        text: title.toLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
  },
};
</script>

<style lang="scss">
#tabsstyles {
  .card-header {
    background-color: #f1f1f1;
    padding: 0em 0.6em;
    border-bottom: 1px solid #d0d0d0;

    .nav.nav-pills.card-header-pills {
      background-color: #f1f1f1;
      .nav-link.active {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        color: black;
        border: none;
        border-radius: 0%;
        border-bottom: 3px solid #ffc627;
        background-color: #f1f1f1;
        padding-left: 0px;
        padding-right: 0px;
      }
      .nav-link {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        color: black;

        padding: 0.5rem 0.7rem 0.5rem 0.2rem !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.card-body {
  background-color: #f1f1f1;
  padding: 14px 0px;
}
@media only screen and (min-width: 992px) {
  .starbucksOpportunitesHeader {
    font-size: 36px;
  }
}
@media only screen and (max-width: 992px) {
  .starbucksOpportunitesHeader {
    max-height: 70px;

    font-size: 24px;
  }
}
</style>
