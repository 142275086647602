var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{staticClass:"font-label pb-space-xs",attrs:{"id":`input-group-${_vm.id}`,"label":_vm.label,"label-class":_vm.labelSize,"label-for":`id-input-${_vm.id}`}},[_c('label',{attrs:{"for":"phone-number"}},[_vm._v("* Phone Number")]),_c('vue-tel-input',{class:{
      'is-invalid':

        (_vm.emptyValue===false && _vm.phoneNumber)&&
        ((!_vm.phone.isValid && _vm.phoneNumber) ||
        (!_vm.phoneNumber && _vm.requiredFlag) ||
        (_vm.clickedSubmit && !_vm.phoneNumber)),
    },attrs:{"defaultCountry":'US',"inputId":"phone-number","inputClasses":'form-control form-control-input',"name":"phone-number","no-validator-state":true,"aria-label":"Phone Number","aria-describedby":"tel phone number","title":"enter phone number","placeholder":"","disabledFormatting":true},on:{"blur":function($event){return _vm.triggerFormGTM('phone number')},"onInput":_vm.onInput},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('b-form-invalid-feedback',{class:{ 'd-block': !_vm.phone.isValid && _vm.phoneNumber },attrs:{"id":`input-${_vm.id}-live-feedback`}},[_c('span',[_vm._v(" "+_vm._s(!_vm.phone.isValid && _vm.phoneNumber ? "Invalid phone number. Please try again." : "")+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }