module.exports = {
  getGtmClientIds() {
    const gaCookie = getCookie("_ga");
    let gaClientId = null;

    try {
      if (gaCookie && typeof gaCookie !== "undefined") {
        // sample value: GA1.1.2040707708.1641428898
        gaClientId = gaCookie
          .split(".")
          .splice(2)
          .join(".")
          .toString();
      }
      console.log(gaClientId);
      return {
        clientid: gaClientId,
        enterpriseclientid: gaClientId,
      };
    } catch (error) {
      // eslint-disable-next-line
      console.error("== error in getting client IDs: ", error);
    }
  },
  getUTMCookies() {
    return {
      utm_ecd22: getCookie("utm_ecd22"),
    };
  },
  getASUOCookies() {
    const asuo = getCookie("asuo");
    var result = {};

    if (asuo !== null) {
      const parsedArray = asuo.split("|");
      let index = 0;
      parsedArray.forEach((item) => {
        index = item.indexOf("=");
        result[item.substring(0, index)] = item.substring(index + 1);
      });
    } else {
      result = {
        utm_source: null,
        utm_medium: null,
        utm_term: null,
        utm_content: null,
        utm_campaign: null,
        utm_campaignid: null,
        utm_adid: null,
        utm_adgroupid: null,
        psfcid: null,
        gclickid: null,
        gclsrc: null,
      };
    }

    return result;
  },
};

function getCookie(cname) {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}
