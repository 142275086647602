<template>
  <div class="accolades bg-dark-green p-4">
    <div class="accolades-content my-3">
      <h2
        class="font-weight-bold font-lg-21 font-21 mb-3 fam-roboto text-white"
      >
        Accolades
      </h2>
      <p v-html="accolades.description" class="text-white fam-roboto"></p>
      <div class="row">
        <span
          :class="{ expandable: limit > 4 }"
          v-for="(type, index) in accolades.items.slice(0, limit)"
          :key="`${type.title}-${index}`"
          class="text-white fam-roboto font-weight-bold col-6 font-18"
        >
          {{ type.title }}
          <span
            class="bg-brand-gold d-block my-2"
            style="height: 2px; width: 30px"
          ></span>
          <p
            class="font-weight-light fam-roboto font-16"
            v-html="type.description"
          ></p>
        </span>
      </div>
      <div class="text-center">
        <a
          style="cursor: pointer"
          @click="MoreAndLess(accolades.items.length)"
          v-if="accolades.items.length > 4"
          class="text-white text-underline mt-space-md"
        >
          {{ limit == 4 ? "Show More" : "Show Less" }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    accolades: Object,
  },
  methods: {
    MoreAndLess(filters_length) {
      let dl = {
        event: "collapse",
        name: "onclick",
        action: "",
        type: "click",
        region: "main content",
        section: "accolades",
        text: "",
      };
      if (this.limit == filters_length) {
        this.limit = this.default_limit;
        dl.action = "close";
        dl.text = "show less";
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } else {
        dl.action = "open";
        dl.text = "show more";
        this.$gtm.trackEvent(dl);
        utag.link(dl);
        this.limit = filters_length;
      }
    },
  },
  data() {
    return {
      default_limit: 4,
      limit: 4,
      isShowMore: false,
    };
  },
};
</script>
<style scoped>
.expandable {
  transition: all 0.5s ease-in-out;
  height: auto;
}
</style>
