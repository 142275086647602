<template>
  <div class="featured-courses my-5">
    <h2 class="font-weight-bold font-lg-21 font-21 mb-3 fam-roboto">
      Featured Courses
    </h2>
    <div class="course__type ml-3">
      <div
        class="row align-items-center courses"
        :class="index"
        v-for="(types, index) in featured.items"
        :key="types.title"
      >
        <div class="col-4">
          <p
            class="
              course-title
              bg-dark-green
              m-0
              text-center
              fam-roboto
              text-white
              p-1
              font-weight-bold font-lg-16 font-14
            "
          >
            {{ courseTitleSplit(types.title) }}
          </p>
        </div>

        <p class="col-8 fam-roboto font-lg-16 m-0 p-0 pl-3 font-14">
          {{ courseDescriptionSplit(types.title) }}
        </p>
        <hr v-if="index != featured.items.length - 1" class="w-100" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    featured: Object,
  },
  methods: {
    courseTitleSplit(splitble) {
      let arr = splitble.split(":");
      return `${arr[0]}`;
    },
    courseDescriptionSplit(splitble) {
      let arr = splitble.split(":");
      return `${arr[1]}`;
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 767px) {
  .course__type {
    margin-left: 5% !important;
  }
  .course-title {
    padding: 4px 2px !important;
  }
}
</style>