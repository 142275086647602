<template>
  <agile
    :nav-buttons="false"
    @afterChange="showCurrentSlide($event)"
    :centerMode="true"
  >
    <div
      class="d-flex flex-wrap slide align-items-center flex-wrap-reverse"
      v-for="(testimonial, i) in testimonials"
      :key="i"
    >
      <div class="col-md-8">
        <div
          class="content-header col-md-11 pl-4 pb-24 text-center text-lg-left"
        >
          <img
            src="../assets/images/quotations.svg"
            alt="quotations"
            class="pb-30"
          />
          <p
            class="font-fam-Georgia font-lg-24 font-18 text-dark-green"
            style="line-height: 28px"
          >
            {{ testimonial.content }}
          </p>
          <p class="fam-roboto-light font-lg-16 font-14 pt-lg-48 text-dark-green">
            {{ testimonial.user }}
          </p>
        </div>
      </div>
      <div
        :style="`background-image: url(${gerImgPath(testimonial.image)});background-repeat: no-repeat; background-size: contain;min-height:550px`"
        class="backround-cover col-md-4"
      ></div>
    </div>
  </agile>
</template>

<script>
export default {
  data() {
    return {
      iterator: 0,
      testimonials: [
        {
          user: "Robert L. | Starbucks College Achievement Plan graduate",
          name: "Robert l",
          content:
            "The Starbucks College Achievement Plan has really armed me with the tools to go out and be someone I’ve always aspired to be. I just maybe didn’t know how. I’m no different than you. So, if I can do it, you can do it. Let’s do this together.",
          image: "Testimonial-graphic-1.png"
        },
        {
          user: "Fidel W. | Starbucks College Achievement Plan graduate",
          content:
            "I was born and raised in the Philippines. I had to stop school to be able to migrate to the U.S. Education is something my parents wanted for all of us and that’s something I gave them in my own little way, with the help of Starbucks, of course. I graduated.",
          image: "Testimonial-graphic-2.png",
          name: "Fidel W"
        },
        {
          user: "Julissa M. | Starbucks College Achievement Plan graduate",
          content:
            "When I came to Starbucks and heard about the College Achievement Plan, I felt like for the first time this was my moment. Ever since my girls were born, I’ve been putting money aside because I always wanted them to go to college. This gave me the opportunity to go and still continue to save for my kids. Now my daughter just graduated and is going to college. I’m so proud of her.",
          image: "Testimonial-graphic-3.png",
          name: "Julissa M"
        }
      ]
    };
  },
  methods: {
    gerImgPath(img) {
      return require(`../assets/images/${img}`);
    },
    showCurrentSlide(event) {
      this.iterator+=1
      console.log(this.iterator+1)
      if(this.iterator > 1){
        let dl ={
        event: "select",
        name: "onclick",
        action: "click",
        type: "owl dot",
        region: "main content",
        section: "testimonials",
        text: this.testimonials[event.currentSlide].name.toLocaleLowerCase()
      }
      this.$gtm.trackEvent(dl);
      utag.link(dl)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.backround-cover {
  @media only screen and (max-width: 991px) {
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
