<template>
  <div class="offset-image">
    <div class="row mx-0 mx-lg-15 position-relative" v-if="justify == 'left'" :class="classes">
      <slot name="image"> </slot>
      <slot name="content"> </slot>
    </div>
    <div class="row row mx-0 mx-lg-15 position-relative" v-else :class="classes">
      <slot name="content"> </slot>
      <slot name="image"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    justify: String,
    vCenter: Boolean
  },
  computed: {
    classes() {
      let instanceClasses = [
        { 'align-items-center': this.vCenter },
      ];
      if(this.justify === 'right'){
        instanceClasses.push("flex-wrap-reverse")
      }
      return instanceClasses;
    }
  }
};
</script>
