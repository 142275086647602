<template>
  <b-form-group
    :id="`input-group-${id}`"
    :label="label"
    :label-class="labelSize"
    :label-for="`id-input-${id}`"
    class="font-label pb-space-xs"
  >
  <label for="phone-number">* Phone Number</label>
    <vue-tel-input
      :defaultCountry="'US'"
      inputId="phone-number"
      v-model="phoneNumber"
      @blur="triggerFormGTM('phone number')"
      :inputClasses="'form-control form-control-input'"
      @onInput="onInput"
      name="phone-number"
      :no-validator-state="true"
      aria-label="Phone Number"
      aria-describedby='tel phone number'
      title="enter phone number"
      placeholder=""
      :disabledFormatting="true"
      :class="{
        'is-invalid':

          (emptyValue===false && phoneNumber)&&
          ((!phone.isValid && phoneNumber) ||
          (!phoneNumber && requiredFlag) ||
          (clickedSubmit && !phoneNumber)),
      }"
      
    ></vue-tel-input>
    <b-form-invalid-feedback
      :id="`input-${id}-live-feedback`"
      :class="{ 'd-block': !phone.isValid && phoneNumber }"
    >
      <span>
        {{
          !phone.isValid && phoneNumber
            ? "Invalid phone number. Please try again."
            : ""
        }}
      </span>
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "BasePhoneInput",
  props: {
    id: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      default: "required",
    },
    labelSize: {
      type: String,
      default: "",
    },
    eventComponent: {
      type: String,
    },
    label: {
      type: String
    },
    clickedSubmit: {
      type: Boolean,
      default: false,
    },
    briteVerifyPhoneIsValid:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      requiredFlag: false,
      briteVerifySuccess: "",
      emptyValue:true,
      phone: {
        isValid: true,
      },
      phoneNumber: "",
    };
  },
  watch:{
    briteVerifyPhoneIsValid:{
      handler(newVal) {
        // Watch for changes to the prop and update the data variable
        this.phone.isValid = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    onInput({ regionCode, valid, number }) {
      this.emptyValue=false
      this.telephoneValid = true;
      this.phone.number = number.e164;
      this.phone.isValid = valid;
      this.phone.country = regionCode;
      //this.phone.isValid = false;
      this.$emit("input", this.phone);
    },
    triggerFormGTM() {
      this.requiredFlag = true;
      this.$emit("blur", this.phone);
    },
  },
};
</script>
