import "core-js/stable";
import "regenerator-runtime/runtime";
import "intersection-observer";
import Vue from "vue";
import VueAgile from "vue-agile";
import VueJsonLD from "vue-jsonld";
import App from "./layout.vue";
import router from "./router";
import VueMeta from "vue-meta";
import vClickOutside from "v-click-outside";
import "./base/_global";
import VueTheMask from "vue-the-mask";
import store from "./store";
import "./assets/scss/custom.scss";
import VueTagManager from "vue-gtm";
import "regenerator-runtime/runtime";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueScrollactive from "vue-scrollactive";
import VueTelInput from "vue-tel-input";
import InstantSearch from "vue-instantsearch";
import "./plugins/vee-validate";
Vue.use(VueTagManager, {
  id: ["GTM-QF9B"],
  enabled: true,
});
Vue.use(vClickOutside);
Vue.use(VueAgile);
Vue.use(VueTelInput);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(BootstrapVueIcons);
Vue.use(VueScrollactive);
Vue.use(VueJsonLD);
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
