<template>
  <b-modal
    class="apply-now-modal-container"
    :id="locale === 'degreeSearch' ? 'apply-now-modal' : 'modal-1'"
    size="xl"
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <BaseGtmLink
        v-if="locale !== 'degreeSearch'"
        tag="a"
        href="javascript:void(0)"
        class="close-apply text-dark font-36 ml-auto border-none"
        @click="close"
        :gtm="{
          event: 'modal',
          name: 'onclick',
          action: 'close',
          type: 'click',
          region: 'main content',
          section: 'ready to apply',
          text: 'close cross',
        }"
      >
        <img src="../assets/images/Icon-close.svg" alt="close" />
      </BaseGtmLink>
      <!-- if locale is from degree search page -->
      <BaseGtmLink
        v-else
        tag="a"
        href="javascript:void(0)"
        class="close-apply text-dark font-36 ml-auto border-none"
        @click="close"
        :gtm="{
          event: 'modal',
          name: 'onclick',
          action: 'close',
          type: 'click',
          region: 'main content',
          section: 'ready to apply?',
          text: 'close button',
        }"
      >
        <img src="../assets/images/Icon-close.svg" alt="close" />
      </BaseGtmLink>
    </template>
    <div
      class="
        col-lg-6
        d-flex
        align-items-center
        justify-content-center
        flex-column
        mx-auto
      "
    >
      <validation-observer>
        <div class="sbux-default" v-show="!$store.state.subx_id_error">
          <div class="text-center">
            <h2 class="fam-roboto font-36 font-black pt-40 pb-30">
              Ready to apply?
            </h2>
            <div class="pb-30">
              <p class="p-0 m-0">
                <img
                  src="../assets/images/info-icon.svg"
                  alt="info-icon"
                  style="width: 1rem"
                />
                Please note: The following form is for current Starbucks
                partners to apply for ASU admission.
                <BaseGtmLink
                  tag="a"
                  href="https://www.starbucks.com/careers/"
                  class="text-light-green text-underline"
                  target="_blank"
                  :gtm="{
                    event: 'link',
                    name: 'onclick',
                    action: 'click',
                    type: 'internal link',
                    region: 'main content',
                    section: 'ready to apply?',
                    text: 'click here to apply to be a starbucks partner',
                  }"
                >
                  Click here to apply to be a Starbucks partner.
                </BaseGtmLink>
              </p>
            </div>
          </div>
          <check-partner-id comp="navbar" class="col-md-8 mx-auto" />
        </div>
        <div class="sbux-id--error" v-show="$store.state.subx_id_error">
          <h2 class="fam-roboto font-36 font-black pt-40 pb-30">
            Please try again.
          </h2>
          <p class="stb-ligt font-16">
            Partner Number does not match. Please try again. If you need
            assistance, contact the Partner Contact Center (PCC) at
            888-SBUX-411.
          </p>
          <div class="row">
            <div class="col-9 col-sm-6">
              <validation-provider
                v-slot="validationContext"
                name="partner number"
                rules="required|digits:8"
              >
                <b-form-group
                  id="input-group-subx"
                  label="Partner number"
                  label-for="id-input-subx"
                  class="font-label pb-space-xs"
                  ><div class="input-group d-flex align-items-center">
                    <div
                      :class="{
                        'input-group-prepend': true,
                        'is-invalid':
                          validationContext.errors[0] ||
                          (rfi_error && $store.state.subx_id.length === 8),
                      }"
                    >
                      <span
                        class="input-group-text fam-roboto font-black border-right-0"
                        id="inputGroupPrepend2"
                        >US -</span
                      >
                    </div>
                    <b-form-input
                      id="id-input-subx"
                      v-model="$store.state.subx_id"
                      placeholder="00000000"
                      name="name-input-subx"
                      class="border-left-0 mr-1"
                      :class="{
                        'input-group-prepend': true,
                        'border-danger': $store.state.subx_id_error,
                      }"
                      :style="
                        $store.state.subx_id_error &&
                        $store.state.subx_id.length === 8 &&
                        rfi_error
                          ? 'border-color:#dc3545 !important'
                          : ''
                      "
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-subx-live-feedback"
                      @input="
                        (rfi_error = false),
                          triggerFormGTM('partner number', 'partner')
                      "
                    ></b-form-input>
                  </div>
                  <b-form-invalid-feedback
                    id="input-subx-live-feedback"
                    v-if="validationContext.errors"
                  >
                    <div class="mb-space-lg">
                      {{ validationContext.errors[0] }}
                    </div>
                  </b-form-invalid-feedback>
                  <span
                    v-if="
                      $store.state.subx_id_error &&
                        validationContext.errors.length === 0 &&
                        rfi_error
                    "
                    class="is-invalid text-danger d-table width-changes invalid-feedback"
                    >Invalid partner number</span
                  >
                </b-form-group>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <BaseGtmLink
            tag="button"
            class="btn btn-dark-green text-white mt-3 mx-auto"
            @click="checkPartnerNumber()"
            :gtm="{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              corporate_partner_id:
                $store.state.partner_selected === 'no'
                  ? '00000000'
                  : $store.state.subx_id,
              section: `${
                $store.state.subx_id_error
                  ? 'please try again'
                  : 'ready to apply'
              }`,
              text: 'submit'.toLocaleLowerCase(),
            }"
            :disabled="
              !(
                $store.state.subx_id.length === 8 ||
                $store.state.partner_selected === 'no'
              )
            "
            >Submit</BaseGtmLink
          >
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CheckPartnerId from "./check-partner-id.vue";
import registerService from "../services/register";

export default {
  components: {
    CheckPartnerId,
    "validation-observer": ValidationObserver,
    "validation-provider": ValidationProvider,
  },
  data() {
    return {
      rfi_error: false,
    };
  },
  props: {
    locale: {
      required: true,
      type: String,
    },
    session: {
      type: String,
      default: "",
    },
    applynowurl: {
      default: "https://webapp4.asu.edu/uga_admissionsapp/?partner=SCAP",
      type: String,
    },
    programPlanAndSubplan: {
      default: "",
      type: String,
    },
  },
  methods: {
    triggerFormGTM(_text, type = null) {
      let dl = {
        event: type ? "select" : "form",
        name: "onclick",
        action: "click",
        type: type ? type : "click",
        region: "main content",
        section: "get started at asu today!",
        text: _text.toLocaleLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    async checkPartnerNumber() {
      console.log("checkPartnerNumber");
      try {
        if (this.$store.state.subx_id.length != 8) {
          window.location.replace("https://webapp4.asu.edu/uga_admissionsapp/");
        } else {
          this.checking = true;
          await registerService.checkPartner(this.$store.state.subx_id);
          this.$store.state.subx_id_error = false;
          // Redirect users to the scap page to apply with UNDECIDED
          window.location.replace(
            this.applynowurl + this.programPlanAndSubplan + this.session
          );
        }
      } catch (err) {
        this.checking = false;
        // console.log(err);
        this.$store.state.subx_id_error = true;
        this.rfi_error = true;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content {
  min-height: 80vh;
  border-top: 10px solid #006340;
}
</style>
