var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseOffsetImg',{attrs:{"justify":"left","vCenter":""},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('div',{staticClass:"backround-cover col-lg-6 position-relative",style:(`background-image: url(${require('../assets/images/military.png')});background-position: center;`)})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"col-lg-6 bg-white"},[_c('div',{staticClass:"content-header col-11 mx-auto ml-lg-0 pb-lg-48 pt-lg-5 pl-lg-36"},[_c('h2',{staticClass:"font-fam-Georgia font-lg-36 font-32 pb-lg-24"},[_vm._v(" A path for veterans and military families ")]),_c('p',{staticClass:"font-16 stb-light"},[_vm._v(" As a veteran partner, you can extend an additional Starbucks College Achievement Plan benefit to a qualifying family member. Like you, your family member can choose from 150+ online bachelor's degrees and receive the same tuition coverage. The family member can call"),_c('BaseGtmLink',{staticClass:"text-underline text-light-green px-1",attrs:{"tag":"a","href":"tel:844-278-7289","gtm":{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'a path for veterans and military families',
              text: '844-ASU-SBUX (278-7289)'.toLocaleLowerCase(),
            }}},[_vm._v("844-ASU-SBUX (278-7289)")]),_vm._v(" and mention they’re a dependent of a military partner to get started. ")],1),_c('img',{staticClass:"img-fluid mx-auto d-block mx-lg-0",attrs:{"src":require("../assets/images/veterans-img.svg"),"alt":"veterans"}})])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }