<template>
  <div class="success-testimonial pt-lg-120">
    <div class="success container mb-lg-200 mb-80">
      <b-row>
        <div class="success-content col-lg-4 text-center text-lg-left">
          <div id="success-pin" class="d-lg-block d-none">
            <h3 class="font-lg-36 font-fam-Georgia font-24">
              Your full-service success team
            </h3>
            <p class="font-lg-18 font-14 stb-light pb-24">
              Whether you’re starting your application, staying on track during
              your studies or preparing for a job, we have a success team to
              help you throughout your college experience.
            </p>
            <BaseGtmLink
              tag="a"
              target="_blank"
              href="./Taxation of Starbucks Tuition Benefit_2024.pdf"
              download="Taxation of Starbucks Tuition Benefit_2024.pdf"
              class="sbtn font-lg-16 font-12 bg-brand-black mr-1 text-white btn-round pt-lg-12 p-2 pr-lg-15 pl-lg-15 pb-lg-12 mb-50"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'your full-service success team',
                text: 'download pdf'.toLocaleLowerCase(),
              }"
              round
            >
              Download PDF
              <img
                src="../assets/images/download-icon.svg"
                alt="download-icon"
              />
            </BaseGtmLink>
          </div>
          <div class="d-lg-none col-md-10 mx-auto">
            <h3 class="font-lg-36 font-fam-Georgia font-24">
              Your full-service success team
            </h3>
            <p class="font-lg-18 font-14 stb-light pb-24">
              Whether you’re starting your application, staying on track during
              your studies or preparing for a job, we have a success team to
              help you throughout your college experience.
            </p>
            <BaseGtmLink
              tag="a"
              target="_blank"
              href="./Taxation of Starbucks Tuition Benefit_2024.pdf"
              download="Taxation of Starbucks Tuition Benefit_2024.pdf"
              class="sbtn font-lg-16 font-12 bg-brand-black mr-1 text-white btn-round pt-lg-12 p-2 pr-lg-15 pl-lg-15 pb-lg-8 mb-50"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'internal link',
                region: 'main content',
                section: 'your full-service success team',
                text: 'download pdf'.toLocaleLowerCase(),
              }"
              round
            >
              Download PDF
              <img
                src="../assets/images/download-icon.svg"
                alt="download-icon"
              />
            </BaseGtmLink>
          </div>
        </div>
        <div class="col-lg-7 ml-auto d-none d-lg-block success-graphic">
          <template v-for="(success, i) in successTeam">
            <div
              class="action-container position-relative"
              :key="success.action"
            >
              <h4
                class="action position-absolute stb-sBold font-18 pl-2 bg-white"
                :style="`color:${success.color};right:0;top:5px;z-index:2`"
              >
                {{ success.action }}
              </h4>
              <hr class="position-absolute w-100" />
            </div>
            <div class="row align-items-center mt-4" :key="i">
              <template v-for="action in success.content">
                <div
                  class="col-4 text-right success-image-container"
                  :class="`success-image-container--${action.counter}`"
                  :key="`action-${action.counter}`"
                >
                  <img
                    :src="require(`../assets/images/${action.image}`)"
                    alt="success-service-1"
                    class="success-image img-fluid"
                  />
                </div>
                <div
                  class="col-8 pl-30 pt-40"
                  :key="`action-${action.heading}`"
                >
                  <h3 class="font-fam-Georgia font-24">{{ action.heading }}</h3>
                  <p class="text-light-green font-16 p-0 m-0 stb-sBold">
                    {{ action.subHeading }}
                  </p>
                  <p class="font-18">{{ action.desc }}</p>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="col-lg-7 d-lg-none mt-60 text-center">
          <img
            src="../assets/images/success-service-mobile.svg"
            alt="success-service-mobile"
            class="img-fluid"
          />
        </div>
      </b-row>
    </div>
    <div
      class="testimonials text-center d-flex align-items-center"
      :style="
        `background-image: url(${require('../assets/images/Testimonial_Illustrations.svg')})`
      "
    >
      <div class="bottom-postion container" style="overflow-x: hidden">
        <h3 class="font-fam-Georgia pb-18 font-lg-36 font-24 text-center">
          Hear from other Starbucks partners
        </h3>
        <agile
          :nav-buttons="false"
          @afterChange="showCurrentSlide($event)"
          :centerMode="true"
        >
          <div
            class="d-flex flex-wrap slide align-items-center"
            v-for="(testimonial, i) in testimonials"
            :key="i"
          >
            <div class="container">
              <div class="content-header col-md-11 pl-4 pb-18 mx-auto">
                <p class="stb-sBold font-lg-30 font-18 line-height-fix mb-0">
                  {{ testimonial.content }}
                </p>
                <p class="fam-roboto-light font-16 pt-lg-42 pt-30">
                  {{ testimonial.user }}
                </p>
              </div>
            </div>
          </div>
        </agile>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      testimonials: [
        {
          user: "Kyle | Starbucks College Achievement Plan graduate",
          content:
            "[I enjoyed] having that flexibility to be able to have a more custom work schedule and just a life outside of work and school. It was the perfect mix all around.",
          name: "Kyle",
        },
        {
          user: "Kate W. | Starbucks College Achievement Plan graduate",
          content:
            "The first time I connected with my enrollment coach, Betsy, we spent two hours on the phone! She was incredibly knowledgeable and took the time to explain that no matter what, ASU was going to give me a fresh chance to prove myself as a student.",
          name: "Kate W",
        },
        {
          user: "Kiki C. | Starbucks College Achievement Plan graduate",
          content:
            "My big aha moment was being able to balance school, work and a social life. Talking honestly with your manager, family and friends makes it easier to work around your responsibilities. It’s not always easy and sometimes you have to say no. However, if you balance your time to the best of your availability, it's all possible.",
          name: "Kiki C",
        },
      ],
      successTeam: [
        {
          action: "Apply",
          color: "#006241",
          content: [
            {
              image: "success-service-1.svg",
              heading: "Taking the first step",
              subHeading: "Enrollment coach",
              desc:
                "Provides application assistance, helps you find the program that’s right for you and preps you for your first day.",
              counter: "1",
            },
          ],
        },
        {
          action: "Enrollment",
          color: "#191919",
          content: [
            {
              image: "success-service-2.svg",
              heading: "Helping you with finances",
              subHeading: "Financial aid counselor",
              desc:
                "Provides financial aid assistance and can answer questions regarding the SCAP program.",
              counter: "2",
            },
            {
              image: "success-service-3.svg",
              heading: "Looking out for your success",
              action: "Enrollment",
              subHeading: "Success coach",
              desc:
                "Provides guidance throughout your journey, helping you to overcome obstacles and execute on your goals; your personal connection to ASU.",
              counter: "3",
            },
            {
              image: "success-service-4.svg",
              heading: "Keeping you on track",
              action: "Graduation",
              subHeading: "Academic advisor",
              desc:
                "Provides assistance with course scheduling and has in-depth knowledge of program requirements and policies.",
              counter: "4",
            },
          ],
        },
        {
          action: "Graduation",
          color: "#8C1D40",
          content: [
            {
              image: "success-service-5.svg",
              heading: "Preparing for the future",
              subHeading: "Career and industry specialist",
              desc:
                "Provides support in your career exploration, resume writing, navigating the job market and searching for jobs or internships.",
              counter: "5",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.scrollMagicMethod();
    });
  },
  methods: {
    scrollMagicMethod() {
      let controller = new window.ScrollMagic.Controller();
      new window.ScrollMagic.Scene({
        triggerElement: "#success-pin",
        offset: "150",
        duration: document.getElementsByClassName("success-graphic")[0]
          .offsetHeight,
      })
        .setPin("#success-pin")
        .addTo(controller);
    },
    showCurrentSlide(event) {
      let dl = {
        event: "select",
        name: "onclick",
        action: "click",
        type: "owl dot",
        region: "main content",
        section: "hear from other starbucks partner",
        text: this.testimonials[event.currentSlide].name.toLocaleLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
  },
};
</script>

<style lang="scss" scoped>
.success-graphic {
  &:before {
    position: absolute;
    width: 100%;
    height: 107%;
    display: block;
    top: -3rem;
    background-size: contain;
    content: "";
    left: 5.7rem;
    z-index: 0;
    background-repeat: no-repeat;
    background-image: url("../assets/images/success_long-graphic.svg");
  }
  @media screen and(max-width: 1200px) and (min-width: 991px) {
    &:before {
      height: 98%;
      left: 4.8rem;
    }
  }
}
.success-image-container {
  margin: 30px auto;
}

.success-content {
  position: relative;
  z-index: 10;
}

// .success-image-container {
//   .success-image {
//     position: relative;
//     margin-top: 3rem;
//     z-index: 2;
//   }
//   display: block;
//   margin-left: auto;
//   &--1:before {
//     background: #006241;
//   }
//   &--2:before {
//     background: #839624;
//   }
//   &--3:before {
//     background: #ffbb00;
//   }
//   &--4:before {
//     background: #ffbb00;
//   }
//   &--5:before {
//     background: #c66c1f;
//   }
//   &--5:after {
//     position: absolute;
//     width: 10px;
//     height: 100px;
//     display: block;
//     content: "";
//     left: 50%;
//     bottom: -3rem;
//     z-index: 0;
//     margin-left: -1px;
//     background: #8c1d40;
//   }
// }
// .success-image-container:before {
//   position: absolute;
//   width: 10px;
//   height: 100px;
//   display: block;
//   content: "";
//   left: 50%;
//   top: -3rem;
//   z-index: 0;
//   margin-left: -1px;
// }
.line-height-fix {
  @media only screen and (min-width: 991px) {
    line-height: 35px;
  }
}
</style>
