<template>
  <a href="javascript:void(0)" class="col-lg-4 col-md-6 service-links pt-lg-0">
    <div class="bg-dark-green common-height pr-lg-40 pl-lg-40 px-lg-4 mb-4 d-flex flex-column p-4 text-white pt-lg-40">
      <span v-html="image" style="height:33px;" class="mr-auto mb-32"></span>
      <h3 class="font-24 pb-24 font-lander-sBold mb-0">
        {{ heading }}
      </h3>
      <p class="stb-light font-16">{{ text }}</p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    image: String,
    text: String,
    heading: String,
    link: String
  },
  methods: {
    getImg(img) {
      return require(`../assets/images/${img}`);
    }
  }
};
</script>

<style lang="scss">
.common-height {
    min-height: 350px;
}
.bg-dark-green{
  border: 1px solid transparent;
        }
.service-links{
    &:hover{
        text-decoration: none;
        .bg-dark-green{
            background-color: white !important;
            color: #29413B !important;
            border-color: #29413B !important;
           transition: all 0.3s ease;
           svg{
             .a,.b,.c {
               stroke: #29413B;
             }
           }
        }
    }
}
</style>