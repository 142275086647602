import axios from 'axios'

export default {
    getPrograms() {
        return new Promise((resolve, reject) => {
            axios.get('https://cms.asuonline.asu.edu/lead-submissions-v3.4/programs?category=undergraduate',{
              'Accept':'text/json'
            })
              .then(function (response) {
                  //console.log(response)
                resolve(response.data);
              })
              .catch(function (error) {
                console.log(error);
                reject({
                    details: "failed to get the content",
                    errCode: "STARB001"
                })
              });
        })
       
         
    }
}