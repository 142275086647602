<template>
  <div class="px-lg-3 mx-auto col-lg-12 mt-5 mt-lg-70 px-lg-5">
    <div class="offset-pathway" id="brand-testimonial">
      <BaseOffsetImg justify="left" vCenter>
        <template #image>
          <div class="bg-dark-green pathway-left col-lg-6 rounded">
            <h2
              class="font-fam-Georgia font-lg-46 font-30 text-white mb-lg-0 mb-32"
            >
              Embark on your <br class="d-lg-none" />
              <span class="underline-special-pathway"
                >pathway to admission</span
              >
            </h2>
            <p class="stb-light font-16 text-white col-md-9 px-0">
              Need a fresh start? No problem. Earn your admission to ASU.
            </p>
          </div>
        </template>
        <template #content>
          <img
            src="../assets/images/pathway-top-desktop.svg"
            alt="pathway-bottom-desktop"
            class="pathway-top position-absolute d-none d-xl-block"
          />
          <div
            class="col-lg-6 col-11 mx-auto px-0 mx-lg-0 adjustment-col-to-left pathway-1"
          >
            <div class="content-header pathway-right col-md-10 bg-white">
              <p class="font-14 stb-light">
                ASU has certain admission requirements like any university. Are
                you worried about your past GPA or academic eligibility? If so,
                the Pathway to Admission program can help. Upon completion, you
                can earn admission and join the Starbucks College Achievement
                Plan.
              </p>
            </div>
          </div>
        </template>
      </BaseOffsetImg>
    </div>
    <div class="about-pathway-admission container pt-lg-100">
      <BaseOffsetImg justify="right" vCenter>
        <template #image>
          <div
            :style="`background-image: url(${require('../assets/images/pathway-scap-img.png')});`"
            class="backround-cover col-lg-6 d-none d-lg-block"
          ></div>
        </template>
        <template #content>
          <div class="col-lg-6 col-11 mx-auto px-0 mx-lg-0 bg-white">
            <div class="mx-auto text-center">
              <img
                src="../assets/images/pathway-testimonial-desktop-border.svg"
                alt="pathway-bottom-desktop"
                class="d-block d-lg-none mx-auto"
              />
            </div>
            <div
              class="content-header py-4 pr-lg-5 pathway-2 bg-white position-relative z-top"
            >
              <h3
                class="font-fam-Georgia font-lg-36 font-22 pb-24 text-lg-left text-center"
              >
                About Pathway to Admission
              </h3>
              <ul class="pl-2">
                <li
                  class="mb-10 offset-persona--list list-bullets list-type-none"
                  v-for="(list, i) in lists"
                  :key="i"
                >
                  {{ list }}
                </li>
              </ul>
            </div>
          </div>
          <img
            src="../assets/images/pathway-bottom-desktop.svg"
            alt="pathway-bottom-desktop"
            class="pathway-bottom position-absolute d-none d-xl-block"
          />
        </template>
      </BaseOffsetImg>
    </div>
    <div
      class="col-md-12 col-11 mx-auto px-0 pt-lg-100 mt-lg-50 text-center pb-62 pb-lg-0 pathway-3"
    >
      <div class="mx-auto text-center d-block d-lg-none mb-4">
        <img
          src="../assets/images/pathway-testimonial-desktop-border.svg"
          alt="pathway-bottom-desktop"
          class="mx-auto"
        />
      </div>
      <h3 class="font-lg-36 font-fam-Georgia">
        Get started with Pathway to Admission
      </h3>
      <p
        class="font-lg-16 font-lg-16 font-14 col-lg-9 px-0 mx-auto pb-lg-28 pb-30"
      >
        Every partner’s situation is unique. Many partners find they're
        qualified for ASU admission after the application process. You must
        submit your ASU Starbucks College Achievement Plan application to be
        eligible for Pathway to Admission.
      </p>
      <BaseGtmLink
        tag="button"
        @click="$store.state.applyNow = 'modal'"
        class="btn font-14 bg-brand-gold stb-sBold mx-auto btn-block col-md-2 col-5"
        round
        v-b-modal.modal-1
        :gtm="{
          event: 'modal',
          name: 'onclick',
          action: 'open',
          type: 'click',
          region: 'main content',
          section: 'get started with pathway to admission',
          text: 'apply now',
        }"
        >Apply now</BaseGtmLink
      >
    </div>
    <div class="student-testiminials pt-lg-5 pt-32">
      <div class="container">
        <div class="mx-auto text-center">
          <img
            src="../assets/images/pathway-testimonial-desktop-border.svg"
            alt="pathway-bottom-desktop"
          />
        </div>

        <div class="pt-lg-5 pt-32">
          <div class="row">
            <div class="col-12 text-center">
              <h3 class="font-fam-Georgia font-lg-36 font-22 pb-24">
                Starbucks student reviews of
                <span class="underline-special-pathway-testimonial"
                  >Pathway to Admission</span
                >
              </h3>
            </div>
          </div>
          <div class="testimonial" v-if="isShowing">
            <div class="row">
              <div class="col-12">
                <TestimonialPathways></TestimonialPathways>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestimonialPathways from "./TestimonialPathways.vue";
export default {
  components: {
    TestimonialPathways,
  },
  mounted() {
    const heroSection = document.querySelector("#brand-testimonial");
    const options = {};
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isShowing = new Date().getMilliseconds();
          console.log("true intersection");
        }
      });
    }, options);
    observer.observe(heroSection);
    window.addEventListener("resize", () => {
      this.isShowing = new Date().getMilliseconds();
    });
  },
  data() {
    return {
      isShowing: "",
      lists: [
        "You can receive credit conversion coverage for up to 10 courses in the program.",
        "Generally, partners take 4-8 courses (12-24 credits) to be eligible for ASU admission.",
        "There is no cost to enroll in credit eligible courses through the program.",
        "Once you pass a course, the cost to convert the course to credit is covered through the program.",
        "You must have at least a 2.75 GPA in the Pathway to Admission program for ASU admission.",
        "There are no costs for books or class materials.",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.adjustment-col-to-left {
  @media only screen and (max-width: 991px) {
    position: unset !important;
    margin-top: -7%;
  }
  left: -4%;
  position: relative;
  z-index: 9;
}
.adjustment-col-to-right {
  position: relative;
  z-index: 9;
  right: -2%;
  @media only screen and (max-width: 991px) {
    position: unset !important;
    right: 0;
    padding-top: 100px;
  }
}
.about-pathway-admission {
  .backround-cover {
    position: relative;
    left: -10%;
    height: 643px;
  }
}

.underline-special-pathway {
  background-image: url("../assets/images/pathway-scribble.svg");
  background-position: center 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  @media only screen and (max-width: 991px) {
    line-height: 1.9px;
    background-position: center 1.5rem;
  }
}

.underline-special-pathway-testimonial {
  background-image: url("../assets/images/pathway-testimonial-scribble.svg");
  background-position: center 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  @media only screen and (max-width: 991px) {
    line-height: 1.9px;
    background-position: center 0.8rem;
  }
}

.pathway-top {
  bottom: -12rem;
  left: 15%;
}
.pathway-bottom {
  bottom: -7rem;
  left: 13rem;
}
@media only screen and (max-width: 991px) {
  .pathway-border-mobile {
    &::before {
      content: "";
      position: absolute;
      border-left: 2px dashed #ffc627;
      height: 100px;
      top: 0rem;
      left: 50%;
    }
    &::after {
      content: "";
      position: absolute;
      border-left: 2px dashed #ffc627;
      height: 100px;
      bottom: -5rem;
      left: 50%;
    }
  }
}
</style>
