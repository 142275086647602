<template>
  <div :class="page">
    <b-form-group>
      <b-form-radio
        v-model="$store.state.partner_selected"
        name="some-radios"
        value="yes"
        class="mb-3"
        @change="triggerFormGTM('yes')"
        >Yes, I am a Starbucks partner.</b-form-radio
      >
      <div
        class="ml-3 my-2"
        v-show="$store.state.partner_selected === 'yes'"
        :class="{ 'col-md-6': page === 'rfi' }"
      >
        <validation-provider
          v-slot="validationContext"
          name="partner number"
          rules="required|digits:8"
        >
          <b-form-group
            id="input-group-subx"
            label="Partner number"
            label-for="id-input-subx"
            class="font-label pb-space-xs"
            ><div class="input-group d-flex align-items-center">
              <div
                :class="{
                  'input-group-prepend': true,
                  'is-invalid':
                    validationContext.errors[0] ||
                    $store.state.rfi_subx_id_error,
                }"
              >
                <span
                  class="input-group-text fam-roboto font-black border-right-0"
                  id="inputGroupPrepend2"
                  >US -</span
                >
              </div>
              <b-form-input
                id="id-input-subx"
                v-model="$store.state.subx_id"
                placeholder="00000000"
                name="name-input-subx"
                class="border-left-0 mr-1"
                :style="
                  $store.state.subx_id_error &
                  ($store.state.subx_id.length === 8)
                    ? 'border-color:#dc3545 !important'
                    : ''
                "
                :state="getValidationState(validationContext)"
                aria-describedby="input-subx-live-feedback"
                @input="triggerFormGTM('partner number', 'partner')"
              ></b-form-input>
              <img
                id="popover-4"
                src="../assets/images/Icon - question-circle-solid.svg"
                alt="Icon - question-circle-solid.svg"
              />
              <b-popover
                target="popover-4"
                :triggers="tooltipTrigger"
                placement="top"
              >
                Don’t know your partner number? Ask your manager or call the
                Partner Contact Center (PCC) at
                <a
                  class="text-brand-gold"
                  @click="gtmTrigger()"
                  href="tel:888-728-9411"
                  >888-728-9411</a
                >
                for assistance.
              </b-popover>
            </div>

            <b-form-invalid-feedback
              id="input-subx-live-feedback"
              v-if="validationContext.errors"
            >
              <div class="mb-space-lg">{{ validationContext.errors[0] }}</div>
            </b-form-invalid-feedback>
            <span
              v-else-if="$store.state.subx_id_error"
              class="is-invalid text-danger d-table width-changes invalid-feedback"
              >Invalid partner number</span
            >
          </b-form-group>
        </validation-provider>
      </div>
      <b-form-radio
        @change="triggerFormGTM('no')"
        v-model="$store.state.partner_selected"
        name="some-radios"
        value="no"
        class="mt-3"
        >No, I am not a Starbucks partner.</b-form-radio
      >
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "partnersCheck",
  data() {
    return { tooltipTrigger: "" };
  },
  components: {
    "validation-provider": ValidationProvider,
  },
  props: {
    page: String,
  },
  methods: {
    gtmTrigger() {
      let dl = {
        event: "link",
        name: "onclick",
        action: "click",
        type: "external link",
        region: "modal",
        section: "ready to apply ^ question mark",
        text: "866-504-7368",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    triggerFormGTM(_text, partner = null) {
      let dl = {
        event: partner ? "form" : "select",
        name: "onclick",
        action: "click",
        type: partner ? "click" : "checkbox",
        region: "main content",
        section: "ready to apply?",
        text: partner
          ? _text
          : _text === "yes"
          ? "yes, i am a starbucks partner"
          : "no, i am not a starbucks partner",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    popoverTrigger() {
      if (window.innerWidth <= 992) this.tooltipTrigger = "click";
      else this.tooltipTrigger = "hover";
    },
  },
  mounted() {
    this.popoverTrigger();
    window.addEventListener("resize", this.popoverTrigger);
  },
};
</script>

<style lang="scss">
.width-changes {
  @media only screen and (min-width: 991px) {
    max-width: 15rem;
  }
}
</style>
