<template>
  <div class="bg-gold padding-fix">
    <div class="container px-lg-0 px-2 margin-fix">
      <p class="font-14 text-black text-center text-lg-left my-2 fam-roboto">
        <span class="font-black">Work for Starbucks? </span>Discover how the program works at our next webinar on
        <span class="font-black"
          ><b-icon
            icon="table"
            scale="1.2"
            variant="black"
            style="width: 20px"
            class="mx-2 font-black"
          ></b-icon>
          <span class="font-black">
            {{ $store.state.openHouse.day }} from
            {{ $store.state.openHouse.time }}.
            <BaseGtmLink
              tag="a"
              :href="openHouseLink"
              class="text-underline mx-1 font-black text-black"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type: 'external link',
                region: 'main content',
                section: 'navbar callout',
                text: 'register now!',
              }"
            >
              Register now!
            </BaseGtmLink></span
          ></span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallOutSection",
  props: {
    openHouseLink: String,
  },
};
</script>

<style lang="scss" scoped>
.padding-fix {
  padding: 10px;
}
</style>
