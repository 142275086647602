<template>
  <BaseSbuxLayout>
    <div id="degree-search">
      <hero
        class="bg-dark-green text-white"
        header="Explore ASU degrees for Starbucks partners"
        subheader="Browse our top-ranked online degrees you can earn with the Starbucks College Achievement Plan"
        image="hero-degree.svg"
        page="degree"
      />
      <div class="play-a-game container pt-32 pb-32 d-none d-lg-block pr-lg-0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="col- d-inline-flex align-items-center">
            <img
              src="../assets/images/me3_logo_black.png"
              alt="me 3 logo"
              style="width: 2rem"
              class="mr-10"
            />
            <p class="m-0 p-0">
              Not sure?
              <BaseGtmLink
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://me3.careers/asu/landing?reg_source=StarbucksCAP"
                class="text-dark-green text-underline btn-link stb-sBold"
                :gtm="{
                  event: 'link',
                  name: 'onclick',
                  action: 'click',
                  type: 'external link',
                  region: 'main content',
                  section: 'explore asu degrees for starbucks partners',
                  text: 'play the game',
                }"
              >
                Play the game
              </BaseGtmLink>
              to find what's right for you.
            </p>
          </div>
          <div class="col-">
            <BaseGtmLink
              tag="button"
              class="btn btn-link text-light-green text-underline stb-sBold"
              v-b-modal.start-date
              :gtm="{
                event: 'modal',
                name: 'onclick',
                action: 'open',
                type: 'click',
                region: 'main content',
                section: 'explore programs',
                text: 'view all start dates',
              }"
            >
              View all start dates
              <b-icon
                icon="table"
                scale="1"
                variant="dark"
                style="width: 15px"
                class="ml-2"
              ></b-icon>
            </BaseGtmLink>
          </div>
        </div>
      </div>
      <div class="algolia-implementaion container">
        <ais-instant-search :search-client="searchClient" index-name="ug_phat">
          <ais-hits-per-page
            class="d-none"
            :items="[{ label: '8 hits per page', value: 1000, default: true }]"
          />
          <div class="row">
            <div class="col-xl-3 col-lg-4 pr-lg-0">
              <algolia-filters></algolia-filters>
            </div>
            <div
              id="algolia-cards-container"
              class="my-3 col-xl-9 col-lg-8 mx-auto"
            >
              <div
                class="
                  col-
                  d-inline-flex
                  align-items-center
                  d-lg-none
                  py-3
                  pb-40
                "
              >
                <img
                  src="../assets/images/me3_logo_black.png"
                  alt="me 3 logo"
                  style="width: 2rem"
                  class="mr-10"
                />
                <p class="m-0 p-0">
                  Not sure?
                  <BaseGtmLink
                    tag="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://me3.careers/asu/landing?reg_source=StarbucksCAP"
                    class="text-dark-green text-underline btn-link stb-sBold"
                    :gtm="{
                      event: 'link',
                      name: 'onclick',
                      action: 'click',
                      type: 'internal link',
                      region: 'main content',
                      section: 'explore programs',
                      text: 'play the game',
                    }"
                  >
                    Play the game
                  </BaseGtmLink>
                  to find what's right for you.
                </p>
              </div>
              <ais-hits>
                <div slot-scope="{ items }" class="row">
                  <BaseGtmLink
                    tag="a"
                    href="javascript:void(0)"
                    class="col-lg-5 col-xl-4 col-md-6 mb-22"
                    v-for="(item, i) in items"
                    @click="clickOnCard(item)"
                    :gtm="{
                      event: 'modal',
                      name: 'onclick',
                      action: 'open',
                      type: 'click',
                      region: 'main content',
                      section: 'explore programs',
                      text: item.title.toLowerCase(),
                    }"
                    :key="i"
                    card
                  >
                    <degree-cards :item="item"></degree-cards>
                  </BaseGtmLink>
                </div>
              </ais-hits>
            </div>
          </div>
        </ais-instant-search>
      </div>

      <div v-if="showSideNav">
        <side-nav
          :showDesktopCourseDetails="showDesktopCourseDetails"
        ></side-nav>
      </div>
      <b-modal id="start-date" size="xl" hide-footer class="container">
        <template v-slot:modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <BaseGtmLink
            tag="a"
            href="javascript:void(0)"
            class="close-apply text-dark font-36 ml-auto border-none"
            @click="close"
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'close',
              type: 'click',
              region: 'main content',
              section: 'your academic journey start here',
              text: 'close cross',
            }"
          >
            <img src="../assets/images/Icon-close.svg" alt="close" />
          </BaseGtmLink>
        </template>
        <div class="container">
          <div
            class="
              row
              align-items-center
              justify-content-center
              mx-auto
              pb-lg-60 pb-30
              pt-lg-6
            "
          >
            <div class="col-md-12">
              <b-table :items="items" :fields="fields_values"></b-table>
            </div>
            <div class="col-md-12">
              <h3 class="font-fam-Georgia font-36 pb-36">
                Your academic journey starts here
              </h3>
              <p class="font-16">
                ASU Online has six start dates per year. Regardless of when you
                plan to start, we recommend completing your application now.
              </p>
              <p class="font-16">
                It takes about four weeks for an admission decision, but
                processing times may vary depending on the volume of student
                applications and how your transcripts are sent to ASU. Starting
                your application at least a month in advance may help ensure you
                start on the day you want.
              </p>
              <div class="mx-auto d-table pt-3">
                <BaseGtmLink
                  tag="button"
                  class="btn font-16 bg-brand-gold stb-sBold"
                  round
                  @click="$store.state.applyNow = 'modal'"
                  v-b-modal.modal-1
                  :gtm="{
                    event: 'modal',
                    name: 'onclick',
                    action: 'open',
                    type: 'click',
                    region: 'main content',
                    section: 'your academic journey starts here',
                    text: 'apply now',
                  }"
                  >Apply now</BaseGtmLink
                >
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <SectionApplyNow
        locale="degreeSearch"
        :applynowurl="this.getUrl().url"
        :session="this.getUrl().session"
        :programPlanAndSubplan="`&program=${showDesktopCourseDetails.programCode}&plan=${showDesktopCourseDetails.planCode}&subplan=${showDesktopCourseDetails.subPlanCode}`"
      />
    </div>
  </BaseSbuxLayout>
</template>
<script>
import algoliasearch from "algoliasearch/lite";
import algoliasearchHelper from "algoliasearch-helper";
import SectionApplyNow from "@/components/SectionApplyNow";
import DegreeCards from "@/components/degree-cards.vue";
import hero from "@/components/hero-degree.vue";
import AlgoliaFilters from "@/components/algolia-filters.vue";
import $ from "jquery";
import SideNav from "../components/SideNav.vue";
export default {
  data() {
    return {
      searchClient: algoliasearch(
        "MLXAAABPA9",
        "fa13eba25dbdd8dcbafed650778bc03e"
      ),
      showSideNav: false,
      showDesktopCourseDetails: {
        applyNowUrl: "https://webapp4.asu.edu/uga_admissionsapp/?partner=SCAP",
      },
      fields_values: [
        "Semester",
        "Start_date_session_A",
        "Start_date_session_B",
      ],
      items: [
        {
          Semester: "Spring 2025",
          Start_date_session_A: "January 13, 2025",
          Start_date_session_B: "March 17, 2025",
        },
        {
          Semester: "Summer 2025",
          Start_date_session_A: "May 19, 2025",
          Start_date_session_B: "July 2, 2025",
        },
        {
          Semester: "Fall 2024",
          Start_date_session_A: "August 22, 2024",
          Start_date_session_B: "October 16, 2024",
        },
      ],
    };
  },
  metaInfo: {
    title: "ASU Degrees for Starbucks Partners",
    titleTemplate: "%s | ASU Starbucks",
    htmlAttrs: {
      page: "support",
    },
    link: [
      {
        rel: "canonical",
        href: `${window.location.origin}/starbucks-asu-degree-list`,
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "What degrees can I get with the Starbucks College Achievement Plan? Browse the 100+ bachelor’s degree  you can earn online.",
      },
      {
        name: "robots",
        content: "nofollow,noindex",
      },
    ],
  },
   mounted(){
    if(this.$route.query.code){
     this.backendAlgoliaSearch(this.$route.query.code)
    }
   
  },
  methods: {
    triggeredShowSideNav(val){
      this.showSideNav = val
    },
     backendAlgoliaSearch(code){
       console.log(code)
      const self = this
      const helper = algoliasearchHelper(this.searchClient, "ug_phat",{
        hitsPerPage: 1000,
      })
     helper.on("result", function (content) {
       if(content.results.hits.length > 0){
           const foundProg = content.results.hits.find(result => result.code.toLocaleLowerCase() === code.toLocaleLowerCase())
           if(foundProg){
             self.showDesktopCourseDetails = foundProg
             self.showSideNav=true
             self.$store.state.isShowSideNav = true
           }
       }
           
      })
      helper.search();

    },
    getUrl() {
      let applyNow = {
        host: "",
        term: "",
        session: "",
        campus: "",
      };
      let applyNowURL = new URL(this.showDesktopCourseDetails.applyNowUrl);
      applyNow.host = applyNowURL.hostname;
      applyNow.session = applyNowURL.searchParams.get("session");
      applyNow.term = applyNowURL.searchParams.get("term");
      applyNow.campus = applyNowURL.searchParams.get("campus");
      return {
        url: `https://${applyNowURL.hostname}/uga_admissionsapp/?partner=SCAP`,
        session: `&term=${applyNow.term}&campus=${applyNow.campus}&session=${applyNow.session}`,
      };
    },
    scrollToTop() {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#algolia-cards-container").offset().top,
        },
        1000
      );
    },
    clickOutside(isTriggerGtm = "notrigger") {
      // alert("trigger")
      this.showSideNav = !this.showSideNav;
      let dl = {
        event: "modal",
        name: "onclick",
        action: "close",
        type: "click",
        region: "main content",
        section: this.showDesktopCourseDetails.title.toLocaleLowerCase(),
        text: "back",
      };
      if (isTriggerGtm === "trigger") {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      }
    },
    insertUrlParam(key, value) {
    if (history.pushState) {
        let searchParams = new URLSearchParams( );
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }
},
    clickOnCard(item) {
      this.insertUrlParam("code",item.code)
      this.showSideNav = true;
      this.$store.state.isShowSideNav = true
      this.showDesktopCourseDetails = item;
    },
  },
  components: {
    hero,
    AlgoliaFilters,
    DegreeCards,
    SideNav,
    SectionApplyNow,
  },
};
</script>

<style lang="scss">
.ais-Pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  .ais-Pagination--link {
    height: 50px;
    width: 42px;
    background: white;
    margin: 8px;
    border: 1px solid #29413b;
    text-align: center;
    line-height: 39px;
    border-radius: 4px;
    color: #29413b;
  }
  .ais-Pagination-item--selected {
    background: #29413b !important;
    color: white !important;
  }
}
#mySidenav {
  .width-opaque {
    width: 100%;
    background: white;
    @media only screen and (min-width: 991px) {
      width: 45%;
      height: 100vh;
      box-shadow: -4px 3px 7px 0px rgba(186, 186, 186, 1);
    }
    @media only screen and (max-width: 1200px) and (min-width: 991px) {
      width: 60%;
    }
    height: 100%;
    right: 0;
    position: fixed;
    top: 0;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  right: 0;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.596);
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
}
#start-date___BV_modal_body_ {
  max-width: 88.33%;
  margin: 0 auto;
  padding-bottom: 2.5rem;
}
.table {
  margin-bottom: 0 !important;
  font-size: 14px;
  th {
    border-top: none !important;
  }
}
#apply-now-modal___BV_modal_content_ {
  min-height: 80vh;
  border-top: 10px solid #006340;
}
</style>
