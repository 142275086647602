<template>
  <BaseOffsetImg :justify="justify">
    <template #image>
      <div
        :style="`background-image: url(${image}); background-position: center;`"
        class="backround-cover col-lg-5 position-relative z-medium"
        :class="[
          [
            justify === 'right'
              ? 'content-offset--left'
              : 'content-offset--right',
          ],
          [section],
        ]"
      >
        <img
          v-if="section === 'how-will-i-fund'"
          src="@/assets/images/personalize/Repeat Grid 10(1).svg"
          alt="Repeat grid"
          class="position-absolute d-lg-none image-fluid"
          style="top: -2rem; right: 0; max-width: 10rem"
        />
      </div>
    </template>
    <template #content>
      <div class="col-lg-7 negative-top pt-lg-48">
        <div
          class="content-offset pb-lg-48 bg-white z-top bg-white pt-24 pt-lg-48"
          :class="[
            justify === 'right'
              ? 'pr-lg-3 content-offset--right'
              : 'pl-lg-48 content-offset--left',
          ]"
        >
          <div class="col-11 mx-auto col-lg-12 pl-lg-0">
            <h3
              v-html="heading"
              class="font-lg-36 px-0 font-24 mb-32 mb-lg-0 pr-1 py-1 mx-lg-0 pt-lg-0 d-lg-table d-inline font-black mb-lg-32 pl-0"
            ></h3>
          </div>

          <numbered-list
            :lists="lists"
            class="col-lg-10 col-11 px-0 col-lg-12 ml-auto mt-32 mt-lg-0 mr-auto mr-lg-0 pr-lg-40 pl-40 px-lg-0 negative-top-numberd pt-0"
            style=""
          />
        </div>
      </div>
    </template>
  </BaseOffsetImg>
</template>

<script>
import NumberedList from "./numbered-list";

export default {
  props: {
    justify: String,
    lists: Array,
    heading: String,
    image: String,
    section: {
      default: "none",
    },
  },
  components: {
    NumberedList,
  },
};
</script>

<style lang="scss">
.how-will-i-fund {
  @media only screen and (max-width: 991px) {
    background-image: url("../../assets/images/personalize/Coffee-mobile.png") !important;
    background-position: unset !important;
  }
}
</style>
