<template>
  <b-sidebar
    id="sidebar-backdrop"
    no-header
    v-model="$store.state.isShowSideNav"
    title="Sidebar with backdrop"
    backdrop
    class="bg-white"
    :backdrop-variant="variant"
    right
    shadow
    @change="toggleBodyScrollbar"
  >
    <template #default="{ hide }">
      <div class="width-opaque">
        <div
          class="d-lg-none position-fixed w-100 z-99 bg-white"
          style="border-bottom: 2px solid #29413b; top: 0"
        >
          <div class="d-flex justify-content-between align-items-center">
            <!-- Backbutton desktop -->

            <div
              class="col-6"
              style="cursor: pointer"
              @click="hideNavBar(showDesktopCourseDetails.title)"
            >
              <p
                @click="hide"
                class="
                  font-16
                  stb-sBold
                  m-0
                  pl-22
                  pt-21
                  pb-21
                  overflow-elapses
                  pl-3
                "
              >
                <img
                  src="../assets/images/icon-left.svg"
                  alt="icon left"
                  class="pr-3"
                />
                <!-- {{ showDesktopCourseDetails.title }} -->
                Back
              </p>
            </div>
            <div class="col-6">
              <BaseGtmLink
                tag="button"
                class="
                  btn
                  font-14
                  apply-now-button
                  stb-sBold
                  bg-brand-gold
                  text-black-1
                  float-right
                "
                round
                v-b-modal.apply-now-modal
                :gtm="{
                  event: 'modal',
                  name: 'onclick',
                  action: 'open',
                  type: 'click',
                  region: 'navbar',
                  section: showDesktopCourseDetails.title.toLowerCase(),
                  text: 'apply now',
                }"
                >Apply now</BaseGtmLink
              >
            </div>
          </div>
        </div>
        <div
          class="modal-card__header degree-header mt-5 mt-lg-0"
          :style="`background-image: url(${showDesktopCourseDetails.degreeImage});`"
        >
          <div class="d-flex justify-content-between p-4">
            <!-- Back button -->
            <div
              class="back-button"
              @click="hideNavBar(showDesktopCourseDetails.title)"
            >
              <a
                href="javascript:void(0)"
                @click="hide"
                class="text-white px-2 align-items-center d-none d-lg-flex"
              >
                <img
                  src="../assets/images/Icon-chevronleft-white.svg"
                  alt="Icon-chevronleft-white"
                  class="mr-3"
                  width="10"
                  height="15"
                /><span class="font-lg-18">Back</span></a
              >
            </div>
            <div class="apply-now-link">
              <BaseGtmLink
                tag="button"
                class="
                  btn
                  font-14
                  apply-now-button
                  stb-sBold
                  d-none d-lg-block
                  bg-brand-gold
                  text-black-1
                "
                round
                v-b-modal.apply-now-modal
                :gtm="{
                  event: 'modal',
                  name: 'onclick',
                  action: 'open',
                  type: 'click',
                  region: 'main content',
                  section: showDesktopCourseDetails.title.toLowerCase(),
                  text: 'apply now',
                }"
                >Apply now</BaseGtmLink
              >
            </div>
          </div>
        </div>

        <div class="col-md-10 col-11 mx-auto">
          <div class="algolia-card__type" style="margin-top: -10px">
            <span
              class="
                type
                bg-dark-green
                stb-sBold
                text-white
                p-2
                font-lg-18 font-12
              "
            >
              Degree
            </span>
          </div>
          <div class="title mt-30">
            <h2 class="font-lg-36 font-24 fam-roboto font-black">
              {{ showDesktopCourseDetails.title }}
            </h2>
          </div>
          <hr class="mt-lg-32" />
          <div class="row">
            <div class="col-7">
              <p class="font-lg-18 font-12 mb-0">
                <b-icon
                  icon="table"
                  scale="0.7"
                  variant="dark"
                  style="width: 15px"
                ></b-icon>
                <span class="d-lg-inline d-none">Next </span>Start date:
                <span class="fam-roboto font-weight-bold">
                  {{
                    nextStartDateSplit(showDesktopCourseDetails.nextStartDate)
                  }}</span
                >
              </p>
            </div>
            <div class="col-5">
              <p class="font-lg-18 font-12 mb-0">
                <img
                  src="../assets/images/check-circle-solid.svg"
                  style="width: 0.75em"
                  alt="check-circle-solid.svg"
                />
                Total classes:
                <span class="fam-roboto font-weight-bold">{{
                  showDesktopCourseDetails.totalClasses
                }}</span>
              </p>
            </div>
            <div class="col-6" v-if="!showDesktopCourseDetails.nextStartDate">
              <p class="font-lg-18 font-12 mb-0">
                <b-icon
                  icon="table"
                  scale="0.7"
                  variant="dark"
                  style="width: 15px"
                ></b-icon>
                Fast Track Eligible:<img
                  src="../assets/images/Icon - check-solid.svg"
                  alt="check-circle-solid.svg"
                  style="width: 0.75em"
                />
              </p>
            </div>
            <div class="col-6">
              <p class="font-lg-18 font-12 mb-0">
                <img
                  src="../assets/images/check-circle-solid.svg"
                  alt="check-circle-solid.svg"
                  style="width: 0.75em"
                />
                Weeks per class:
                <span class="fam-roboto font-weight-bold">
                  {{ showDesktopCourseDetails.weeksPerClass }}</span
                >
              </p>
            </div>
          </div>
          <hr class="mb-lg-32" />
          <div class="description">
            <h2 class="font-weight-bold font-lg-21 font-21 mb-3 fam-roboto">
              Description
            </h2>
            <p v-html="showDesktopCourseDetails.shortDescription"></p>
          </div>
          <!-- featured courses -->
          <featured-courses
            v-if="showDesktopCourseDetails.featuredCourses.items.length > 0"
            :featured="showDesktopCourseDetails.featuredCourses"
          ></featured-courses>
        </div>
        <!-- Accolades -->
        <div class="bg-dark-green">
          <accolades
            class="col-md-10 col-11 mx-auto"
            :accolades="showDesktopCourseDetails.accolades"
          >
          </accolades>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import featuredCourses from "./featured-courses.vue";
import accolades from "./accolades.vue";
export default {
  data() {
    return {
      variant: "white"
    };
  },
  components: { featuredCourses, accolades },
  
  props: {
    showDesktopCourseDetails: Object,
    isShow: Boolean
  },
  methods: {
    nextStartDateSplit(splitble) {
      let arr = splitble.split("-");
      return `${arr[1]}/${arr[2]}/${arr[0]}`;
    },
    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName("body")[0];
      if (visible) body.classList.add("overflow-hidden");
      else body.classList.remove("overflow-hidden");
    },
    hideNavBar(title) {
      let dl = {
        event: "modal",
        name: "onclick",
        action: "close",
        type: "click",
        region: "main content",
        section: title.toLowerCase(),
        text: "back",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
      window.history.pushState({}, document.title, window.location.pathname);
    },
  },
};
</script>

<style lang="scss">
#sidebar-backdrop {
  width: 45vw;
  @media only screen and (max-width: 991px) {
    width: 100vw;
  }
}
.h2-small,
.small-h2 {
  font-size: 24px;
  font-weight: 700;
  @media only screen and (max-width: 991px) {
    top: 18px !important;
  }
}
@media only screen and (max-width: 767px) {
  #sidebar-backdrop {
    width: 100%;
    height: 100%;
  }
}
</style>

