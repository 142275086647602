var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"persona-page",attrs:{"id":`${_vm.$route.name}`}},[_c('navbar'),_c('section',{staticClass:"bg-dark-green",style:('background-image:url(' +
        require('../assets/images/personalize/' + _vm.pageData.hero.bgImage) +
        ');background-position: right;'),attrs:{"id":"persona-new-hero"}},[_c('div',{staticClass:"w-100",staticStyle:{"background":"transparent linear-gradient(180deg, #19191900 0%, #19191900 3%, #19191940 39%, #19191980 67%, #000000CC 100%) 0% 0% no-repeat padding-box"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-lg-9 pl-lg-0 position-relative z-top"},[_c('h1',{staticClass:"font-36 font-lg-56 font-weight-bold padding-fix mb-0 text-white pb-lg-48 pb-24"},[_vm._v(" "+_vm._s(_vm.pageData.hero.header)+" ")])])])])]),_c('section',{staticClass:"pt-48 pb-48 pt-lg-96 pb-lg-96 bg-grey",attrs:{"id":"section-overview"}},[_c('div',{staticClass:"container pl-0 pr-0 pr-lg-15 pl-lg-15 ob"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-6 description-img image-side-left overview",style:('background-image:url(' +
              require('../assets/images/personalize/' +
                _vm.pageData.card.left.image) +
              ');')},[_c('div',{staticClass:"col-12 description-div pl-lg-32 pr-lg-32 pt-lg-48 pb-lg-48 pl-lg-48 pr-lg-48 mt-lg-48 mb-lg-48 mt-xxl-64 mb-xxl-64 bg-grey white-bg"},[_c('div',[_c('h2',{staticClass:"mb-24 font-24 font-weight-bold font-lg-36"},[_vm._v(" "+_vm._s(_vm.pageData.card.left.heading)+" ")]),_vm._l((_vm.pageData.card.left.lists),function(list){return _c('p',{key:list,staticClass:"mb-0"},[_vm._v(" "+_vm._s(list)+" ")])})],2)])])])])]),_c('section',{staticClass:"container mt-lg-96 mt-48 mb-24 mb-lg-72",attrs:{"id":"cardDegree-info"}},[_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"font-weight-bold m-0 mx-auto font-24 font-lg-36 pr-8 pl-8 pb-8 pt-8"},[_c('span',{staticClass:"highlight-text"},[_vm._v(_vm._s(_vm.pageData.cardData.header))])])]),_c('div',{staticClass:"row mt-48"},_vm._l((_vm.pageData.degreeInfo),function(degree){return _c('div',{key:degree.title,staticClass:"col-lg-4 mb-4 col-12"},[_c('CardDegreeInfo',{staticClass:"h-100",attrs:{"info":degree,"componentText":_vm.pageData.cardData.header}})],1)}),0),_c('div',{staticClass:"container px-lg-0 d-flex pt-24",attrs:{"id":"new-modal"}},[_c('span',[_vm._v("* "),_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.new-disclaimer",modifiers:{"new-disclaimer":true}}],staticClass:"disclaimer-text",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
            event: 'modal',
            name: 'onclick',
            action: 'open',
            type: 'click',
            region: 'main content',
            section: 'frequently asked questions',
            text: 'here',
          }}},[_vm._v(" View Disclaimer")])],1)])]),_c('section',{staticClass:"section-resources",attrs:{"id":"section-Resource"}},[_c('div',{staticClass:"container p-0"},[_c('img',{staticClass:"resource-image img-fluid description-image p-0",attrs:{"src":require(`../assets/images/personalize/${_vm.pageData.sectionResource.image}`),"alt":""}})]),_c('div',{staticClass:"resource-content bg-green-3 pb-lg-96 pb-48"},[_c('div',{staticClass:"container px-lg-0 text-white pt-48 pt-lg-64"},[_c('h1',{staticClass:"font-24 mb-12 font-weight-bold font-lg-36 m-0"},[_vm._v(" "+_vm._s(_vm.pageData.sectionResource.title)+" ")]),_c('p',{staticClass:"pt-12 font-16 m-0"},[_vm._v(_vm._s(_vm.pageData.sectionResource.text))]),_c('div',{staticClass:"row"},[_vm._l((_vm.cardInfo),function(grid,index){return [(grid.cta.path !== _vm.currentPage)?_c('div',{key:index,staticClass:"col-12 col-lg-4 mt-48"},[_c('b-card',{staticClass:"mb-lg-0 bg-green-3 border-light-4 h-100 p-0",attrs:{"no-body":"","href":grid.path}},[_c('b-card-body',{staticClass:"pt-32 pl-32 pr-32"},[_c('b-card-title',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(grid.title)}}),_c('b-card-text',{staticClass:"mt-24"},[_vm._v(" "+_vm._s(grid.text)+" ")])],1),_c('b-card-footer',{staticClass:"bg-green-3 border-0 pb-32 pr-32 pl-32"},[_c('a',{staticClass:"cta-color personlize-hover text-underline",attrs:{"href":grid.cta.path},on:{"click":function($event){return _vm.triggerCardClickDataLayer(grid.title)}}},[_vm._v(_vm._s(grid.cta.label))])])],1)],1):_vm._e()]})],2)])])]),_c('mainFooter'),_c('b-modal',{attrs:{"id":"new-disclaimer","size":"xl","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('BaseGtmLink',{staticClass:"ml-auto mt-24 mt-lg-4 border-none close-apply text-dark font-36",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
          event: 'modal',
          name: 'onclick',
          action: 'close',
          type: 'click',
          region: 'main content',
          section: 'submit your question to asu online',
          text: 'close',
        }},on:{"click":close}},[_c('img',{attrs:{"src":require("../assets/images/Icon-close.svg"),"alt":"close"}})])]}}])},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-left pb-16 disclaimer-title"},[_c('p',[_vm._v(_vm._s(_vm.pageData.disclaimerFinancial.title))])]),_c('div',{staticClass:"mb-5 font-12 text-left"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.pageData.disclaimerFinancial.text)}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }