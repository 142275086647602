<template>
  <section
    id="js-get-started"
    class="contact pt-48 pb-lg-64 mb-96 mb-lg-134 bg-contact more-contact position-relative"
  >
    <!-- <img src="../assets/images/dot-grid-top.svg" alt="dot grid top" class="position-absolute image-top"> -->
    <div class="row position-relative z-top grid-card">
      <div class="col-lg-4 mb-48" v-for="(contact, i) in content" :key="i">
        <div
          class="d-flex flex-column justify-content-center align-items-center text-center"
        >
          <div class="image-icons">
            <img :src="getimageURL(contact.image)" alt="degree-hat.svg" />
          </div>
          <h3
            class="font-black font-24 px-5 mt-24 mb-32 text-white grid-card__heading"
            v-html="contact.header"
          ></h3>
          <BaseGtmLink
            v-if="contact.action.label === 'Chat now'"
            :tag="contact.action.type"
            class="btn btn-gold rounded-pill font-18 font-weight-bold btn-lg grid-card__button"
            :href="
              contact.action.type === 'a'
                ? contact.action.url
                : 'javascript:void(0)'
            "
            @click="actionClick(contact.action)"
            :gtm="{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'what if i still have questions?',
              text: contact.action.label.toLocaleLowerCase(),
            }"
            >{{ chatNowText }}</BaseGtmLink
          >
          <BaseGtmLink
            :tag="contact.action.type"
            v-else
            class="btn btn-gold rounded-pill font-18 font-weight-bold btn-lg grid-card__button"
            :href="
              contact.action.type === 'a'
                ? contact.action.url
                : 'javascript:void(0)'
            "
            @click="actionClick(contact.action)"
            :gtm="{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type: 'internal link',
              region: 'main content',
              section: 'what if i still have questions?',
              text: contact.action.label.toLocaleLowerCase(),
            }"
            >{{ contact.action.label }}</BaseGtmLink
          >
        </div>
      </div>
    </div>
    <!-- <img src="../assets/images/dot-grid-bottom.svg" alt="dot grid bottom image-bottom" class="position-absolute"> -->
  </section>
</template>

<script>
import liveAgentMixin from "@/services/liveagent-persona.js";
export default {
  props: {
    content: Array,
  },
  mixins: [liveAgentMixin],
  methods: {
    actionClick(action) {
      if (action.type === "button") {
        this.ShowChatWindow();
      }
    },
    getimageURL(image) {
      return require("@/assets/images/personalize/" + image);
    },
  },
};
</script>

<style lang="scss">
.grid-card {
  @media only screen and (min-width: 991px) {
    min-height: 315px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 315px;
  }
}
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #8c1d40 !important;
  font-family: "Roboto", sans-serif;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #8c1d40 !important;
}
.grid-card__button {
  position: absolute;
  bottom: 0;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    left: 0;
    right: 0;
    width: 180px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 991px) {
    position: unset;
  }
}
.more-contact {
  border-radius: 4px;
  position: relative;
  &:before {
    content: "";
    background-image: url("../../assets/images/personalize/dot-grid-top.svg");
    position: absolute;
    left: -6%;
    height: 100%;
    background-repeat: no-repeat;
    width: 100%;
    z-index: -1;
    margin-top: -2.9rem;
    top: 0rem;
  }
  &:after {
    content: "";
    background-image: url("../../assets/images/personalize/dot-grid-bottom.svg");
    position: absolute;
    height: 100%;
    right: -6%;
    background-repeat: no-repeat;
    width: 350px;
    z-index: -1;
    margin-top: -2.6rem;
  }
}

.image-icons {
  height: 106px;
  width: 106px;
  line-height: 106px;
  background: #29413b;
  border-radius: 50%;
}
.image-top {
  left: -5%;
  bottom: -15%;
  z-index: 0;
}
.image-bottom {
  right: -5%;
  bottom: -15%;
  z-index: 0;
}
.bg-contact {
  background-color: #1f3933;
}
.more-contact {
  @media only screen and (max-width: 991px) {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}
</style>
