<template>
    <div class="col-lg-11 mx-auto text-center">
        <h2 class="font-lg-36 font-24 px-lg-5 font-black pb-32 mb-0 pb-lg-48" v-html="content.header">
        </h2>
        <p class="font-16">
          {{content.content}}
        </p>
      </div>
</template>

<script>
export default {
    props:{
        content:Object
    }
}
</script>