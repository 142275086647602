<template>
  <header
    class="z-99 position-relative header-section mb-lg-0"
    :class="{ 'border-bottom': !isSubMenuClicked }"
  >
    <b-navbar
      toggleable="lg"
      type="light"
      variant="faded"
      id="top-navbar"
      class="pt-3 container px-lg-0"
    >
      <b-navbar-brand class="m-0">
        <a href="/" class @click="triggerNavGtm()">
          <img
            :src="[
              !color
                ? require('../assets/images/logo.svg')
                : require('../assets/images/logo-white.svg'),
            ]"
            class="logo"
            alt="asu starbucks logo"
            srcset
          />
        </a>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <div id="hamburger-1" class="hamburger" @click="closeHamburger()">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </b-navbar-toggle>
      <b-collapse
        id="nav-collapse"
        is-nav
        class="text-center text-lg-left"
        @show="addColor(), showMenu()"
        @hide="addColor(), closeMenu()"
      >
        <b-navbar-nav role="presentation" class="top-fix-desktop mx-auto">
          <template v-for="(nav, i) in navigation">
            <BaseGtmLink
              tag="a"
              :key="i"
              class="text-center nav-link pl-lg-15"
              :href="nav.url"
              :active="nav.label === $router.history.current.name"
              :gtm="{
                event: 'link',
                name: 'onclick',
                action: 'click',
                type:
                  nav.name === 'Work for Starbucks'
                    ? 'external link'
                    : 'internal link',
                region: 'navbar',
                section: 'main navbar',
                text: nav.name.toLocaleLowerCase(),
              }"
            >
              {{ nav.name }} 
            </BaseGtmLink>
          </template>
        </b-navbar-nav>
        <hr class="light-border d-lg-none" style="width: 200px" />
        <b-navbar-nav role="presentation" class="ml-auto d-inline-flex d-lg-block cta-buttons">
          <BaseGtmLink
            
            tag="button"
            class="btn font-14 get-info-button stb-sBold mb-2 mb-lg-0"
            round
            @click="goToRFISection()"
            >Get info</BaseGtmLink
          >
          <BaseGtmLink
            
            tag="button"
            class="
              btn
              font-14
              apply-now-button
              stb-sBold
              bg-brand-gold
              text-black-1
            "
            round
            @click="$store.state.applyNow = 'navbar'"
            v-b-modal.modal-1
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'open',
              type: 'click',
              region: 'navbar',
              section: 'main navbar',
              text: 'apply now',
            }"
            >Apply now</BaseGtmLink
          >
        </b-navbar-nav>
      </b-collapse>
      <SectionApplyNow locale="navbar" />
      <b-modal ref="rfi-modal" size="xl" hide-footer body-class="p-0">
        <template v-slot:modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <BaseGtmLink
            tag="a"
            href="javascript:void(0)"
            class="close-apply text-dark font-36 ml-auto border-none"
            @click="close"
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'close',
              type: 'click',
              region: 'navbar',
              section: 'main navbar',
              text: 'close',
            }"
          >
            <img
              src="../assets/images/close-green.svg"
              alt="close"
              class="mt-4"
            />
          </BaseGtmLink>
        </template>
        <div class="d-block">
          <rfi page="offpage" />
        </div>
      </b-modal>
    </b-navbar>
    <slot></slot>
  </header>
</template>

<script>
import rfi from "@/components/rfi.vue";
import SectionApplyNow from "./SectionApplyNow";
import $ from "jquery";
export default {
  components: {
    SectionApplyNow,
    rfi,
  },

  data() {
    return {
      selected: "",
      checking: false,
      isNavHeaderOpen: false,
      navigation: [
        {
          name: "Home",
          url: "/",
          label: "home",
        },
        {
          name: "Student Support",
          url: "/support",
          label: "support",
        },
        {
          name: "Degree Search",
          url: "/starbucks-asu-degree-list",
          label: "search",
        },
        {
          name: "FAQ",
          url: "/faq",
          label: "faq",
        },
        {
          name: "Work for Starbucks",
          url: "https://www.starbucks.com/careers",
          label: "work for starbucks",
        },
      ],
      color: false,
      previousClickElement: "",
      subMenuArr: [],
      isSubMenuClicked: false,
      currentClickElement: "",
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.onResize();
    });
    window.addEventListener("resize", this.onResize);
    let self = this;
    window.addEventListener("click", function (e) {
      if (
        !document.getElementById("hamburger-1").contains(e.target) &&
        self.isNavHeaderOpen
      ) {
        self.$root.$emit("bv::toggle::collapse", "nav-collapse");
      }
    });
  },
  methods: {
    triggerNavGtm(){
    let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "navbar",
        section: "main navbar",
        text: "co brand scap asu logo",
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch {
        console.error("analytics fault");
      }
    },
    triggerFormGTM() {
      let dl = {
        event: "form",
        name: "onclick",
        action: "click",
        type: "click",
        region: "modal",
        section: "please try again",
        text: "partner number",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    closeHamburger() {
      if (this.isNavHeaderOpen) {
        let dl = {
          event: "collapse",
          name: "onlick",
          action: "close",
          type: "click",
          region: "navbar",
          section: "main navbar",
          text: "menu button mobile",
        };
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      }
    },
    showMenu() {
      this.isNavHeaderOpen = true;
      let dl = {
        event: "collapse",
        name: "onlick",
        action: "open",
        type: "click",
        region: "navbar",
        section: "main navbar",
        text: "menu button mobile",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    closeMenu() {
      this.isNavHeaderOpen = false;
    },
    onResize() {
      if (window.innerWidth > 767) {
        this.isSubMenuClicked = false;
        this.resize = true;
        this.color = true;
        this.addColor();
      }
    },
    checkActiveRoute(path) {
      console.log(path);
      console.log(this.$router.currentRoute.name);
      return this.$router.currentRoute.name === path;
    },
    goToRFISection() {
      let dl;
      if (
        this.$router.currentRoute.name !== "home" &&
        this.$router.currentRoute.name !== "support"
      ) {
        dl = {
          event: "modal",
          name: "onclick",
          action: "open",
          type: "click",
          region: "navbar",
          section: "main navbar",
          text: "get info",
        };
        // open the RFI modal
        this.$refs["rfi-modal"].show();
      } else {
        dl = {
          event: "link",
          name: "onclick",
          action: "click",
          type: "internal link",
          region: "navbar",
          section: "main navbar",
          text: "Get info".toLocaleLowerCase(),
        };
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#get-started").offset().top,
          },
          10
        );
      }
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    addColor() {
      if (!this.color) {
        document.getElementsByClassName(
          "header-section"
        )[0].style.backgroundColor = "#29413b";
        document.getElementsByClassName("navbar")[0].style.backgroundColor =
          "#29413b";
        [...document.querySelectorAll(".nav-link")].map(
          (elmnt) => (elmnt.style.color = "#fff")
        );
        [...document.getElementsByClassName("line")].map(
          (elmnt) => (elmnt.style.backgroundColor = "#fff")
        );
        document
          .getElementsByClassName("hamburger")[0]
          .classList.add("is-active-hamburger");
        this.color = true;
      } else {
        document.getElementsByClassName(
          "header-section"
        )[0].style.backgroundColor = "#ffff";
        document.getElementsByClassName("navbar")[0].style.backgroundColor =
          "#ffff";
        [...document.querySelectorAll(".nav-link")].map(
          (elmnt) => (elmnt.style.color = "#2A2A2A")
        );
        [...document.getElementsByClassName("line")].map(
          (elmnt) => (elmnt.style.backgroundColor = "#2A2A2A")
        );
        document
          .getElementsByClassName("hamburger")[0]
          .classList.remove("is-active-hamburger");
        this.color = false;
      }
    },
    clickedValue() {
      console.log("asdfasd");
    },
  },
};
</script>
<style lang="scss">
#top-navbar {
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
}
.top-fix-desktop {
  position: relative;
  @media only screen and (min-width: 1200px) {
    top: 15px !important;
  }
  @media only screen and (max-width: 1200px) and (min-width: 991px) {
    top: 11px !important;
  }
}
.logo {
  @media only screen and (max-width: 1200px) {
    width: 200px !important;
  }
  @media only screen and (max-width: 991px) {
    width: 250px !important;
  }
  width: 277px;
}

.nav-link {
  @media only screen and (max-width: 1024px) {
    font-size: 14px !important;
  }
  @media only screen and (max-width: 1200px) {
    padding-right: 15px;
  }
  font-size: 16px !important;
  @media only screen and (max-width: 991px) {
    padding-top: 16px !important;
  }
}
.active-route {
  @media only screen and (min-width: 991px) {
    border-bottom: 5px solid #29413b;
    font-family: "stb-s-bold" !important;
    color: #2a2a2a !important;
  }
  @media only screen and (max-width: 991px) {
    border-bottom: 1px solid white;
    font-family: "stb-s-bold" !important;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 0 !important;
  }
}
#modal-1___BV_modal_content_ {
  min-height: 80vh;
  border-top: 10px solid #006340;
}
.modal-header {
  border: none !important;
  justify-content: flex-end !important;
  padding: 0 !important;
  @media only screen and (max-width: 767px) {
    padding: 15px !important;
  }
}
.input-group-text {
  background-color: transparent !important;
}
.input-group-prepend.is-invalid {
  .input-group-text {
    border-color: #dc3545;
  }
}
.navbar-light .navbar-nav .nav-link {
  font-family: stb-light;
}
.popover-body {
  background-color: black;
  color: white !important;
}
.arrow {
  &:after {
    border-right-color: black !important;
    border-left-color: black !important;
  }
}
.navbar-toggler {
  box-shadow: none;
  border: none;
  border-color: transparent;
  &:focus {
    outline: none;
  }
}
.hamburger .line {
  width: 30px;
  height: 4px;
  background-color: black;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-light .navbar-toggler {
  border: none !important;
}
.activeLink {
  a {
    background: #29413b;
    color: white !important;
  }
}
.hamburger:hover {
  cursor: pointer;
}
#top-navbar {
  @media only screen and (max-width: 991px) {
    position: absolute;
    background-color: white;
  }
}
/* ONE */

#hamburger-1.is-active-hamburger .line:nth-child(2) {
  opacity: 0;
}
.navBar-desktop-fix {
  @media only screen and (min-width: 767px) {
    top: 16px;
    position: relative;
    left: 5%;
  }
}
#hamburger-1.is-active-hamburger .line:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}

#hamburger-1.is-active-hamburger .line:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}
#modal-1___BV_modal_content_ {
  min-height: 80vh;
  border-top: 10px solid #006340;
}
</style>
