<template>
  <BasePersonaLayout :available="pageData.availableIn">
    <hero :hero="pageData.hero" />
    <div class="container pt-lg-96 pt-48 pb-64 pb-64 pb-lg-128">
      <jumbotron :content="pageData.jumbo" />
    </div>
    <div class="container pb-lg-128 pb-64">
      <h2
        class="font-lg-36 col-lg-7 mx-auto font-24 text-center font-black mb-48 pl-0"
      >
        <span class="highlight-text"
          >Does Arizona State University have the major I want?</span
        >
      </h2>
      <div class="row">
        <div
          class="col-lg-4 card-deck mb-lg-0 mb-4"
          v-for="degree in pageData.degreeInfo"
          :key="degree.heading"
        >
          <CardDegreeInfo :info="degree"></CardDegreeInfo>
          <!-- {{ degree }} -->
        </div>
      </div>
    </div>
    <div class="container pb-lg-128 pb-96 pt-48">
      <image-text
        :heading="pageData.ListImage.left.heading"
        :image="getImageUrl(pageData.ListImage.left.image)"
        justify="left"
        :lists="pageData.ListImage.left.lists"
        section="how-will-i-fund"
      />
    </div>
    <div class="container text-center pb-lg-32 pb-24">
      <h3 class="highlight-text text-center font-black font-lg-36 font-24">
        What if I still have questions?
      </h3>
    </div>
    <div class="container">
      <contact :content="contacts" />
    </div>
  </BasePersonaLayout>
</template>

<script>
import { career } from "@/assets/data/persona.json";

import hero from "@/components/personalize/hero";
import jumbotron from "@/components/personalize/jumbotron";
import ImageText from "@/components/personalize/image-text";
import contact from "@/components/personalize/contact";
import CardDegreeInfo from "@/components/personalize/CardDegreeInfo.vue";

export default {
  metaInfo: {
    title: "Career",
    titleTemplate: "Starbucks College Achievement Plan | ASU Starbucks",
    htmlAttrs: {
      page: "Career",
    },
    link: [{ rel: "canonical", href: "https://starbucks.asu.edu/" }],
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow",
      },
    ],
  },

  data() {
    return {
      pageData: career,

      contacts: [
        {
          image: "degree-hat.svg",
          header: "Ready to begin <br> the application?",
          action: {
            label: "Apply now",
            type: "a",
            url: "https://webapp4.asu.edu/uga_admissionsapp/?partner=SCAP",
          },
        },
        {
          image: "phone.svg",
          header: "Speak to an <br> enrollment coach.",
          action: {
            label: "844-ASU-SBUX",
            type: "a",
            url: "tel:844-278-7289",
          },
        },
        {
          image: "chat.svg",
          header: "Have a question? <br> Chat with our team.",
          action: {
            label: "Chat now",
            type: "button",
            url: "#",
          },
        },
      ],
    };
  },
  methods: {
    getImageUrl(image) {
      if (image) return require("@/assets/images/personalize/" + image);
      else return false;
    },
  },
  components: {
    hero,
    jumbotron,
    contact,
    ImageText,
    CardDegreeInfo,
  },
};
</script>

<style lang="scss" scoped>
.card-deck {
  @media screen and (min-width: 991px) {
    margin: 0;
    padding: 0;
  }
}
.card {
  margin-bottom: 24px;
}
</style>
