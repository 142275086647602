<template>
  <div :id="`${$route.name}`" class="persona-page">
    <navbar :display-cta="true">
      <!-- <CallOutSection class="mt-0" :openHouseLink="bannerLink" /> -->
    </navbar>
    <main class="roboto-section">
      <slot></slot>
      <section class="">
        <div class="container">
          <div class="row"></div>
          <div class="col-lg-11 mx-auto text-center">
            <h2
              class="font-lg-36 font-24 px-lg-5 font-black pb-32 mb-0 pb-lg-32"
              v-html="cardData.header"
            ></h2>
            <p class="font-16">
              {{ cardData.content }}
            </p>
            <span
              class="bg-dark-green  d-flex col-5 col-md-2 mt-48 mb-48 mb-lg-64 mx-auto"
              style="height: 2px"
            ></span>
          </div>

          <div class="col-12">
            <personalizedcards />
          </div>
        </div>
      </section>
    </main>

    <mainFooter />
  </div>
</template>

<script>
import navbar from "@/components/personalize/navbar.vue";
import mainFooter from "@/components/mainFooter";
import personalizedcards from "@/components/personalize/personlaized-cards.vue";

// import CallOutSection from "@/components/CallOutSection";
export default {
  components: {
    navbar,
    mainFooter,
    personalizedcards,
  },
  data() {
    return {
      cardData: {
        header: "What else do I need to know?",
        content:
          "Get all of your questions answered about ASU Online and the Starbucks College Achievement Plan. Explore our resources below to learn more about the online student experience and how the partnership works.",
      },
      bannerLink:
        "https://app.brazenconnect.com/events/0ZVQW?utm_medium=ASU+Persona&utm_source=ASU+Persona&utm_content=ASU+Persona&utm_campaign=ASU+Persona",
    };
  },
};
</script>
