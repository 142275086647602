var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{staticClass:"font-label",staticStyle:{"margin-bottom":"0px !important"},attrs:{"id":`input-group-${_vm.id}`,"label":_vm.label,"label-class":_vm.labelSize,"label-for":`id-input-${_vm.id}`}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"custom-select",class:{
      'is-invalid':
        (_vm.value === null && _vm.errorFlag) || (_vm.clickedSubmit && _vm.value === null),
    },attrs:{"id":`id-input-${_vm.id}`},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.value=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.valueChanged(_vm.value)}],"blur":_vm.handleBlur}},[_c('option',{attrs:{"disabled":"","hidden":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.placeholder))]),_vm._l((_vm.options),function(option){return [_c('option',{key:option.code || option,attrs:{"data-reveal-area":`${_vm.degreeType}-${option.title || option}`},domProps:{"value":{ id: option.code, text: option.title } || option}},[_vm._v(" "+_vm._s(option.title || option)+" ")])]})],2),_c('b-form-invalid-feedback',{class:{
      'd-block':
        (_vm.value === null && _vm.errorFlag) || (_vm.clickedSubmit && _vm.value === null),
    },attrs:{"id":`input-${_vm.id}-live-feedback`}},[_c('span',[_vm._v(" "+_vm._s((_vm.value === null && _vm.errorFlag) || (_vm.clickedSubmit && _vm.value === null) ? "The degree field is required" : "")+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }