<template>
  <nav class="navbar navbar-light bg-white">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../assets/images/logo.svg" alt="logo" class="logo" />
      </a>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style lang="scss">
.logo {
  max-width: 100%;
  width: 300px;
}
</style>
