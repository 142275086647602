<template>
	<div :id="`${$route.name}`" class="persona-page">
		<navbar> </navbar>

		<section
			id="persona-new-hero"
			:style="
				'background-image:url(' +
				require('../assets/images/personalize/' + pageData.hero.bgImage) +
				');'
			"
			class="bg-dark-green">
			<div
				class="w-100"
				style="background: transparent linear-gradient(180deg, #19191900 0%, #19191900 3%, #19191940 39%, #19191980 67%, #000000CC 100%) 0% 0% no-repeat padding-box; !important">
				<div class="container">
					<div class="col-lg-9 pl-lg-0 position-relative z-top">
						<h1
							class="font-36 font-lg-56 font-weight-bold padding-fix mb-0 text-white pb-lg-48 pb-24">
							{{ pageData.hero.header }}
						</h1>
					</div>
				</div>
			</div>
		</section>

		<section
			id="section-overview"
			class="pt-48 pb-48 pt-lg-96 pb-lg-96 bg-grey">
			<div class="container pl-0 pr-0 pr-lg-15 pl-lg-15 ob">
				<div class="row no-gutters">
					<div
						class="col-lg-6 description-img image-side-left overview"
						:style="
							'background-image:url(' +
							require('../assets/images/personalize/' +
								pageData.card.left.image) +
							');'
						">
						<div
							class="col-12 description-div pl-lg-32 pr-lg-32 pt-lg-48 pb-lg-48 pl-lg-48 pr-lg-48 mt-lg-48 mb-lg-48 mt-xxl-64 mb-xxl-64 bg-grey white-bg">
							<div>
								<h2 class="mb-24 font-24 font-weight-bold font-lg-36">
									{{ pageData.card.left.heading }}
								</h2>
								<p
									v-for="list in pageData.card.left.lists"
									:key="list"
									class="mb-0">
									{{ list }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			id="cardDegree-info"
			class="container mt-lg-96 mt-48 mb-24 mb-lg-72">
			<div class="d-flex">
				<h1
					class="font-weight-bold m-0 mx-auto font-24 font-lg-36 pr-8 pl-8 pb-8 pt-8">
					<span class="highlight-text">{{ pageData.cardData.header }}</span>
				</h1>
			</div>
			<div class="row mt-48">
				<div
					class="col-lg-4 mb-4 col-12"
					v-for="degree in pageData.degreeInfo"
					:key="degree.title">
					<CardDegreeInfo
						:info="degree"
						:componentText="pageData.cardData.header"
						class="h-100"></CardDegreeInfo>
				</div>
			</div>
		</section>

		<section id="section-Resource" class="section-resources">
			<div class="container p-0">
				<img
					:src="
						require(`../assets/images/personalize/${pageData.sectionResource.image}`)
					"
					alt=""
					class="resource-image img-fluid description-image p-0" />
			</div>

			<div class="resource-content bg-green-3 pb-lg-96 pb-48">
				<div class="container px-lg-0 text-white pt-48 pt-lg-64">
					<h1 class="font-24 mb-12 font-weight-bold font-lg-36 m-0">
						{{ pageData.sectionResource.title }}
					</h1>
					<p class="pt-12 font-16 m-0">{{ pageData.sectionResource.text }}</p>
					<div class="row">
						<template v-for="(grid, index) in cardInfo">
							<div
								class="col-12 col-lg-4 mt-48"
								:key="index"
								v-if="grid.cta.path !== currentPage">
								<b-card
									no-body
									:href="grid.path"
									class="mb-lg-0 bg-green-3 border-light-4 h-100 p-0">
									<b-card-body class="pt-32 pl-32 pr-32">
										<b-card-title class="font-weight-bold" v-html="grid.title">
										</b-card-title>
										<b-card-text class="mt-24">
											{{ grid.text }}
										</b-card-text>
									</b-card-body>

									<b-card-footer class="bg-green-3 border-0 pb-32 pr-32 pl-32">
										<a
											:href="grid.cta.path"
											class="cta-color personlize-hover text-underline"
											@click="triggerCardClickDataLayer(grid.title)"
											>{{ grid.cta.label }}</a
										></b-card-footer
									>
								</b-card>
							</div>
						</template>
					</div>
				</div>
			</div>
		</section>

		<mainFooter> </mainFooter>
	</div>
</template>

<script>
	import navbar from "@/components/personalize/navbar.vue";
	import CardDegreeInfo from "@/components/personalize/CardDegreeInfo.vue";
	import mainFooter from "@/components/mainFooter";

	export default {
		components: {
			navbar,
			CardDegreeInfo,
			mainFooter,
		},

		computed: {
			currentPage() {
				return this.$route.path;
			},
		},
		props: {
			pageData: {
				type: Object,
				required: true,
			},
			cardInfo: {
				type: Array,
				required: false,
			},
		},
		methods: {
			triggerCardClickDataLayer(_title) {
				let dl = {
					event: "link",
					action: "click",
					name: "onclick",
					type: "internal link",
					region: "main content",
					section: _title.toLowerCase(),
					text: "learn more",
					component: "what other resources can i explore?",
				};
				// console.log(val);
				this.$gtm.trackEvent(dl);
				utag.link(dl);
			},
		},
	};
</script>

<style lang="scss">
	.text-shadow {
		text-shadow: 3px 6px #29413b;
	}

	#persona-new-hero {
		height: 600px;
		width: 100%;
		display: flex;
		position: relative;
		background-size: cover;
		background-position: center;
		align-items: end;
		@media only screen and (max-width: 991px) {
			height: 256px;
		}
	}
	.border-light-4 {
		border-color: #d0d0d0 !important;
	}
	.section-resources {
		.resource-image {
			@media (min-width: 576px) {
				margin-bottom: -175px;
				@media (min-width: 992px) {
					margin-bottom: -265px;
				}
				position: relative;
			}
		}
		.resource-content {
			@media (min-width: 576px) {
				padding-top: 175px !important;
				@media (min-width: 992px) {
					padding-top: 265px !important;
				}
			}
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.description-img {
			background-position: center top;
			background-repeat: no-repeat;
			background-size: cover;
			margin-right: 15px;
			min-height: 350px;

			&.image-side-right {
				margin-left: 43.33333%;
			}
		}

		.description-div {
			.image-side-right & {
				margin-left: -83.3333%;
			}
			.image-side-left & {
				margin-left: 83.3333%;
			}
		}
	}
	.personlize-hover {
		color: var(--brand-gold) !important;
		&:hover {
			color: white !important;
		}
	}
	.overview {
		background-position: inherit;
	}
	@media only screen and(max-width:500px) {
		.overview {
			background-color: #fafafa !important;
		}
	}
	@media only screen and (min-width: 992px) {
		.white-bg {
			background-color: white !important;
		}
	}
</style>
