<template>
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :aria-label="'input'"
      :label-class="labelSize"
      :label-for="`id-input-${id}`"
      class="font-label pb-space-xs"
    >
      <slot></slot>
      <b-form-input
        :id="`id-input-${id}`"
        v-model="$v.value.$model"
        :aria-label="'input'"
        :name="`name-input-${id}`"
        :state="!isInputRequired ? null : ($v.value.$dirty && $v.value.$error ? false : null)"
        :aria-describedby="`input-${id}-live-feedback`"
        :placeholder="placeholderText"
        @update="handleUpdate"
        @input="handleInput"
        @blur="handleBlur"
      ></b-form-input>
      <b-form-invalid-feedback
        :id="`input-${id}-live-feedback`"
        class="font-weight-normal"
        >{{  !isInputRequired ? null : standardInputError }}</b-form-invalid-feedback
      >
    </b-form-group>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "BaseInput",
  components: {
  },
  props: {
    isRequired:{
      type: Boolean,
      default: true,
    },
    labelSize: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    eventComponent: {
      type: String,
      default: "",
    },
    errMsg: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    placeholderText: {
      type: String,
      default: "",
    },
    emailError:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value:null,
      inputTyping:false,
      isInputRequired: this.isRequired,
    };
  },
  validations: {
    value:{
        required,
        maxLength: maxLength(30),
        validName: function (value) {
          return /^[a-zA-Z-\\'\\`\\'\\'\u2019\u2018\u0027\u0060\u00b4]+( [a-zA-Z-\\'\\`\\'\\'\u2019\u2018\u0027\u0060\u00b4]+)*$/.test(
            value
          );
        }
  }
},
  computed:{

    standardInputError() {
      let message = null;

      if (!this.$v.value.required && this.inputTyping==true) {
        message = "This is a required field.";
      } else if (!this.$v.value.maxLength) {
        message = `The ${this.errMsg} should not be more than 30 characters.`;
      } else if (!this.$v.value.validName) {
        message =
          this.capitalzeError(this.errMsg) +" can only contain letters, spaces and allowed special characters ( ', `, - ).";
      }else{
        message=""
      }

      return message;
    }

  },
  methods: {
    capitalzeError(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    handleUpdate(){
      return false
    },
    handleInput() {
      this.inputTyping=true
      this.$emit("inputError", this.$v.value.$dirty && this.$v.value.$error)
      this.$emit("input", this.value);
      this.$emit('changed', this.value);
    },
    handleBlur() {
      this.$emit("blur", this.value);
    },
  },
};
</script>
