<template>
  <div
    role="presentation"
    class="border mb-16"
    style="border-left: 5px solid #29413b !important"
  >
    <a
      href="javascript:void(0)"
      v-b-toggle="'accordion-' + iterate"
      class="
        p-3
        d-flex
        w-100
        justify-content-between
        text-dark
        font-18
        fam-roboto
        font-weight-bold
        position-relative
        z-top
      "
    >
      <span class="text">{{ FAQs.question }}</span>
      <span class="when-opened">
        <img
          src="../assets/images/Icon-chevron-down-solid.svg"
          alt="icon-cheveron-down"
          class="icon-cheveron-down"
        />
      </span>
      <span class="when-closed">
        <img
          src="../assets/images/Icon-chevron-down-solid.svg"
          alt="icon-cheveron-down"
          class="icon-cheveron-up"
        />
      </span>
    </a>
    <b-collapse
      :id="`accordion-${iterate}`"
      @shown="handleCollapseShown(FAQs.question, faqKey)"
      @hidden="handleCollapseHidden(FAQs.question, faqKey)"
      accordion="my-accordion"
      role="tabpanel"
      class="px-3 z-bottom"
    >
      <div class="faq-body">
        <p v-for="(answer, i) in FAQs.answer" :key="i" v-html="answer"></p>
      </div>
    </b-collapse>
  </div>
</template>
<script>
export default {
  props: {
    FAQs: Object,
    iterate: String,
    faqKey: String,
    evntSection: String,
  },
  watch: {
    '$route.hash': function(newHash) {
      if (newHash) {
        this.$nextTick(() => {
          // Scroll to the element with the id
          const element = document.getElementById(newHash.replace('#', ''));
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            // After scrolling, remove the hash from the URL
            this.$router.replace({ path: this.$route.path, query: this.$route.query });
          }
        });
      }
    }
  },
  methods: {
    handleCollapseShown(question) {
      let dl = {
        event: "collapse",
        name: "onclick",
        action: "open",
        type: "click",
        region: "main content",
        section: this.evntSection.toLocaleLowerCase(),
        text: question.toLocaleLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    handleCollapseHidden(question) {
      let dl = {
        event: "collapse",
        name: "onclick",
        action: "close",
        type: "click",
        region: "main content",
        section: this.evntSection.toLocaleLowerCase(),
        text: question.toLocaleLowerCase(),
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
  },
};
</script>

<style lang="scss" scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.icon-cheveron-down {
  transform: rotate(-180deg);
  width: 26px;
}
.icon-cheveron-up {
  width: 26px;
}
.collapsing {
  transition: all 0.5s !important;
}
</style>
