<template>
  <BaseSbuxLayout>
    <div id="FAQ-page">
      <hero
        class="text-white"
        header="Frequently asked questions"
        subheader="Your questions about the Starbucks College Achievement Plan, answered!"
        page="faq"
      />
      <!--new question modal section -->
      <section id="new-modal" class="container px-lg-0 d-flex pt-36 pb-36">
        <p class="font-16">
          <img
            src="@/assets/images/Icon - question-circle-solid.svg"
            alt="question-circle-solid"
            class="mr-2"
            height="32"
            width="32"
          />
        </p>
        <span>
          Have a question we don’t cover? Submit it
          <BaseGtmLink
            tag="a"
            href="javascript:void(0)"
            v-b-modal.new-faq-request
            class="text-dark-green text-underline"
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'open',
              type: 'click',
              region: 'main content',
              section: 'frequently asked questions',
              text: 'here',
            }"
            >here</BaseGtmLink
          >.
        </span>
      </section>
      <!-- filter - faq container -->
      <section class="container mb-100">
        <div class="row">
          <!-- filter desktop-->
          <div class="col-lg-3 filter-faq pl-lg-0">
            <b-form-group
              id="filter-input"
              label="Filters"
              label-for="Filter-search"
              class="mb-2"
            >
              <b-form-input
                id="search"
                v-model="search"
                type="search"
                @blur="getSearchedValues(search)"
                placeholder="Enter keywords"
              ></b-form-input>
            </b-form-group>
            <!-- button group -->
            <hr class="d-none d-lg-block" />
            <div class="mt-2 mb-4 row">
              <div class="col-lg-7 pr-lg-0">
                <b-dropdown
                  id="dropdown-right"
                  left
                  block
                  variant="dark-green"
                  @show="showDropdownOpen()"
                  class="d-lg-block d-none"
                  :disabled="!selected.length > 0"
                >
                  <template #button-content>
                    {{ selected.length }} filters applied
                  </template>
                  <ul class="selected-filters">
                    <li class="" v-for="list in selected" :key="list">
                      <a href="javascript:void(0)">{{ list }}</a>
                      <b-icon
                        icon="x-circle-fill"
                        scale="1.5"
                        variant="light"
                        class="float-right"
                        @click="removeThis(list)"
                        style="
                          margin-top: 4px;
                          margin-right: 5px;
                          cursor: pointer;
                        "
                      ></b-icon>
                    </li>
                  </ul>
                </b-dropdown>
                <b-button
                  block
                  variant="outline-light"
                  v-b-toggle.modal-mobile-filter
                  class="
                    mb-2
                    border
                    font-xl-16 font-lg-12
                    text-dark
                    border-grand-green
                    d-lg-none
                  "
                  >Filters
                  <span
                    class="rounded-circle bg-dark-green circular-bg text-light"
                    >{{ selected.length }}</span
                  >
                  <b-icon icon="chevron-right" scale="1"></b-icon>
                </b-button>
              </div>
              <div class="col-lg-5">
                <b-button
                  block
                  variant="light"
                  @click="
                    (selected = []), gtmTriggerLink('filters', 'clear all')
                  "
                  class="
                    border
                    font-xl-14 font-lg-12
                    border-grey-brand
                    d-none d-lg-block
                  "
                  >Clear all</b-button
                >
              </div>
            </div>
            <hr class="d-none d-lg-block" />
            <!-- check boxes -->
            <b-form-group
              class="d-none d-lg-block faq-checkbox font-lg-14 font-14"
              label="FAQ category"
            >
              <b-form-checkbox-group
                class="checkbox-group-1"
                v-model="selected"
                stacked
                @change="selectedChange"
                :options="faqKeywords(faqData)"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <!-- faq -->
          <div class="col-lg-9 bg-grey p-lg-5 py-4 mt-2">
            <template v-for="(faqKey, i) in Object.keys(sortedFilterKeyword)">
              <div :key="faqKey" class="mb-lg-5 mt-lg-0">
                <h3
                  :class="{ 'mt-32': i !== 0 }"
                  class="mb-24 font-lg-36 font-fam-Georgia text-dark-green"
                v-html="faqKey" >
                </h3>
                <faq-container
                  v-for="(faq, j) in sortedFilterKeyword[faqKey]"
                  :key="j"
                  :FAQs="faq"
                  :evntSection="faqKey"
                  :iterate="`${i}-${j}`"
                />
              </div>
            </template>
          </div>
        </div>
      </section>
      <b-sidebar
        width="100%"
        id="modal-mobile-filter"
        aria-labelledby="modal-mobile-filter-title"
        no-header
        shadow
      >
        <template #default="{ hide }">
          <div class="container">
            <div class="pb-3">
              <div class="mt-3 mb-4 row justify-content-between">
                <h4
                  id="modal-mobile-filter-title"
                  class="ml-3 font-wight-bold font-24 fam-roboto"
                >
                  Filters
                </h4>
                <img
                  src="../assets/images/Icon-close.svg"
                  alt="close"
                  @click="hide"
                  class="mr-3"
                />
              </div>
              <label for="checkbox-group-category" class="font-weight-bold"
                >FAQ category</label
              >
              <b-form-checkbox-group
                id="checkbox-group-category"
                class="checkbox-group-1"
                v-model="selected"
                stacked
                :options="faqKeywords(faqData)"
                name="flavour-1"
              ></b-form-checkbox-group>
            </div>
          </div>
        </template>
        <template #footer="{ hide }">
          <div class="container mb-3">
            <div class="row">
              <div class="col-6">
                <b-button block variant="dark-green" @click="hide" class="py-3"
                  >Apply</b-button
                >
              </div>
              <div class="col-6">
                <b-button
                  block
                  variant="light"
                  @click="selected = []"
                  class="py-3 border border-grey-brand"
                  >Clear all</b-button
                >
              </div>
            </div>
          </div>
        </template>
      </b-sidebar>
      <b-modal id="new-faq-request" size="xl" hide-footer>
        <template v-slot:modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <BaseGtmLink
            tag="a"
            href="javascript:void(0)"
            class="ml-auto border-none close-apply text-dark font-36"
            @click="close"
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'close',
              type: 'click',
              region: 'main content',
              section: 'submit your question to asu online',
              text: 'close',
            }"
          >
            <img src="../assets/images/Icon-close.svg" alt="close" />
          </BaseGtmLink>
        </template>
        <div class="container">
          <h2 class="stb-s-bold font-wight-bold modal-title font-26 pb-lg-24">
            Submit your question to ASU Online
          </h2>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider
                v-slot="validationContext"
                name="description"
                rules="required|min:5"
              >
                <b-form-group
                  group
                  id="filter-text-area"
                  label="Enter your question in the text area below (required field)"
                  label-for="Filter-search"
                >
                  <b-form-textarea
                    id="text-area"
                    v-model="form.description"
                    type="text"
                    name="example-input-1"
                    data-vv-as="description"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter something..."
                    rows="10"
                    max-rows="15"
                    @click="gtmTriggerTextArea()"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="validationContext"
                name="email"
                rules="required|email:true"
              >
                <b-form-group
                  id="filter-Input"
                  label="Enter your email text box below (required field)"
                  label-for="Filter-search"
                >
                  <b-form-input
                    id="email"
                    :state="getValidationState(validationContext)"
                    v-model="form.email"
                    name="email"
                    type="email"
                    placeholder="Enter text"
                    data-vv-as="email"
                    @click="triggerEmail()"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <div class="mt-32 mb-5 text-center">
                <b-button
                  type="submit"
                  variant="dark-green"
                  size="lg"
                  class="text-center"
                  >Submit</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>
    </div>
  </BaseSbuxLayout>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import hero from "@/components/hero-faq.vue";
import FaqContainer from "@/components/faq-container.vue";
import seo from "../services/seo";
import faq from "../services/data/faq.json";

import axios from "axios";
import _ from "lodash";
export default {
  metaInfo: {
    title: "FAQ",
    titleTemplate: "%s | ASU Starbucks",
    htmlAttrs: {
      page: "faq",
    },
    link: [
      {
        rel: "canonical",
        href: `${window.location.origin}/faq`,
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "Discover what the Starbucks College Achievement Plan is, what degrees you can earn at ASU and eligibility rules for the scholarship.",
      },
    ],
  },
  jsonld: seo.jsonldSchema(faq),
  name: "home",
  components: {
    hero,
    FaqContainer,
    "validation-observer": ValidationObserver,
    "validation-provider": ValidationProvider,
  },
  data() {
    return {
      FAQs: faq,
      search: "",
      email: "",
      oldValue: [],
      selectedFaqs: faq,
      selected: [],
      faqData: faq,
      form: {
        description: "",
        email: "",
        location: "",
      },
    };
  },
  watch: {
    search: function(text) {
      if (text?.length > 0) {
        if (this.selected.length > 0) {
          this.FAQs = this.handleSearch(this.selectedFaqs, text);
        } else {
          this.FAQs = this.handleSearch(faq, text);
        }
      } else {
        this.FAQs = this.selectedFaqs;
      }
    },
    selected: function(newValue, oldValue) {
      this.oldValue = oldValue;
      if (this.selected.length > 0) {
        let chkArray = [];
        newValue.forEach((chk) => {
          chkArray.push(faq.filter((faq) => chk == faq.keyword));
        });
        this.search = null;
        this.FAQs = chkArray.flat();
        this.selectedFaqs = chkArray.flat();
      } else {
        this.search = null;
        this.FAQs = faq;
        this.selectedFaqs = faq;
      }
    },
  },
  methods: {
    handleSearch(arr, searchInput) {
      return arr.filter((obj) => {
        const { keyword, ...rest } = obj;
        console.log(keyword);
        return Object.values(rest)
          .flat()
          .some((v) => v.toLowerCase().includes(searchInput.toLowerCase()));
      });
    },
    getSearchedValues(text) {
      console.log(text);
      this.gtmTrigger(
        "search",
        "type",
        "onenter",
        "keyword search",
        "main content",
        "filters",
        text.toLocaleLowerCase()
      );
    },
    gtmTriggerTextArea() {
      let dl = {
        event: "form",
        action: "click",
        name: "onclick",
        type: "click",
        region: "main content",
        section: "submit your question to asu online",
        text: "enter your question in the text area below",
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch (error) {
        console.log("no utag");
      }
    },
    selectedChange(newValue) {
      let uncheck = false;
      if (newValue.length < this.oldValue.length) {
        uncheck = true;
      }
      if (uncheck) {
        let text = this.oldValue.filter(function(i) {
          return this.indexOf(i) < 0;
        }, newValue);
        this.gtmTrigger(
          "select",
          "onclick",
          "onclick",
          "filters applied",
          "main content",
          "filters",
          text[0].toLowerCase()
        );
      } else {
        this.gtmTrigger(
          "select",
          "click",
          "onclick",
          "filters applied",
          "main content",
          "filters",
          newValue[newValue.length - 1].toLowerCase()
        );
      }
    },
    triggerEmail() {
      let dl = {
        event: "form",
        action: "click",
        name: "onclick",
        type: "click",
        region: "main content",
        section: "submit your question to asu online",
        text: "enter email",
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch (error) {
        console.log("no utag");
      }
    },
    onSubmit() {
      let dl = {
        event: "form",
        action: "click",
        name: "onclick",
        type: "click",
        region: "main content",
        section: "submit your question to asu online",
        text: "submit",
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch (error) {
        console.log("no utag");
      }
      let formdata = new FormData();
      formdata.append("Email", this.form.email);
      formdata.append("description", this.form.description);
      formdata.append("location", window.location.href);

      axios({
        method: "post",
        url:
          "https://script.google.com/macros/s/AKfycbzZ1D7h61kOkjyQWcdrOQmnv_bWUBrxh66be9TCXl7nUBn-_Q3ThOxqOlyiyFD9mBcS4A/exec",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function(response) {
          //handle success
          console.log(response);
        })
        .catch(function(response) {
          //handle error
          console.log(response);
        });
      this.$nextTick(() => {
        this.$bvModal.hide("new-faq-request");
        this.form.description = "";
        this.form.email = "";
      });
    },
    gtmTrigger(_event, _action, _name, _type, _region, _section, _text) {
      let dl = {
        event: _event,
        action: _action,
        name: _name,
        type: _type,
        region: _region,
        section: _section,
        text: _text,
      };
      this.dlTrigger(dl);
    },
    gtmTriggerLink(_section, _text) {
      let dl = {
        event: "link",
        action: "click",
        name: "onclick",
        type: "internal link",
        region: "main content",
        section: _section,
        text: _text,
      };
      this.dlTrigger(dl);
    },
    gtmTriggerModal(_state, _section, _text) {
      let dl = {
        event: "modal",
        action: _state,
        name: "onclick",
        type: "click",
        region: "main content",
        section: _section,
        text: _text,
      };
      this.dlTrigger(dl);
    },
    dlTrigger(dl) {
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch {
        console.log("dl issue");
      }
    },
    showDropdownOpen() {
      this.gtmTrigger(
        "select",
        "open",
        "onclick",
        "dropdown",
        "main content",
        "filters",
        "filters applied"
      );
    },
    showDropdownHide() {
      this.gtmTrigger(
        "select",
        "close",
        "onclick",
        "dropdown",
        "main content",
        "filters",
        "filters applied"
      );
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    removeThis(li) {
      this.selected.splice(this.selected.indexOf(li), 1);
      this.gtmTrigger(
        "select",
        "onclick",
        "onclick",
        "filters applied",
        "main content",
        "filters",
        li.toLowerCase()
      );
    },
    faqKeywords(faqs) {
      return [...new Set(faqs.map((faq) => faq.keyword))];
    },
  },
  computed: {
    sortedFilterKeyword() {
      return _.groupBy(_.sortBy(this.FAQs), "keyword");
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-filters {
  list-style: none;
  padding: 0 10px;
  width: 250px;
  li {
    margin-bottom: 5px;
    padding: 5px;
    background: #29413b;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    color: white;
  }
}
#dropdown-right__BV_toggle_ {
  font-size: 14px;
}
#dropdown-right__BV_toggle_ {
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 12px;
  }
}
.filter-faq {
  @media only screen and (max-width: 767px) {
    position: sticky;
    z-index: 100;
    top: 0;
    background: white;
  }
}
.circular-bg {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.modal-title {
  @media only screen and (max-width: 991px) {
    margin-top: 6% !important;
  }
}
</style>
<style lang="scss">
#dropdown-right__BV_toggle_ {
  font-size: 14px;
}
.faq-tax-impact-paycheck .table td {
  padding: 0;
}
</style>
