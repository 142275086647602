<template>
  <div class="offset-how-to-apply container p-0 pl-lg-auto">
    <BaseOffsetImg justify="right">
      <template #image>
        <div
          :style="
            `background-image: url(${require('../assets/images/How_to_Apply.svg')}); border-radius:10px;`
          "
          class="backround-cover col-lg-6 offset-background"
        ></div>
      </template>
      <template #content>
        <div class="col-lg-6 negative-top">
          <div
            class="content-header col-10 col-lg-12 mx-auto text-center text-lg-left px-3 pb-4 pb-lg-5 pl-lg-4 pr-lg-5 pt-0"
          >
            <h2 class="font-fam-Georgia font-lg-36 font-32 pt-4 pt-lg-0">
              How to apply
            </h2>
            <p class="font-16 mb-0 col-lg-8 px-0 z-2">
              Follow these steps to apply to the Starbucks College Achievement
              Plan
            </p>
          </div>
          <numbered-list
            :lists="items"
            class="col-10 mx-auto col-lg-12 negative-top-numberd pb-lg-3"
            style=""
          />
        </div>
      </template>
    </BaseOffsetImg>
    <b-modal
      id="pathway-modal-desktop"
      :static="true"
      size="xl"
      hide-footer
      body-class="p-0"
      content-class="border-0 pathway-container"
    >
      <!-- if desktop show close icon -->
      <template v-slot:modal-header="{ close }">
        <div class="d-none d-lg-block">
          <BaseGtmLink
            tag="a"
            href="javascript:void(0)"
            class="close-apply text-dark font-36 ml-auto border-none"
            @click="close"
            :gtm="{
              event: 'modal',
              name: 'onclick',
              action: 'close',
              type: 'click',
              region: 'main content',
              section: 'embark on your pathway to admission',
              text: 'close cross',
            }"
          >
            <img
              src="../assets/images/Icon-close.svg"
              alt="close"
              @click="close"
            />
          </BaseGtmLink>
        </div>
        <div class="d-lg-none w-100 fixed-top bg-white">
          <div class="close-header border-bottom-2-green">
            <div class="container">
              <BaseGtmLink
                tag="a"
                href="javascript:void(0)"
                class="text-dark font-36 ml-auto border-none"
                @click="close"
                :gtm="{
                  event: 'modal',
                  name: 'onclick',
                  action: 'close',
                  type: 'click',
                  region: 'main content',
                  section: 'embark on your pathway to admission',
                  text: 'back',
                }"
              >
                <img src="../assets/images/icon-left.svg" alt="left icon" />
              </BaseGtmLink>
              <span class="header-text stb-sBold pl-22 font-16"
                >Pathways to admission</span
              >
            </div>
          </div>
        </div>
      </template>
      <div class="container">
        <pathway-to-admission />
      </div>
    </b-modal>
  </div>
</template>

<script>
import PathwayToAdmission from "@/components/pathway-to-admission.vue";
import NumberedList from "@/components/numbered-list";
export default {
  data() {
    return {
      items: [
        {
          heading: "Become a partner",
          content:
            "Not a current partner? <a href='https://www.starbucks.com/careers/search' class='text-underline text-dark-green'>Join the team</a> and become benefits-eligible.",
        },
        {
          heading: "Request information",
          content:
            "<a href='#get-started' class='text-underline text-dark-green' id='use-this-form'>Use this form </a>for an ASU enrollment coach to reach out to you.",
        },
        {
          show: true,
          heading: "Apply to ASU",
          content:
            "Once you’ve gathered enough information, apply to ASU. Even if you might not be admissible, you can earn your admission through Pathway to Admission. Apply today!.",
        },
        {
          heading: "Send transcripts",
          content:
            "Request your school(s) to send official transcripts to ASU. Your enrollment coach can help.",
        },
        {
          heading: "Complete your financial aid file",
          content:
            "The program requires the Free Application for Federal Student Aid (FAFSA) along with any required documentation to be completed before the first day of your first session and every year. Visit <a href='https://studentaid.gov/h/apply-for-aid/fafsa' class='text-underline text-dark-green'>fafsa.ed.gov</a> to get started. Check with your enrollment coach if you have questions.",
        },
      ],
    };
  },
  components: {
    NumberedList,
    PathwayToAdmission,
  },
};
</script>

<style lang="scss">
#pathway-modal-desktop {
  padding: 0 !important;
  .modal-dialog {
    @media only screen and (max-width: 991px) {
      max-width: 100% !important;
      margin: 0 !important;
    }
  }
}
.pathway-container {
  @media only screen and (min-width: 991px) {
    border-top: 10px solid #006340 !important;
  }
}
#pathway-modal-desktop___BV_modal_header_ {
  border: none;
}
.negative-top-numberd {
  @media only screen and (min-width: 991px) {
    position: relative;
    z-index: 1;
    top: -10%;
    padding-top: 48px;
  }
}
.content-header {
  @media only screen and (max-width: 991px) {
    background-color: white;
  }
}
</style>
