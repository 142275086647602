var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"z-99 position-relative header-section mb-lg-0",class:{ 'border-bottom': !_vm.isSubMenuClicked }},[_c('b-navbar',{staticClass:"pt-3 container px-lg-0",attrs:{"toggleable":"lg","type":"light","variant":"faded","id":"top-navbar"}},[_c('b-navbar-brand',{staticClass:"m-0"},[_c('a',{attrs:{"href":"/"},on:{"click":function($event){return _vm.triggerNavGtm()}}},[_c('img',{staticClass:"logo",attrs:{"src":[
            !_vm.color
              ? require('../assets/images/logo.svg')
              : require('../assets/images/logo-white.svg'),
          ],"alt":"asu starbucks logo","srcset":""}})])]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}},[_c('div',{staticClass:"hamburger",attrs:{"id":"hamburger-1"},on:{"click":function($event){return _vm.closeHamburger()}}},[_c('span',{staticClass:"line"}),_c('span',{staticClass:"line"}),_c('span',{staticClass:"line"})])]),_c('b-collapse',{staticClass:"text-center text-lg-left",attrs:{"id":"nav-collapse","is-nav":""},on:{"show":function($event){_vm.addColor(), _vm.showMenu()},"hide":function($event){_vm.addColor(), _vm.closeMenu()}}},[_c('b-navbar-nav',{staticClass:"top-fix-desktop mx-auto",attrs:{"role":"presentation"}},[_vm._l((_vm.navigation),function(nav,i){return [_c('BaseGtmLink',{key:i,staticClass:"text-center nav-link pl-lg-15",attrs:{"tag":"a","href":nav.url,"active":nav.label === _vm.$router.history.current.name,"gtm":{
              event: 'link',
              name: 'onclick',
              action: 'click',
              type:
                nav.name === 'Work for Starbucks'
                  ? 'external link'
                  : 'internal link',
              region: 'navbar',
              section: 'main navbar',
              text: nav.name.toLocaleLowerCase(),
            }}},[_vm._v(" "+_vm._s(nav.name)+" ")])]})],2),_c('hr',{staticClass:"light-border d-lg-none",staticStyle:{"width":"200px"}}),_c('b-navbar-nav',{staticClass:"ml-auto d-inline-flex d-lg-block cta-buttons",attrs:{"role":"presentation"}},[_c('BaseGtmLink',{staticClass:"btn font-14 get-info-button stb-sBold mb-2 mb-lg-0",attrs:{"tag":"button","round":""},on:{"click":function($event){return _vm.goToRFISection()}}},[_vm._v("Get info")]),_c('BaseGtmLink',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"btn font-14 apply-now-button stb-sBold bg-brand-gold text-black-1",attrs:{"tag":"button","round":"","gtm":{
            event: 'modal',
            name: 'onclick',
            action: 'open',
            type: 'click',
            region: 'navbar',
            section: 'main navbar',
            text: 'apply now',
          }},on:{"click":function($event){_vm.$store.state.applyNow = 'navbar'}}},[_vm._v("Apply now")])],1)],1),_c('SectionApplyNow',{attrs:{"locale":"navbar"}}),_c('b-modal',{ref:"rfi-modal",attrs:{"size":"xl","hide-footer":"","body-class":"p-0"},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('BaseGtmLink',{staticClass:"close-apply text-dark font-36 ml-auto border-none",attrs:{"tag":"a","href":"javascript:void(0)","gtm":{
            event: 'modal',
            name: 'onclick',
            action: 'close',
            type: 'click',
            region: 'navbar',
            section: 'main navbar',
            text: 'close',
          }},on:{"click":close}},[_c('img',{staticClass:"mt-4",attrs:{"src":require("../assets/images/close-green.svg"),"alt":"close"}})])]}}])},[_c('div',{staticClass:"d-block"},[_c('rfi',{attrs:{"page":"offpage"}})],1)])],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }