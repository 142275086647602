<template>
  <div :class="page">
    <b-form-group role="radiogroup">
      <b-form-radio
        v-model="partnerSelectionMode"
        name="some-radios"
        value="yes"
        class="mb-3"
        ref="PartnerNumber"
        >Yes, I am a Starbucks partner.</b-form-radio
      >
      <div
        class="ml-3 my-2"
        v-show="partnerSelectionMode === 'yes'"
        :class="{ 'col-md-6': page === 'rfi' }"
      >
        <b-form-group
          id="input-group-subx"
          label="*Partner number"
          label-for="id-input-subx"
          class="font-label pb-space-xs"
          ><div class="input-group d-flex align-items-center">
            <div
              :class="{
                'input-group-prepend': true,
                'is-invalid': partnerNumberInvalid,
              }"
            >
              <span
                class="input-group-text fam-roboto font-black border-right-0"
                id="inputGroupPrepend2"
                >US -</span
              >
            </div>
            <b-form-input
              id="id-input-subx"
              v-model="$v.inputValue.$model"
              name="name-input-subx"
              class="border-left-0 mr-1"
              :class="{
                'input-group-prepend': true,
                'border-danger': partnerNumberInvalid,
              }"
              :style="
                partnerNumberInvalid & (inputValue.length === 8)
                  ? 'border-color:#dc3545 !important'
                  : ''
              "
              :state="
                $v.inputValue.$dirty && $v.inputValue.$error ? false : null
              "
              aria-describedby="input-subx-live-feedback"
              @blur="triggerFormGTM(inputValue, 'partner')"
              @input="focusOut()"
            ></b-form-input>
            <img
              id="popover-3"
              src="../assets/images/Icon - question-circle-solid.svg"
              alt="Icon - question-circle-solid.svg"
            />
            <b-popover
              target="popover-3"
              :triggers="tooltipTrigger"
              placement="top"
              :show.sync="showTooltip"
            >
              <span @click="showTooltip = false">
                Don’t know your partner number? Ask your manager or call the
                Partner Contact Center (PCC) at
                <a
                  class="text-brand-gold"
                  @click="gtmTrigger()"
                  href="tel:888-728-9411"
                  >888-728-9411</a
                >
              </span>
            </b-popover>
          </div>

          <b-form-invalid-feedback id="input-subx-live-feedback">
            <div class="mb-space-lg">
              {{ $v.inputValue.$error ? standardInputError : "" }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <span class="is-invalid text-danger invalid-feedback">{{
          this.partnerNumberInvalid & (this.inputValue.length === 8)
            ? "Invalid partner number"
            : ""
        }}</span>
      </div>
      <b-form-radio
        v-model="partnerSelectionMode"
        name="some-radios"
        value="no"
        class="mt-3"
        >No, I am not a Starbucks partner.</b-form-radio
      >
    </b-form-group>
  </div>
</template>

<script>
import registerService from "../services/register";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  name: "partnersCheck",
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      partnerNumberInvalid: false,
      flag: false,
      partnerSelectionMode: "yes",
      tooltipTrigger: "",
      showTooltip: null,
      inputValue: "",
      isCheckingPartnerNumber: false,
      standardInputError:
        "The partner number field must be numeric and exactly contain 8 digits",
    };
  },
  watch: {
    inputValue(val) {
      this.$emit("input-value", val);
    },
    partnerSelectionMode(val) {
      this.$emit("radio-value", val);
      this.inputValue = "";
    },
    isCheckingPartnerNumber(val) {
      this.$emit("is-checking-partner-number", val);
    },
  },
  validations: {
    inputValue: {
      required,
      maxLength: maxLength(8),
      minLength: minLength(8),
    },
  },
  computed: {},
  props: {
    page: String,
    clickedSubmit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    gtmTrigger() {
      let dl = {
        event: "link",
        name: "onclick",
        action: "click",
        type: "external link",
        region: "modal",
        section: "get started at asu today! ^ question mark",
        text: "866-504-7368",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    async focusOut() {
      if (this.inputValue.length === 8) {
        this.isCheckingPartnerNumber = true;

        try {
          await registerService
            .checkPartner(this.inputValue)
            .then((this.partnerNumberInvalid = false));
          this.partnerNumberInvalid = false;
          this.isCheckingPartnerNumber = false;
        } catch (err) {
          this.isCheckingPartnerNumber = false;
          this.partnerNumberInvalid = true;
        }
      }

      this.$emit(
        "inputError",
        this.$v.inputValue.$error || this.partnerNumberInvalid
      );
    },
    triggerFormGTM(_text, partner = null) {
      let dl = {
        event: partner ? "form" : "select",
        name: "onclick",
        action: "click",
        type: partner ? "blur" : "checkbox",
        region: "main content",
        section: `get started at asu today!${partner ? " ^ " + partner : ""}`,
        text: partner
          ? _text
          : _text === "yes"
          ? "yes, i am a starbucks partner"
          : "no, i am not a starbucks partner",
      };
      this.$gtm.trackEvent(dl);
      utag.link(dl);
    },
    popoverData() {
      return {
        html: true,
        content: () => {
          // Note this is called only when the popover is opened
          return 'Don’t know your partner number? Ask your manager or call the Partner Contact Center (PCC) at <a class="text-brand-gold" href="tel:866-504-7368">866-504-7368</a> for assistance.';
        },
      };
    },
    popoverTrigger() {
      if (window.innerWidth <= 992) this.tooltipTrigger = "click";
      else this.tooltipTrigger = "hover focus";
    },
  },
  mounted() {
    this.popoverTrigger();
    window.addEventListener("resize", this.popoverTrigger);
  },
};
</script>
<style lang="scss">
.invalid-feedback {
  display: block !important;
  position: inherit !important;
}
</style>
