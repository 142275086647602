<template>
  <section class="col-lg-11 mx-auto">
    <h2 class="font-lg-36 font-24 stb-sBold pt-lg-90 pt-30">
      {{ rfi.content.heading }}
    </h2>
    <p
      class="pt-lg-10 pt-5 pb-lg-20 pb-10 stb-light font-16 text-black line-height-22 mb-0"
    >
      {{ rfi.content.desc }}
    </p>
    <transition name="fade">
      <div
        class="alert fade in alert-dismissible show"
        v-if="custom"
        :class="this.error ? 'alert-danger' : 'alert-success'"
      >
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-on:click="remove"
        >
          <span aria-hidden="true" style="font-size: 20px">×</span>
        </button>
        <strong>{{ customMessage }}</strong>
        {{ customData }}
      </div>
    </transition>
    <b-form @submit.stop.prevent="onSubmit">
      <p class="font-weight-bold">* Indicates a required field</p>
      <div id="req-info-form" class="mb-3 justify-content-center">
        <div class="row">
          <div class="col-md-6">
            <base-input
              id="first-name"
              v-model="first_name"
              label="* First name"
              placeholderText="Enter first name"
              err-msg="first name"
              @blur="triggerFormGTM(first_name, 'first name')"
              @inputError="validateFirstName"
            ></base-input>
          </div>
          <div class="col-md-6">
            <base-input
              id="last-name"
              v-model="last_name"
              label="* Last name"
              err-msg="last name"
              placeholderText="Enter last name"
              @blur="triggerFormGTM(last_name, 'last name')"
              @inputError="validateLastName"
            ></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-input
              id="preferred-first-name"
              v-model="preferred_first_name"
              :aria-label="'first name'"
              placeholderText="Enter preferred first name"
              errMsg=""
              :isRequired="false"
              @blur="
                triggerFormGTM(preferred_first_name, 'preferred first name')
              "
            >
              <label class="font-label" for="first_name"
                >Preferred first name</label
              ><img
                id="popover-5"
                src="../assets/images/info-circle-solid.svg"
                alt="info-circle-solid.svg"
                style="margin-top:-8px"
                class="ml-1"
              />
              <b-popover
                target="popover-5"
                :triggers="tooltipTrigger"
                class="font-14 font-weight-bold"
                placement="top"
                :show.sync="showTooltip"
              >
                <span @click="showTooltip = false">
                  What name would you like us to use when we communicate with
                  you?</span
                >
              </b-popover>
              <span class="ml-2 pl-1 font-12 font-weight-light"
                >(optional)</span
              >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-email
              id="email"
              v-model="email"
              label="* Email"
              placeholderText="Enter email address"
              rules="required|email|max:128"
              err-msg="email"
              :emailError="emailError"
              @blur="triggerFormGTM(email, 'email')"
              @changed="handleEmailError"
              @inputError="validateEmailValue"
            ></base-email>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-phone-input
              id="phone-input"
              v-model="phoneNum"
              @blur="triggerPhoneFormGTM('phone number')"
              @input="onInput"
              :clickedSubmit="submitClick"
              :briteVerifyPhoneIsValid="phone.isValid"
            ></base-phone-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <base-select
              id="degree"
              v-model="degree"
              label="* Degree program"
              placeholder="Select a degree"
              :options="program"
              @changed="triggerDegreeFormGTM"
              :clickedSubmit="submitClick"
            ></base-select>
          </div>
        </div>
      </div>
      <check-partner-id
        page="rfi"
        class="mt-3"
        @input-value="updatePartnerNumber"
        :clickedSubmit="submitClick"
        @radio-value="radioButtonSelected"
        @is-checking-partner-number="checkingPartnerNumber"
        @inputError="validatePartnerIDValue"
      />
      <footer-desclimer class="mx-auto" />
      <div class="col-lg-4 col-11 mx-auto ml-lg-auto mr-lg-0 text-center">
        <button
          type="submit"
          class="btn  btn-secondary btn-block rounded-pill heading-7 stb-sBold btn-dark-green"
          @click="submitClick = true"
          :disabled="isSubmitDisabled || !phone.isValid || invalid || !degree"
        >
          <template v-if="isPartnerNumberLoading">
            <span><b-spinner small></b-spinner> <span>Checking..</span></span>
            <span class="sr-only">Checking...</span>
          </template>
          <template v-else> Submit </template>
        </button>
        <br />
      </div>
    </b-form>
  </section>
</template>

<script>
import BaseInput from "@/components/BaseInput";
import BaseEmail from "@/components/BaseEmail";
import BasePhoneInput from "@/components/BasePhoneInput";
import BaseSelect from "@/components/BaseSelect";
import fetchService from "../services/fetch";
import { briteVerify } from "../services/BriteVerifyService";
import FooterDesclimer from "@/components/footer-desclimer.vue";
import CheckPartnerId from "@/components/check-partner-rfi.vue";
//import { briteVerify } from "../services/BriteVerifyService";
import {
  getGtmClientIds,
  getUTMCookies,
  getASUOCookies,
} from "../services/RFIService";
import util from "../services/utils";
import registerService from "../services/register";
export default {
  name: "RequestInfoComponent",
  components: {
    "base-input": BaseInput,
    "base-email": BaseEmail,
    "base-phone-input": BasePhoneInput,
    "base-select": BaseSelect,
    "footer-desclimer": FooterDesclimer,
    "check-partner-id": CheckPartnerId,
  },
  data() {
    return {
      popoverData: {
        content:
          'Don’t know your partner number? Ask your manager or call the Partner Contact Center (PCC) at <a href="tel:866-504-7368">866-504-7368</a> for assistance.',
      },
      step: 0,
      sbuxId: "",
      radioButtonValue: "yes",
      isPartnerNumberLoading: false,
      firstNameError: true,
      lastNameError: true,
      emailValueError: true,
      partnerValueError: true,
      program: [],
      phoneError: false,
      telephoneValid: false,
      emailError: false,
      first_name: "",
      last_name: "",
      preferred_first_name: "",
      phone_number: "",
      email: "",
      interest: "",
      //zip: '',
      degree: "",
      custom: false,
      error: false,
      success: false,
      customSuccessData: "",
      briteVerifySuccess: false,
      customErrorData: "",
      customData: "",
      isSubmitDisabled: false,
      customMessage: "",
      phoneNum: "",
      phone: {
        number: "",
        isValid: false,
        country: "",
      },
      tooltipTrigger: "",
      showTooltip: null,
      rfi: {
        content: {
          heading: "Get started at ASU today!",
          desc:
            "Please fill out the form to request additional information. Our enrollment coaches can answer your questions about the application process, degree programs, financial aid and more.",
        },
      },
      rfiSubmissionMsg: {
        rfiSubmitTitle: "Thank you for your interest in Starbucks",
        rfiSubmitMessage:
          "<p style='font-size: 13px; font-weight: 1000; margin-bottom: 30px;'>You are now being redirected to an interactive guide that will help you explore your tuition coverage and apply to ASU.</p>",
        rfiFooterMessage:
          "<span style='font-size: 11px;'>If you haven't been redirected in 30 seconds, please click this <a style='font-weight: bold; border-bottom: 1px dotted #a30046;' href='%url%'>link</a> to continue</span>",
        isredirectDX: true,
      },
      submitClick: false,
    };
  },
  computed: {
    invalid() {
      return (
        this.firstNameError ||
        this.lastNameError ||
        this.emailValueError ||
        this.partnerValueError
      );
    },
  },

  methods: {
    checkingPartnerNumber(value) {
      this.isPartnerNumberLoading = value;
    },
    validateFirstName(data) {
      this.firstNameError = data;
    },
    validateLastName(data) {
      this.lastNameError = data;
    },
    validateEmailValue(data) {
      this.emailValueError = data;
    },
    radioButtonSelected(data) {
      //console.log(this.radioButtonValue);
      if (data === "no") {
        this.partnerValueError = false;
      } else {
        this.partnerValueError = true;
      }

      this.radioButtonValue = data;

      //console.log(this.radioButtonValue);
    },
    updatePartnerNumber(value) {
      this.sbuxId = value;
    },
    validatePartnerIDValue(data) {
      if (this.radioButtonValue === "no") {
        this.partnerValueError = false;
      } else {
        //console.log("input error validation check");
        this.partnerValueError = data;
      }
      console.log(this.partnerValueError);
      //this.partnerValueError = this.radioButtonValue==="no" ? true : data
      //console.log(this.partnerValueError);
      // this.partnerValueError = data
    },
    handleEmailError() {
      this.emailError = false;
      if (this.emailError == false && this.phone.isValid == true) {
        this.isSubmitDisabled = false;
      }
    },
    onInput({ number, isValid, country }) {
      this.telephoneValid = true;
      this.phone.number = number;
      this.phone.isValid = isValid;
      this.phone.country = country;

      if (this.emailError == false && this.phone.isValid == true) {
        this.isSubmitDisabled = false;
      }

      // console.log(this.phone.number);
      // console.log(this.phone.country);
    },
    async onSubmit() {
      //this.emailError = false
      this.isPartnerNumberLoading = true;
      this.isSubmitDisabled = true;
      let dl = {
        event: "form",
        action: "click",
        name: "onclick",
        type: "click",
        region: "main content",
        section: "get started at asu today!",
        text: "submit",
      };

      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch (error) {
        console.log("no utag");
      }

      try {
        //check briteVerify and flag emailError accordingly
        const bvquery = {
          email: this.email,
        };
        if (this.phone.country === "US" || this.phone.country === "CA") {
          bvquery.phone = this.phone.number;
        }

        let bvoutput = await briteVerify(bvquery);

        //   let bvoutput = {
        //                     email: {
        //               address: "glakkava@asu.edu",
        //               account: "sales",
        //               domain: "asu.edu",
        //               status: "valid",
        //               connected: null,
        //               disposable: false,
        //               role_address: true
        //             },
        //               phone: {
        //           number: "18009618205",
        //           service_type: "land",
        //           phone_location: null,
        //           status: "valid",
        //           errors: []
        //         },
        //  }

        if (
          (bvoutput.email.status === "valid" ||
            bvoutput.email.status === "accept_all") &&
          !bvoutput.email.disposable
        ) {
          this.emailError = false;
          // work if false
        } else {
          this.emailError = true;
          this.isPartnerNumberLoading = false;
        }

        if (this.phone.country === "US" || this.phone.country === "CA") {
          // apply briteVerify flag
          if (
            bvoutput.phone.status === "valid" ||
            bvoutput.phone.status === "accept_all"
          ) {
            this.phone.isValid = true;
          } else {
            this.phone.isValid = false;
            this.isPartnerNumberLoading = false;
          }
        } else {
          this.phone.isValid = true;
        }
        this.briteVerifySuccess = true;
      } catch (err) {
        this.isPartnerNumberLoading = false;

        this.isSubmitDisabled = false;
        console.log(err);
      }

      // on submit data layer changes
      this.$gtm.trackEvent({
        event: "briteverify_contact",
        email: this.email.toLocaleLowerCase(),
        email_valid: !this.emailError,
        phone: this.phone.number,
        phone_valid: this.phone.isValid,
      });
      if (
        !this.sbuxId_error &&
        this.phone.isValid &&
        this.degree &&
        !this.emailError &&
        this.briteVerifySuccess === true
      ) {
        let response;
        try {
          // eslint-disable-next-line no-undef
          const utmCookieValues = getUTMCookies();
          const getAsuoCookie = getASUOCookies();
          // eslint-disable-next-line no-undef
          const edplusTrackers = getGtmClientIds(window);

          if (this.radioButtonValue === "yes") {
            console.log("yes");
            let dl = {
              event: "form",
              action: "rfi submit",
              name: "onsubmit",
              type: "submit",
              region: "main content",
              section: "get started at asu today!",
              text: "rfi form submitted",
              first_name: this.first_name.toLocaleLowerCase(),
              last_name: this.last_name.toLocaleLowerCase(),
              preferred_name:
                this.preferred_first_name.toLocaleLowerCase() || "",
              email: this.email.toLocaleLowerCase(),
              phone_number: this.phone.number,
              starbucks_affiliation: this.radioButtonValue,
              degree_program: this.degree.text
                ? this.degree.text.toLocaleLowerCase()
                : "undecided",
              corporate_partner_id: "00000000" + this.sbuxId,
              career: "undergraduate",
              program_code: this.degree.id,
            };
            try {
              this.$gtm.trackEvent(dl);
              utag.link(dl);
            } catch (error) {
              console.log("no utag");
            }

            response = await registerService.sendInfo({
              email_address: this.email,
              first_name: this.first_name,
              last_name: this.last_name,
              preferred_name: this.preferred_first_name || "",
              phone: this.phone.number,
              program_key: this.degree.id,
              clientid: edplusTrackers?.clientid,
              enterpriseclientid: edplusTrackers?.enterpriseclientid,
              sbux_id: "0000000" + this.sbuxId,
              sbux_type: "Starbucks_Partner",
              sub_class: "scap",
              //zip: this.zip,
              sourceid: "starbucks_us_rfi_module-Ctrg42vEMOmo9nZs",
              utm_ecd22: utmCookieValues.utm_ecd22,
              ...getAsuoCookie,
              origin_uri:
                window.location.protocol + "//" + window.location.host,
              lead_class: "CORP",
              sms_permission: true,
            });
          } else if (this.radioButtonValue === "no") {
            console.log("no");
            let dl = {
              event: "form",
              action: "rfi submit",
              name: "onsubmit",
              type: "submit",
              region: "main content",
              section: "get started at asu today!",
              text: "rfi form submitted",
              first_name: this.first_name.toLocaleLowerCase(),
              last_name: this.last_name.toLocaleLowerCase(),
              preferred_name:
                this.preferred_first_name.toLocaleLowerCase() || "",
              email_address: this.email.toLocaleLowerCase(),
              phone: this.phone.number,
              program: this.degree.text
                ? this.degree.text.toLocaleLowerCase()
                : "undecided",
              corporate_partner_id: "00000000" + this.sbuxId,
              career: "undergraduate",
              starbucks_affiliation: this.radioButtonValue,
              program_code: this.degree.id,
            };
            try {
              this.$gtm.trackEvent(dl);
              utag.link(dl);
            } catch (error) {
              console.log("no utag");
            }
            response = await registerService.sendInfo({
              email_address: this.email,
              first_name: this.first_name,
              last_name: this.last_name,
              preferred_name: this.preferred_first_name || "",
              phone: this.phone.number,
              program_key: this.degree.id,
              clientid: cidE,
              enterpriseclientid: cidA,
              sub_class: null,
              // zip: this.zip,
              sourceid: "starbucks_us_rfi_module-Ctrg42vEMOmo9nZs",
              origin_uri:
                window.location.protocol + "//" + window.location.host,
              lead_class: null,
              sms_permission: true,
              utm_ecd22: utmCookieValues.utm_ecd22,
              ...getAsuoCookie,
            });
          }

          if (response.status === 400) {
            this.customMessage = true;
            this.error = true;
            this.customMessage = "Error! ";
          } else {
            let DXlink;
            if (!response.data.dx_url) {
              DXlink = util.getDXLink().replace("%rfi_id%", response.data.id);
            } else {
              DXlink = response.data.dx_url;
            }
            this.success = true;
            this.custom = true;
            this.customMessage = "Success";
            this.customData = "User Created";
            console.log("success");
            if (this.first_name != "embtest") {
              // this.$gtm.trackEvent({
              //   event: "RFI Submit",
              //   program_code: this.degree.id,
              //   program: this.degree.text,
              //   asuoid: response.data.id
              // });
            }
            // if(this.step === 1){
            // $('#starbucks_rfi_form').modal('show');
            // var self = this;
            // setTimeout(function() {
            //     self.rfiSubmissionMsg.rfiFooterMessage = self.rfiSubmissionMsg.rfiFooterMessage.replace('%url%', DXlink);
            //     return (window.location = DXlink);
            // }, 7000);
            //if (!this.emailError){
            this.isPartnerNumberLoading = false;
            return (window.location = DXlink);
            //}
            //return (window.location = "");
            //}
            // else {
            //     this.$store.commit("changeStep", "thankyou");
            //     this.$router.push({ name: "ThankYou" });
            // }
          }
        } catch (err) {
          console.log(err);
          this.isSubmitDisabled = false;
          this.custom = true;
          this.error = true;
          this.customMessage = "Error";
          this.customData = "Please contact administrator";
          this.isPartnerNumberLoading = false;
        }
      } else {
        this.isPartnerNumberLoading = false;
      }
    },
    async getProgramArray() {
      try {
        this.program = await fetchService.getPrograms();
        let data = {
          code: "UGUD-UNDECIDED",
          title: "Undecided",
        };
        this.program.unshift(data);
      } catch (err) {
        console.log(err);
      }
    },
    triggerFormGTM(text, _section) {
      //console.log("working at dl");
      let dl = {
        event: "form",
        name: "onclick",
        action: "click",
        type: "blur",
        region: "main content",
        section: `get started at asu today! ^ ${_section}`,
        text: text.toLocaleLowerCase(),
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch (error) {
        console.log(error);
      }
    },
    triggerPhoneFormGTM(_section) {
      let dl = {
        event: "form",
        name: "onclick",
        action: "click",
        type: "blur",
        region: "main content",
        section: `get started at asu today! ^ ${_section}`,
        text: this.phone.number || "",
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch (error) {
        console.log(error);
      }
    },
    triggerDegreeFormGTM() {
      let dl = {
        event: "select",
        name: "onclick",
        action: "click",
        type: "select a degree program",
        region: "main content",
        section: `get started at asu today!`,
        text: this.degree.text.toLocaleLowerCase(),
      };
      try {
        this.$gtm.trackEvent(dl);
        utag.link(dl);
      } catch (error) {
        console.log(error);
      }
    },
    remove() {
      this.custom = false;
    },
    popoverTrigger() {
      if (window.innerWidth <= 992) this.tooltipTrigger = "click";
      else this.tooltipTrigger = "hover focus";
    },
  },
  mounted() {
    this.getProgramArray();
    this.popoverTrigger();
    window.addEventListener("resize", this.popoverTrigger);
  },
};
</script>

<style lang="scss">
.form-group {
  margin-bottom: 16px !important;
}
#req-info-form label {
  font-family: "stb-s-bold" !important;
}
.form-control.is-valid {
  border-color: #ced4da !important;
  background-image: none !important;
}
.remove-default-class {
  -webkit-appearance: none;
}
.invalid-text {
  position: absolute;
  color: red;
}
.invaid-data {
  border-color: red !important;
  color: red !important;
  box-shadow: none !important;
}
.input-group-text {
  background-color: transparent;
}
.input-group-prepend.is-invalid {
  .input-group-text {
    border-color: #dc3545;
  }
}
.font-label {
  font-family: "stb-s-bold" !important;
  @media only screen and (max-width: 991px) {
    font-size: 14px !important;
  }
}
.bs-popover-top > .arrow::after,
.bs-popover-bottom > .arrow::after {
  bottom: -1px !important;
  border-top-color: black !important;
  border-bottom-color: black !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
</style>
