<template>
  <div>
    <!-- uConnect LMI: Start -->
    <div id="uconnect-lmi-root"><div id="section_job_insights"></div></div>
  </div>
</template>

<script>
export default {
  mounted() {
    // uConnect LMI script
    (function (w, d, s, src, id, o) {
      w[o] = w[o] || {};
      w[o].lmi =
        w[o].lmi ||
        function () {
          (w[o].lmi.q = w[o].lmi.q || []).push(arguments);
        };
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = src;
      fjs.parentNode.insertBefore(js, fjs);
    })(
      window,
      document,
      "script",
      "https://cdn.uconnectlabs.com/wp-content/mu-plugins/uconnect/modules/labor-market-insights/assets/js/lmi-embed.js?v=1.13",
      "uc_lmi_js",
      "uconnect"
    );

    // Initialize uConnect LMI
    uconnect.lmi("init", {
      instance: "https://career.eoss.asu.edu/job-market-insights/?embd=true",
      hide_filters: false,
      compact_mode: false,
      search_by: "both",
      filter: null,
    });
  },
};
</script>
