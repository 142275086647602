<template>
  <section
    id="hero-persona"
    :style="
      'background-image:url(' +
        require('../../assets/images/personalize/' + bgImage) +
        ');' +
        'align-items:' +
        alignTitlePosition
    "
    class="bg-dark-green"
  >
    <div class="container">
      <div class="col-8 col-lg-5 pl-lg-0 position-relative z-top">
        <h1
          class="font-lg-48 padding-fix mb-0 font-24 text-white pb-16 font-black"
        >
          {{ hero.header }}
        </h1>
        <p class="text-white font-lg-18 font-14 fam-roboto-light text-shadow">
          {{ hero.content }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    hero: {
      type: Object,
      required: true,
    },
    bgImage: {
      type: String,
      default: "hero-lady.png",
    },
    alignTitlePosition: {
      type: String,
      default: "center",
    },
  },
};
</script>
<style lang="scss">
.text-shadow {
  text-shadow: 3px 6px #29413b;
}
#hero-persona {
  height: 600px;
  margin-top: -5px;
  width: 100%;
  display: flex;
  position: relative;
  background-size: cover;
  background-position: center top;
  align-items: center;
  @media only screen and (max-width: 991px) {
    height: 288px;
  }
}
</style>
